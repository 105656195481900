import {Expose, plainToInstance, Transform} from "class-transformer";
import {PhysicalAddress} from "@/components/common/AddressEditor.vue";
import {Social} from "@/classes/companies/Company";
import {ReHelper} from "@/classes/common/ReHelper";

export default class CompanyContact {
    id: number;
    type: string;
    name: string;
    email: string;
    phone: string;
    phone_alt: string;
    created_on: string;

    @Expose({name: 'address_json'})
    @Transform(({value}) => JSON.parse(value), {toClassOnly: true,})
    @Transform(({value}) => JSON.stringify(value), {toPlainOnly: true})
    address?: PhysicalAddress;

    @Expose({name: 'social_json'})
    @Transform(({value}) => JSON.parse(value), {toClassOnly: true})
    @Transform(({value}) => JSON.stringify(value), {toPlainOnly: true})
    social?: Social;

    is_empty() {
        return ReHelper.empty(this, ['type']);
    }


    constructor(id: number, type: string, name: string, email: string, phone: string, phone_alt: string, created_on: string) {
        this.id = id;
        this.type = type;
        this.name = name;
        this.email = email;
        this.phone = phone;
        this.phone_alt = phone_alt;
        this.created_on = created_on;
    }

    static from(o: object): CompanyContact {
        return plainToInstance(CompanyContact, o);
    }
}