import {LikeableEntity, LikeableEntityGenericRepo} from "@/classes/repos/LikeableEntityGenericRepo";
import Vue from "vue";
import {BoothType, ComplexBoothType, ShowsGenericRepo} from "@/classes/common/internal";
import FloorMap from "@/classes/floor-map/FloorMap";
import Booth from "@/classes/floor-map/Booth";
import axios from "axios";
import ErrorForDisplay from "@/classes/common/ErrorForDisplay";
import FilesRepo from "@/classes/repos/FilesRepo";
import {uniqBy} from "lodash-es";
import {Guid} from "guid-typescript";
import DataProviderContext from "@/classes/common/DataProviderContext";
import {processing_refresh_auth_logic} from "@/classes/repos/AuthRepo";

class _BoothTypesGenericRepo extends LikeableEntityGenericRepo<{ show_id?: number; limit_by_ids?: number[], exclude_ids?: number[] }> {
    protected entities_cache: BoothType[] = [];

    constructor() {
        super(BoothType, 'BoothType');
    }

    override get axios() {
        return (processing_refresh_auth_logic ? axios.create() : axios);
    }

    booth_types: BoothType[] = [];

    get all_booth_types(): BoothType [] {
        return [
            ...this.booth_types,
            ...uniqBy((ShowsGenericRepo.current_show?.maps?.flatMap(m => m.all_booths())
                .filter(o => !o.booth_type_id && !!o.internal_booth_type)
                .map(o => o.internal_booth_type!)) ?? [], t => t.type_id
            )
        ];
    }

    get all_booth_types_sorted(): BoothType [] {
        return this.all_booth_types.sort((t1, t2) => t1.letter_index.localeCompare(t2.letter_index));
    }

    get booth_types_by_letter(): { [letter: string]: BoothType } {
        return Object.fromEntries(this.booth_types.map(t => [t.letter_index, t]));
    }

    get all_booth_types_by_letter(): { [letter: string]: BoothType } {
        return Object.fromEntries(this.all_booth_types.map(t => [t.letter_index, t]));
    }

    async load_booth_types(): Promise<{ success: boolean; items: LikeableEntity[]; total: number; errors?: any }> {
        const res = await super.list(new DataProviderContext(1, 100000), {show_id: ShowsGenericRepo.current_show?.id});

        this.booth_types = res.success ? res.items as BoothType[] : [];
        this.booth_types.filter(bt => bt.type == "ComplexBoothType").forEach(bt => (bt as ComplexBoothType).parts?.forEach(p => p.update_size_from_booth_type()));

        return res;
    }

    get_free_letter_index(): string {
        let letters = new Set([...Array(26).keys()].map(n => String.fromCharCode(65 + n)));
        this.all_booth_types.forEach(t => letters.delete(t.letter_index));
        return [...letters][0];
    }

    assign_letter_index(booth_type: BoothType) {
        booth_type.letter_index = '';
        const similar_bt = BoothTypesGenericRepo.all_booth_types.find(t => t.type_id === booth_type.type_id && t !== booth_type);
        booth_type.letter_index = similar_bt?.letter_index ?? BoothTypesGenericRepo.get_free_letter_index();
    }

    protected objects_to_entities(plain_objects: any[]): BoothType[] {
        const items = plain_objects.filter(x => x.type !== 'ComplexBoothType').map(x => BoothType.from(x));
        items.push(...plain_objects.filter(x => x.type === 'ComplexBoothType').map(x => ComplexBoothType.from(x)));

        return items;
    }

    get_by_id(id: number): BoothType | undefined {
        return this.booth_types.find(t => t.id === id);
    }

    override async save(bt: BoothType): Promise<{ success: boolean; errors?: ErrorForDisplay }> {
        if (bt.png_data_url)
            bt.imageURL = await FilesRepo.save_file(Guid.create().toString(), bt.png_data_url, 'booth', `show/${ShowsGenericRepo.current_show!.id}/booth`);

        const res = await super.save(bt);

        await this.log_all_show_booth_types(bt.show_id);

        return res;
    }

    async save_all_changed() {
        try {
            const changed_bts = uniqBy(this.all_booth_types.filter(t => t.is_dirty || !t.is_public), t => t.letter_index);
            if (!changed_bts.length) return;

            const show_id = ShowsGenericRepo.current_show!.id;

            const post_body = {
                booth_types: changed_bts.map(t => t.prepare_for_save()),
                show_id: show_id
            };

            await axios.post(`/${this.entity}/save_multiple`, post_body);

            await this.log_all_show_booth_types(show_id);

            return {success: true,};
        } catch (ex) {
            console.log(ex)
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async remove(id: number): Promise<{ success: boolean; errors?: ErrorForDisplay }> {
        const res = await super.remove(id);

        if (ShowsGenericRepo.current_show?.id)
            await this.log_all_show_booth_types(ShowsGenericRepo.current_show.id);

        return res;
    }

    async remove_multiple(ids: number[]): Promise<{ success: boolean; errors?: ErrorForDisplay }> {
        const res = await super.remove_multiple(ids);

        if (ShowsGenericRepo.current_show?.id)
            await this.log_all_show_booth_types(ShowsGenericRepo.current_show.id);

        return res;
    }

    remap() {
        const booths = ShowsGenericRepo.current_show?.all_booths(['Booth']).filter(b => !b.booth_type.is_public) ?? [];
        booths.forEach(b => b.booth_type = this.booth_types_by_letter[b.booth_type.letter_index]);
    }

    find_usages(bt: BoothType): { map: FloorMap; booth: Booth }[] | null {
        let results = ShowsGenericRepo.current_show?.maps?.flatMap(m =>
            m.mapObjects
                .filter(b => b.booth_or_complex_booth?.booth_type_id === bt.id)
                .map(b => ({map: m, booth: b as Booth}))
        ) ?? [];

        let results2 = ShowsGenericRepo.current_show?.maps?.flatMap(m =>
            m.mapObjects
                .filter(b => b.complex_booth?.booth_type?.parts.some(p => p.booth_type_id === bt.id))
                .map(b => ({map: m, booth: b as Booth}))
        ) ?? [];

        results.push(...results2);
        // console.log('find_usages', results)
        // debugger
        return results.length ? results : null;
    }

    find_unused(): BoothType[] | null {
        let results = this.booth_types.filter(bt => !this.find_usages(bt));
        return results?.length ? results : null;
    }

    async get_available_for_import(): Promise<{ bts: BoothType[]; show: string }[]> {
        const show_id = ShowsGenericRepo.current_show?.id;
        const {data}: { data: any[] } = await axios.get(`/${this.entity}/get_available_for_import`, {params: {show_id}});
        const result = data.map(x => ({
            show: JSON.parse(x.show_json).name as string,
            bts: (x.booth_types as Array<any>).map(bt => BoothType.from_wrapped(bt))
        }));

        return result;
    }

    async import_booth_types(bt_ids: number[]) {
        const show_id = ShowsGenericRepo.current_show?.id;
        await axios.post(`/${this.entity}/import_booth_types`, bt_ids, {params: {show_id}});
    }

    get_booths_by_fn(fn: (t: BoothType, b: Booth) => boolean): Booth[] {
        return ShowsGenericRepo.current_show?.maps?.flatMap(m => m.mapObjects.filter(o => !!o.booth_or_complex_booth && fn(o.booth_or_complex_booth.booth_type, o as Booth))) as Booth[] ?? [];
    }

    get_booths_by_letter(letter_index: string): Booth[] {
        return this.get_booths_by_fn(t => t.letter_index === letter_index);
    }

    change_booth_type_if_needed(booth: Booth, curr_size: { rw_width: number, rw_height: number }, new_size: { rw_width: number, rw_height: number }) {
        if (booth.booth_type.type === "ComplexBoothType") return;

        const show = ShowsGenericRepo.current_show!;
        let booth_type = booth.booth_type;

        if (booth_type.is_public) { //public booth type
            const booths_same_letter = this.get_booths_by_fn((t, b) => t.letter_index === booth_type.letter_index && b !== booth);
            if (booths_same_letter.length) { //is it shared between multiple booths?
                booth_type = booth_type.clone(true);
                booth_type.letter_index = BoothTypesGenericRepo.get_free_letter_index();
                booth.booth_type = booth_type;
                // revert?.(booths_same_letter[0].booth_type);
            } else booth_type.is_dirty = true;
            booth_type.rw_width = new_size.rw_width;
            booth_type.rw_height = new_size.rw_height;
        } else { //internal booth type
            //are there booths sharing the booth type?
            const all_booths = this.get_booths_by_fn(t => true);
            const booths_same_bt = this.get_booths_by_fn((t, b) => t === booth_type && b !== booth);
            if (booths_same_bt.length) {
                //split
                booth_type = booth_type.clone(true);
                booth.booth_type = booth_type;
                booth_type.letter_index = BoothTypesGenericRepo.get_free_letter_index();
            }
            booth_type.rw_width = new_size.rw_width;
            booth_type.rw_height = new_size.rw_height;

        }

        //check new size
        const booths_same_new_size = this.get_booths_by_fn(t => t.sizes_equal(new_size) && t !== booth_type);
        //is there a same-size booth type?
        if (booths_same_new_size.length) {
            //assign it to booth (merge)
            booth.booth_type = booths_same_new_size[0].booth_type;
        }

        show.assign_numeric_index(booth);
    }

    show_switched() {
        this.booth_types = [];
    }

    async log_all_show_booth_types(show_id: number) {
        await axios.post(`/${this.entity}/log_all_show_booth_types`, null, {params: {show_id}});
    }
}

export const BoothTypesGenericRepo = Vue.observable(new _BoothTypesGenericRepo);