
import {Component, Prop} from 'vue-property-decorator';
import EntitySelector from "@/components/generic/EntitySelector.vue";
import {search_field_description} from "@/components/generic/EntitySearchForm.vue";
import {CompaniesRepo} from "@/classes/repos/CompaniesRepo";

@Component<CompanySelector>(
    {}
)
export default class CompanySelector extends EntitySelector {
  repo = CompaniesRepo;

  protected entity_plural_name = 'companies';

  @Prop({default: false})
  with_bookings_only!: boolean;

  get search_request_additional_params(): any {
    return {has_bookings: this.with_bookings_only}
  }

  protected search_fields: (string | search_field_description)[] = ['name', {name: 'email', row_start: true}];

}
