
import {Component} from 'vue-property-decorator';
import EntityEditor from "@/components/generic/EntityEditor.vue";
import {BForm} from "bootstrap-vue";
import {UsersRepo} from "@/classes/repos/UsersRepo";
import User from "@/classes/users/User";
import {LikeableEntity} from "@/classes/repos/LikeableEntityGenericRepo";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import DataProviderContext from "@/classes/common/DataProviderContext";
import Show from "@/classes/floor-map/Show";
import {AuthRepo} from '@/classes/repos/AuthRepo';
import ShowSelector from "@/components/generic/ShowSelector.vue";
import CompanySelector from "@/components/generic/CompanySelector.vue";
import App from "@/App.vue";

@Component<UserGenericEditor>(
    {
      components: {CompanySelector, ShowSelector}
    }
)
export default class UserGenericEditor extends EntityEditor {
  repo = UsersRepo;
  TEntity = User;

  protected entity: (User | null) = null;

  private get user() {
    return this.entity!;
  }

  private AuthRepo = AuthRepo;

  private get can_have_limited_shows_access() {
    return this.roles.includes('staff') && !this.roles.includes('admin');
  }

  private get accessible_shows_count() {
    return this.shows_accessible_for_staff.length;
  }

  private roles: string[] = [];
  private shows_access_is_limited = false;
  private shows_accessible_for_staff: Show[] = [];

  protected async load_additional() {
    this.roles = this.user.roles.map(r => r.role_id);

    const staff_role = this.user.roles.find(r => r.role_id == 'staff');
    if (staff_role) {
      this.shows_access_is_limited = staff_role.shows_access_is_limited;
      const show_ids_accessible_for_staff = staff_role.limit_by_shows ?? [];
      if (show_ids_accessible_for_staff?.length) {
        const res: { success: boolean; items: LikeableEntity[]; total: number; errors?: any } = await ShowsGenericRepo.list(new DataProviderContext, {limit_by_ids: show_ids_accessible_for_staff});
        this.shows_accessible_for_staff = <Show[]>res.items;
      }
    } else {
      this.shows_access_is_limited = false;
      this.shows_accessible_for_staff = [];
    }
  }

  protected setup_entity() {
    //clear this array on save in order to have previously selected items available until saving
    if (!this.shows_access_is_limited || !this.can_have_limited_shows_access) this.shows_accessible_for_staff = [];
    this.user!.roles = this.roles.map(r => ({
      role_id                : r,
      shows_access_is_limited: this.shows_access_is_limited,
      limit_by_shows         : r === 'staff' ? this.shows_accessible_for_staff.map(x => x.id) : undefined
    }));

    this.entity!.copyTo(<User>this.orig_entity);
  }

  private async resend_activation_email() {
    const {errors, success} = await UsersRepo.resend_activation_email(this.user.id);
    if (!errors)
      App.notify('Email successfully sent');
    else App.notify(errors.single_err_msg, 'danger');
  }
}
