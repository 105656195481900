
import {Component, Prop, Vue} from 'vue-property-decorator';
import {BoothType} from "@/classes/floor-map/BoothType";

@Component<BoothTypeImage>(
    {}
)
export default class BoothTypeImage extends Vue {
  @Prop()
  bt!: BoothType;

  @Prop({default: false})
  draggable!: boolean;

  @Prop({default: false})
  show_letter!: boolean;
}
