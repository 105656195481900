
import {Component, Prop} from 'vue-property-decorator';
import EntityTable, {field_definition} from "@/components/generic/EntityTable.vue";
import {CompaniesRepo} from "@/classes/repos/CompaniesRepo";
import Company from "@/classes/companies/Company";
import {LikeableEntity} from "@/classes/repos/LikeableEntityGenericRepo";
import {RawLocation} from "vue-router";

@Component<CompaniesTable>(
    {}
)
export default class CompaniesTable extends EntityTable {
  repo = CompaniesRepo;
  TEntity = Company;

  protected get default_fields(): (field_definition | undefined)[] {
    return [
      {key: 'id', label: 'Id', sortable: true,},
      {key: 'name', label: 'Organization', sortable: true,},
      {key: 'email', label: 'Email', sortable: true,},
      {key: 'phone', label: 'Phone', sortable: false,},
      {key: 'website', label: 'Website', sortable: false,},
      {key: 'created_on', label: 'Created', sortable: true, formatter: 'format_date'},

      {key: 'edit_company', label: 'Edit', sortable: false,},
      // {key: 'clone', label: 'Clone', sortable: false,},
      {key: 'delete', label: 'Delete', sortable: false,},
    ];
  }

  @Prop({default: {}})
  filter_request: any;

  get search_request(): any {
    return this.filter_request;
  }

  actions: { key: string; text: string; to: (b: LikeableEntity) => RawLocation }[] = [
    {
      key : 'edit_company',
      text: 'Edit',
      to  : c => ({
        name  : 'admin_vendor_profile',
        params: {vendor_id: c.id.toString()}
      })
    }
  ]
}
