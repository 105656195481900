import Vue from "vue";
import axios from "axios";
import {AuthRepo} from "@/classes/repos/AuthRepo";
import {MapRepo} from "@/classes/repos/MapRepo";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import Config from "@/classes/common/Config";
import {BookingsRepo} from "@/classes/repos/BookingsRepo";
import {ReHelper} from "@/classes/common/ReHelper";
import {CompaniesRepo} from "@/classes/repos/CompaniesRepo";
import {JwtRepo} from "@/classes/repos/JwtRepo";
import router from "@/router";
import {BoothTypesGenericRepo} from "@/classes/repos/BoothTypesGenericRepo";
import {UsersRepo} from "@/classes/repos/UsersRepo";
import {EventBus} from "@/classes/common/EventBus";

export class _AppRepo {
    async load_settings() {
        const {data: {roles, settings, constants, user_settings, user_companies}} = await axios.post('/app/settings');
        AuthRepo.roles = roles;
        BookingsRepo.booth_in_cart_expires_min = settings.booth_in_cart_expires_min;
        BookingsRepo.paypal_client_id = settings.paypal_client_id;
        BookingsRepo.booking_statuses = constants.booking_statuses;
        BookingsRepo.booking_item_types = constants.booking_item_types;
        BookingsRepo.min_payment_amount_percentage = settings.min_payment_amount_percentage;
        BookingsRepo.multi_table_discount_min_booth_count = settings.multi_table_discount_min_booth_count;
        BookingsRepo.allow_store_credit = settings.allow_store_credit;
        BookingsRepo.vendors_pay_via_store_credit_first = settings.vendors_pay_via_store_credit_first;
        UsersRepo.user_settings = user_settings;
        JwtRepo.companies = user_companies;
    }

    ShowsRepo = ShowsGenericRepo;
    MapRepo = MapRepo;
    BookingsRepo = BookingsRepo;
    CompaniesRepo = CompaniesRepo;
    config = Config;
    ReHelper = ReHelper;
    JwtRepo = JwtRepo;
    BoothTypesGenericRepo = BoothTypesGenericRepo;

    private _ui_level: 'admin' | 'vendor' = "vendor";

    get ui_level(): "admin" | "vendor" {
        return this._ui_level;
    }

    set ui_level(value: "admin" | "vendor") {
        this._ui_level = value;
        if (value === 'vendor')
            MapRepo.panning_mode = true;
        EventBus.$emit('ui_level_changed');
    }

    get ui_level_changing_allowed(): boolean {
        if (!JwtRepo.is_admin) return false;

        return ['shows', 'layout'].includes(router.currentRoute.name ?? 'no_route');
    }

    async get_setting(name: string): Promise<string> {
        const {data}: { data: { setting: string } } = await axios.get('/app/get_setting', {params: {name}});
        return data.setting;
    }

    async get_settings(names: string[]): Promise<{ name: string, value: string }[]> {
        const {data}: { data: { settings: { name: string, value: string }[] } } = await axios.get('/app/get_settings', {params: {names}});
        return data.settings;
    }

    async save_setting(name: string, value: string) {
        await axios.post('/app/save_setting', {name, value});
    }

    async save_settings(settings: { [name: string]: string }) {
        await axios.post('/app/save_settings', settings);
    }
}

export const AppRepo = Vue.observable(new _AppRepo());
export let AppInstance: any = null;

export function init_app_instance(instance: any) {
    AppInstance = instance;
}
