
import {Component, Vue} from 'vue-property-decorator';
import {BModal} from "bootstrap-vue";
import LoginForm from "@/components/common/LoginForm.vue";
import {EventBus} from "@/classes/common/EventBus";

@Component<LoginPopup>(
    {
        components: {LoginForm}
    }
)
export default class LoginPopup extends Vue {
    $refs!: {
        modal: BModal;
    }

    private resolvePromise?: (value: (PromiseLike<boolean> | boolean)) => void;

    open(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.$refs.modal.show();

            this.resolvePromise = resolve;
        });
    }

    private close() {
        this.resolvePromise?.(false)
    }

    private success() {
        this.$refs.modal.hide();
        this.resolvePromise?.(true);
        EventBus.$emit?.('logged_in');
    }
}
