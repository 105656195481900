import axios from "axios";

export default class FilesRepo {
    static async save_file(id: number | string, data_url: string, entity_name: string, dir?: string): Promise<string> {
        const blob = await (await fetch(data_url)).blob();
        const file = new File([blob], `${entity_name}_${id}.png`, {
            type        : "image/png",
            lastModified: new Date().getDate()
        });

        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", file.name);
        dir ??= entity_name;
        try {
            const {data} = await axios.post(`/files/save_file`, formData, {params: {dir}});
            // console.log(data);
            return data.file_url;
        } catch (error) {
            console.log(error);
            return 'error';
        }
    }

}