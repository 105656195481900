
import {Component, Prop, Ref, Vue} from 'vue-property-decorator';
import BoothComp from "@/components/floor-map/BoothComp.vue";
import ReFabricGroup from "@/components/re-fabric/re-fabric-group.vue";
import ICanvasParent from "@/components/interfaces/ICanvasParent";
import ReFabricText from "@/components/re-fabric/re-fabric-text.vue";
import BoothPopupEditor from "@/components/editors/BoothPopupEditor.vue";
import {ComplexBooth} from "@/classes/floor-map/Booth";
import {mixins} from "vue-class-component";
import BoothCompBase from "@/components/floor-map/BoothCompBase";
import {BPopover} from "bootstrap-vue";

@Component<ComplexBoothComp>(
    {components: {BoothComp, ReFabricGroup, ReFabricText, BoothPopupEditor}}
)
export default class ComplexBoothComp extends mixins(Vue, BoothCompBase) implements ICanvasParent {
  //Defined in BoothCompBase!
  $refs!: {
    popup: BPopover,
    group: ReFabricGroup,
    text: ReFabricText,
  }

  type = 'ComplexBoothComp';

  @Prop()
  booth!: ComplexBooth;

  is_group: boolean = false;

  @Ref()
  private group!: ReFabricGroup;

  @Ref()
  private parts!: BoothComp[];

  ungroup() {
    this.group.fjs_object?.destroy();

    //force updating positions/angles for map objects
    for (const part of this.parts)
      part.$refs.group.modified_from_fjs();

  }
}
