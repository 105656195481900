
import {logo_url} from '@/classes/common/Const';
import {Component, Vue} from 'vue-property-decorator';
import {AuthRepo} from "@/classes/repos/AuthRepo";

@Component
export default class Logo extends Vue {
  private logo_url = logo_url;
  private AuthRepo = AuthRepo;
}
