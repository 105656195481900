import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import Loc from "@/classes/common/Loc";
import {fabric} from "fabric";
import ICanvasChild from "@/components/interfaces/ICanvasChild";
import ICanvasParent from "@/components/interfaces/ICanvasParent";
import Config from "@/classes/common/Config";
import ReFabricCanvas from "@/components/re-fabric/re-fabric-canvas.vue";
import ReFabricGroup from "@/components/re-fabric/re-fabric-group.vue";
import {MapRepo} from "@/classes/repos/MapRepo";

@Component({})
export default class ReFabricObject extends Vue implements ICanvasChild {

    fjs_object?: fabric.Object;

    static re_fabric_objects: ReFabricObject[] = [];

    @Prop()
    parent_fjs_wrap_object?: ReFabricCanvas | ReFabricGroup;

    @Prop({default: true})
    evented!: boolean;

    @Prop()
    bordered?: boolean;

    private config = Config;

    private static object_cnt = 0;
    n_object = 0;

    //setting after scaling from fjs; clearing after notifying parent - BoothCompBase
    private size_changed = false;

    @Prop({default: false})
    locked!: boolean;

    @Prop({default: false})
    allow_resizing!: boolean;

    get lock_movements() {
        return MapRepo.do_lock_objects_movements || this.locked;
    }

    get has_controls() {
        return this.allow_resizing && !this.lock_movements;
    }

    private get type_id_letter() {
        return this.constructor.name.replace('ReFabric', '').substr(0, 1).toLowerCase();
    }

    get debug_name() {
        return this.config.debug ? `f${this.type_id_letter}_${this.n_object}` : '';
    }

    get parent(): ICanvasParent {
        return this.parent_fjs_wrap_object ?? this.$parent as unknown as ICanvasParent;
    }

    get fo_left(): number {
        return this.fjs_object!.left!;
    }

    set fo_left(value: number) {
        this.fjs_object!.set({left: value});
    }

    get fo_top(): number {
        return this.fjs_object!.top!;
    }

    set fo_top(value: number) {
        this.fjs_object!.set({top: value});
    }

    get fo_width(): number {
        return this.fjs_object!.width!;
    }

    set fo_width(value: number) {
        this.fjs_object!.set({width: value});
    }

    get fo_height(): number {
        return this.fjs_object!.height!;
    }

    set fo_height(value: number) {
        this.fjs_object!.set({height: value});
    }

    get sin_a() {
        return Math.sin(this.angle / 180 * Math.PI);
    }

    get cos_a() {
        return Math.cos(this.angle / 180 * Math.PI);
    }

    protected create_fjs_object(callback: () => void) {
    }

    @Prop()
    title?: string;

    @Prop()
    loc!: Loc;

    @Prop()
    lowest_layer?: boolean;

    @Prop()
    highest_layer?: boolean;

    @Prop({default: 0})
    angle!: number;

    @Prop({default: false})
    do_scale_content!: boolean;

    @Prop({default: false})
    do_straighten!: boolean;

    protected listen_fjs_events() {
        this.fjs_object?.on('modified', this.modified_from_fjs);
        // this.fjs_object?.on('scaled', this.scaled);
        this.fjs_object?.on('selected', () => {
            // console.log(`${this.constructor.name} listen_fjs_events: selected`)
            // console.log(`${this.constructor.name} listen_fjs_events: selection width: ${this.fjs_object?.canvas?.getActiveObject().width}`)
            this.$emit('selected_from_fjs')
        });
        this.fjs_object?.on('deselected', () => {
            // console.log(`${this.constructor.name} listen_fjs_events: deselected`)
            this.$emit('deselected_from_fjs')
        });
    }

    protected connect_object() {
        // console.log(`${this.constructor.name}.connect_object (${this.debug_name})`);
        //
        if (this.title)
            (this.fjs_object as any).set({title: this.title});

        if (this.constructor.name == 'ReFabricImage') {
            // debugger
        }

        this.listen_fjs_events();

        this.$nextTick(() => {
            this.parent.addChild(this);
            // this.$emit('child_added', this.fjs_object!);

            if (this.lowest_layer)
                this.fjs_object?.sendToBack();

            if (this.highest_layer)
                this.fjs_object?.bringToFront();

            /*if (this.angle && !(this instanceof ReFabricRect)) {
                this.fjs_object?.rotate(this.angle);
            }*/
        });

        if (this.$repo.config.debug) {
            // (window as any).fjs_objects = (window as any).fjs_objects || [];
            // (window as any).fjs_objects.push(this.fjs_object);
            (window as any)[this.debug_name] = this.fjs_object;
            ReFabricObject.re_fabric_objects.push(this);
        }
    }

    created() {
        this.n_object = ++ReFabricObject.object_cnt;
    }

    mounted() {
        this.initialize();
    }

    protected initialize() {
        // console.log(`ReFabricObject.initialize (${this.debug_name}):`);
        this.create_fjs_object(this.connect_object);
    }

    @Watch('loc', {deep: true})
    modified_loc_from_model(loc: Loc, old_loc: Loc) {
        if (this.parent.is_group || MapRepo.multiple_selected) return;
        // console.log(`${this.constructor.name}.modified_loc_from_model (${this.debug_name}):`, loc.object, old_loc.object);
        this.fjs_object?.set(loc.object);
        this.fjs_object?.setCoords();
        this.$emit('render_all');
    }

    @Watch('angle')
    modified_angle_from_model(angle: number) {

        if (this.parent.is_group) return;
        this.fjs_object?.rotate(angle);
        if (this.do_straighten)
            this.fjs_object?.straighten();
        this.fjs_object?.setCoords();
        this.$emit('render_all');
        // this.fjs_object?.fire('modified_from_fjs');
        if (!MapRepo.processing_undo) {
            MapRepo.undo_buffer.pop();
            this.modified_from_fjs(); //so parent knows new coords
        }

        // console.log(`${this.constructor.name}.modified_angle_from_model (${this.debug_name}):`, angle, this.fjs_object?.angle);

    }

    /*    @Watch('selected')
        selected_from_model(v: boolean) {
            console.log(`${this.constructor.name}.selected_from_model (${this.debug_name}):`, this, arguments);
            if (v) {
                this.$emit('selected_from_model', this.fjs_object);
            }
        }*/

    modified_from_fjs(e?: any, parent_loc?: Loc) {
        if (e?.action == 'scale')
            this.size_changed = true;

        // console.log(`${this.constructor.name} modified_from_fjs: `, e);
        // const r: fabric.Rect = o.target;
        const r = this.fjs_object!;
        let new_loc: Loc;
        if (this.do_scale_content) {
            new_loc = new Loc(r.top!, r.left!, r.width!, r.height!, r.scaleX, r.scaleY);
        } else {
            new_loc = new Loc(r.top!, r.left!, r.getScaledWidth(), r.getScaledHeight(), 1, 1);
        }

        // console.log(`${this.constructor.name}.modified_from_fjs:`, new_loc.toString(), r.angle, parent_loc?.toString(), this.size_changed);

        if (parent_loc) {
            new_loc = new_loc.add(parent_loc.center_point);
        }

        this.$emit('modified_from_fjs', new_loc, r.angle, this.size_changed);
        this.size_changed = false;
    }


    @Watch('bordered')
    private bordered_changed(v: boolean) {
        // console.log(`${this.constructor.name}.bordered_changed`, arguments);
        if (v)
            this.fjs_object!.set({
                                     strokeWidth: MapRepo.booth_edited_stroke_width,
                                     stroke     : MapRepo.booth_edited_stroke_color
                                 })
        else
            this.fjs_object!.set({strokeWidth: 0})
    }

    unmounted() { //TODO: is this ever called?
        this.parent.removeChild(this);
        // console.log('unmounted')
    }


    @Watch('MapRepo.do_lock_objects_movements')
    @Watch('locked')
    @Watch('allow_resizing')
    @Watch('has_controls')
    private lock_objects_movements_changed(v: boolean) {
        this.fjs_object?.set({
                                 lockMovementX: this.lock_movements,
                                 lockMovementY: this.lock_movements,
                                 lockScalingX : this.lock_movements,
                                 lockScalingY : this.lock_movements,
                                 lockRotation : this.lock_movements,
                                 hasControls  : this.has_controls
                             });
    }

    @Watch('evented')
    private evented_changed() {
        this.fjs_object?.set({evented: this.evented});
    }
}