var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"px-3 text-left",attrs:{"id":"payments-report"}},[_c('h3',[_vm._v("Collections Report")]),_c('b-card',{staticClass:"mb-3",attrs:{"id":"filter","header":"Filters"}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',[_c('show-selector',{staticClass:"mb-4 w-100",staticStyle:{"max-width":"660px"},attrs:{"single_selection":true,"use_dropdown_form":true,"auto_close_dropdown":true,"dropdown_title":"Choose show"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}})],1),_c('div',{staticClass:"d-inline-flex mb-3 flex-column flex-sm-row"},[_c('b-form-group',{staticClass:"mr-3",attrs:{"label":"Date Range"}},[_c('b-form-select',{attrs:{"options":_vm.predefined_date_ranges},model:{value:(_vm.selected_date_range),callback:function ($$v) {_vm.selected_date_range=$$v},expression:"selected_date_range"}})],1),_c('b-form-group',{staticClass:"mr-3",attrs:{"label":"Start Date"}},[_c('b-form-datepicker',{attrs:{"max":_vm.$repo.ReHelper.today,"reset-button":""},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}})],1),_c('b-form-group',{attrs:{"label":"End Date"}},[_c('b-form-datepicker',{attrs:{"max":_vm.$repo.ReHelper.today,"min":_vm.start_date || '',"reset-button":""},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}})],1)],1)])],1),[_c('b-button',{staticClass:"float-right mb-4",attrs:{"variant":"primary"},on:{"click":_vm.get_csv}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'fa-file-csv']}}),_vm._v(" Export CSV ")],1),_c('div',{staticClass:"clearfix"}),_c('em',{staticClass:"d-block mb-2"},[_vm._v(" "+_vm._s(_vm.total_rows)+" "+_vm._s(_vm.Pluralize.plural('item'))+" ")]),_c('b-table',{ref:"table",attrs:{"items":_vm.data_provider,"fields":[
              'exhibitor',
              'email',
              {key:'status', formatter:a=>_vm.$repo.ReHelper.format_booking_status(a)},
              {key:'total', formatter:a=>_vm.$repo.ReHelper.format_money(a)},
              {key:'payments_total', formatter:a=>_vm.$repo.ReHelper.format_money(a)},
              {key:'balance_due', formatter:a=>_vm.$repo.ReHelper.format_money(a)},
            ],"busy":_vm.busy,"stacked":"md","show-empty":""}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }