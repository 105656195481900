
import {Component, Prop, Vue} from 'vue-property-decorator';
import BookingsTable from "@/components/generic/BookingsTable.vue";
import Payment from "@/classes/bookings/Payment";
import Booking from "@/classes/bookings/Booking";
import {BookingsRepo} from "@/classes/repos/BookingsRepo";
import App from "@/App.vue";

@Component<MovePaymentPopup>(
    {
      components: {BookingsTable}
    }
)
export default class MovePaymentPopup extends Vue {
  @Prop()
  vendor_id!: number;

  payment: Payment | null = null;

  get filter_request() {
    return {vendor_ids: [this.vendor_id], status: -1};
  }

  private editor_visible = false;
  private selected_booking: Booking | null = null;

  open(payment: Payment) {
    this.payment = payment;
    this.selected_booking = null;
    this.editor_visible = true;
  }

  private booking_selected(items: Booking[]) {
    this.selected_booking = items[0];
  }

  async move_payment() {
    const {errors, success} = await BookingsRepo.move_payment(this.payment!.id, this.selected_booking!.id);

    if (errors)
      App.notify(errors.single_err_msg, 'danger');
    else {
      this.$emit('booking_changed');
      App.notify('Payment successfully moved');
    }
  }
}
