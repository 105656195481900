
import {Component, Prop, Vue} from 'vue-property-decorator';
import {pwd_regex, pwd_requirement} from "@/classes/common/Const";

@Component<PasswordEditor>(
    {}
)
export default class PasswordEditor extends Vue {
  @Prop()
  pwd!: string;

  @Prop()
  username?: string;

  private pwd_confirm = '';

  // private error_msg = '';

  private pwd_requirement = pwd_requirement;

  @Prop({default: false})
  submit_pressed!: boolean;

  private pwd_valid(): boolean | null {
    if (!this.submit_pressed) return null;
    return pwd_regex.test(this.pwd);
  }

  private pwd_len_valid(): boolean | null {
    if (!this.submit_pressed) return null;
    return this.pwd.length >= 5;
  }

  private pwd_confirm_valid(): boolean | null {
    if (!this.submit_pressed) return null;
    return this.pwd === this.pwd_confirm;
  }

  form_valid(): boolean {
    return this.pwd_valid() !== false && this.pwd_len_valid() !== false && this.pwd_confirm_valid() !== false;
  }

  clear() {
    this.pwd_confirm = '';
    this.$emit('changed', '');
  }
}
