
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component<CheckoutThankYou>(
    {
    }
)
export default class CheckoutThankYou extends Vue {
  @Prop()
  booking_id!: number;
}
