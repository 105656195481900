
import {Component, Watch} from 'vue-property-decorator';
import EntityTable, {field_definition} from "@/components/generic/EntityTable.vue";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import Show from "@/classes/floor-map/Show";
import ShowGenericEditor from "@/components/editors/ShowGenericEditor.vue";
import {AppRepo} from "@/classes/repos/AppRepo";

@Component<ShowsTable>(
    {
      components: {ShowGenericEditor}
    }
)
export default class ShowsTable extends EntityTable {
  protected get default_fields(): (field_definition | undefined)[] {
    return [
      {key: 'id', label: 'Select', sortable: false,},
      {key: 'name', label: 'Show', sortable: true,},
      {key: 'location', label: 'Location', sortable: true,},
      // {key: 'dates', label: 'Date', sortable: true,},
      {
        key: 'start_date',
        label: 'Date',
        sortable: true,
        formatter: (value: string, key: string, item: Show) => item.dates
      },
      this.admin_mode ? {key: 'publish_status', label: 'Visibility', sortable: true,} : undefined,
      this.admin_mode ? {key: 'contact_number', label: 'Contact Number', sortable: true,} : undefined,
      this.admin_mode ? {key: 'edit', label: 'Edit', sortable: false,} : undefined,
      this.admin_mode ? {key: 'clone', label: 'Clone', sortable: false,} : undefined,
      this.admin_mode ? {key: 'delete', label: 'Delete', sortable: false,} : undefined,
    ];
  }

  repo = ShowsGenericRepo;
  TEntity = Show;
  protected readonly editor_comp = 'ShowGenericEditor';
  show_id_as_radio = true;
  sort_by = 'start_date';
  sort_desc = AppRepo.ui_level === "admin";

  private get admin_mode() {
    return AppRepo.ui_level === "admin";
  }

  get editing_enabled() {
    return !!this.repo && this.admin_mode;
  }

  get search_request(): any {
    return this.admin_mode ? {} : {published_only: true};
  }

  private navigating = false;

  protected row_selected(shows: Show[]) {
    if (!shows?.length) return;

    const sh = shows[0];
    ShowsGenericRepo.select_show(sh);
    if (!this.admin_mode)
      if (!this.navigating) {
        this.navigating = true;
        this.$router.push({name: 'layout', params: {slug: sh.slug}});
      }
    // this.$emit('on_items_selected', shows);
  }

  get selected_id(): number | undefined {
    return ShowsGenericRepo.current_show?.id;
  }

  set selected_id(value: number | undefined) {
    //emitting event instead
  }

  protected selected_id_changed(e: Show, index: number) {
    ShowsGenericRepo.select_show(e);
    this.$refs.table.selectRow(index);
  }

  @Watch('admin_mode')
  private admin_mode_changed() {
    this.sort_desc = this.admin_mode;
  }

}
