export default class DataProviderContext {
    currentPage: number = 1;
    perPage: number = 15;
    filter: string | RegExp | Object = '';
    sortBy: string = 'id';
    sortDesc: boolean = false;


    constructor(currentPage?: number, perPage?: number, filter?: string | RegExp | Object, sortBy?: string, sortDesc?: boolean) {
        this.currentPage = currentPage ?? 1;
        this.perPage = perPage ?? 15;
        this.filter = filter ?? '';
        this.sortBy = sortBy ?? 'id';
        this.sortDesc = sortDesc ?? false;
    }

    static from(o: any) {
        return new DataProviderContext(
            o.currentPage,
            o.perPage,
            o.filter,
            o.sortBy,
            o.sortDesc,
        )
    }
}