
import {Component, Prop, Vue} from 'vue-property-decorator';
import {BookingsRepo} from "@/classes/repos/BookingsRepo";
import Config from "@/classes/common/Config";
import Booking from "@/classes/bookings/Booking";
import ReSpinner from "@/components/common/re-spinner.vue";
import App from "@/App.vue";

@Component<ExportPdfReceipt>(
    {
      components: {ReSpinner}
    }
)
export default class ExportPdfReceipt extends Vue {
  @Prop()
  booking!: Booking | null;

  private waiting_for_pdf_receipt = false;

  private async export_receipt() {
    const wnd = window.open('', '_blank');
    wnd!.document.write('Loading...');

    this.waiting_for_pdf_receipt = true;
    const {errors, temp_data_id} = await BookingsRepo.pdf_receipt(this.booking!.id);
    this.waiting_for_pdf_receipt = false;

    if (errors) {
      App.notify(errors.single_err_msg, 'danger');
      wnd!.close();
    } else
      wnd!.location.assign(`${Config.base_URL}/booking/Receipt?id=${temp_data_id}`);
  }
}
