
import {Component, Vue} from 'vue-property-decorator';
import {library} from '@fortawesome/fontawesome-svg-core'
import {faUserCircle} from '@fortawesome/free-regular-svg-icons'
import App from "@/App.vue";
import {AuthRepo} from '@/classes/repos/AuthRepo';
import Logo from "@/components/common/Logo.vue";
import {logo_url} from '@/classes/common/Const';
import AccountMenu from "@/components/common/AccountMenu.vue";
import {JwtRepo} from '@/classes/repos/JwtRepo';
import {UsersRepo} from "@/classes/repos/UsersRepo";

library.add(faUserCircle);

@Component<TopPanel>(
    {
      components: {AccountMenu, Logo}
    }
)
export default class TopPanel extends Vue {
  private App = App;
  private AuthRepo = AuthRepo;
  private JwtRepo = JwtRepo;
  private UsersRepo = UsersRepo;
  private logo_url = logo_url;

  get vendor_id() {
    return JwtRepo.vendor_id;
  }

  set vendor_id(id) {
    UsersRepo.switch_company(id).then(() => App.notify(`Switched to ${JwtRepo.current_company.name || 'company'}`));
  }
}
