import IRW_Size from "@/classes/floor-map/IRW_Size";
import IBoothQuickProps from "@/classes/floor-map/IBoothQuickProps";
import ComplexBoothTypePart from "@/classes/floor-map/Booth";
import {ReHelper} from "@/classes/common/ReHelper";
import {Exclude, instanceToPlain} from "class-transformer";
import {BoothTypesGenericRepo} from "@/classes/common/internal";

export class BoothType implements IRW_Size, IBoothQuickProps {
    @Exclude()
    id: number;
    @Exclude()
    show_id: number;
    letter_index: string;
    title: string;
    desc: string;
    rw_height: number;
    rw_width: number;
    days: number;
    price: number;
    table_cnt: number;
    fill: string;
    imageURL?: string;
    is_favourite: boolean;
    @Exclude()
    png_data_url?: string;

    @Exclude()
        //indicates that the booth type needs to be saved when saving show
        is_dirty = false;

    type: 'BoothType' | 'ComplexBoothType' = 'BoothType';

    private static UID_base = 0;

    @Exclude()
    readonly UID = ++BoothType.UID_base;

    constructor(id: number,
                show_id: number,
                letter_index: string,
                title: string,
                desc: string,
                rw_height: number,
                rw_width: number,
                days: number,
                price: number,
                table_cnt: number,
                fill: string,
                is_favourite: boolean = true,
                imageURL?: string,
                png_data_url?: string) {
        this.id = id;
        this.show_id = show_id;
        this.letter_index = letter_index;
        this.title = title;
        this.desc = desc;
        this.rw_height = rw_height;
        this.rw_width = rw_width;
        this.days = days;
        this.price = price;
        this.table_cnt = table_cnt;
        this.fill = fill;
        this.is_favourite = is_favourite;
        this.imageURL = imageURL;
        this.png_data_url = png_data_url;
    }

    static get_type_id(descriptor: { rw_width: number, rw_height: number, price: number }) {
        return [descriptor.rw_width, descriptor.rw_height, descriptor.price].join('x'); //
    }

    sizes_equal(t2: { rw_width: number, rw_height: number }): boolean {
        return this.rw_width === t2.rw_width && this.rw_height === t2.rw_height;
    }

    get type_id() {
        return BoothType.get_type_id(this);
    }

    get short_desc() {
        return `${this.title} - ${this.desc}`
    }

    get name() {
        return this.title;
    }

    get is_public(): boolean {
        return this.id > 0;
    }

    static from(o: any, force_cloning: boolean = false): BoothType {
        if (o.json) return this.from({...JSON.parse(o.json), ...o, ...{json: undefined}});

        if (o.type == 'ComplexBoothType') return ComplexBoothType.from(o, force_cloning);

        if (!!BoothTypesGenericRepo.get_by_id(o.id) && !force_cloning) {
            return BoothTypesGenericRepo.get_by_id(o.id)!;
        } else {
            return new BoothType(
                o.id,
                o.show_id,
                o.letter_index,
                o.title,
                o.desc,
                o.rw_height,
                o.rw_width,
                o.days,
                o.price,
                o.table_cnt,
                o.fill,
                o.is_favourite,
                o.imageURL,
            );
        }
    }

    /**
     *
     * @param o: {id:number, json:string}
     */
    static from_wrapped(o: any): BoothType {
        const plain_obj = JSON.parse(o.json);
        ReHelper.set_id(plain_obj, o.id);
        return BoothType.from(plain_obj);
    }

    clone(setup_as_new = false): BoothType {
        const clone = BoothType.from(this, true);
        if (setup_as_new) {
            clone.id = 0;
            clone.is_dirty = true;
            clone.fill = ReHelper.random_light_color_hex();
        }
        return clone;
    }

    copyTo(bt: BoothType) {
        bt.show_id = this.show_id;
        bt.letter_index = this.letter_index;
        bt.title = this.title;
        bt.desc = this.desc;
        bt.rw_height = this.rw_height;
        bt.rw_width = this.rw_width;
        bt.days = this.days;
        bt.price = this.price;
        bt.table_cnt = this.table_cnt;
        bt.fill = this.fill;
        bt.is_favourite = this.is_favourite;
        bt.imageURL = this.imageURL;
    }

    rw_sizes() {
        return `${ReHelper.format_num(this.rw_width)} x ${ReHelper.format_num(this.rw_height)} feet`;
    }

    static default(show_id: number = 0): BoothType {
        const fill = ReHelper.random_light_color_hex();
        return new BoothType(0, show_id, "", "", "", 0, 0, 0, 0, 0, fill, false, undefined);
    }

    prepare_for_save() {
        return {
            id     : this.id,
            show_id: this.show_id,
            json   : JSON.stringify(instanceToPlain(this))
        };
    }
}

export class ComplexBoothType extends BoothType {
    parts: ComplexBoothTypePart[] = [];
    type: 'BoothType' | 'ComplexBoothType' = 'ComplexBoothType';

    constructor(id: number,
                show_id: number,
                letter_index: string,
                title: string,
                desc: string,
                rw_height: number,
                rw_width: number,
                days: number,
                price: number,
                fill: string,
                is_favourite: boolean = true,
                parts: ComplexBoothTypePart[],
                imageURL?: string,
                png_data_url?: string) {
        super(id, show_id, letter_index, title, desc, rw_height, rw_width, days, price, parts.length, fill, is_favourite, imageURL, png_data_url);
        this.parts = parts;
    }

    get type_id() {
        return this.letter_index;
    }

    static from(o: number | any, force_cloning: boolean = false): ComplexBoothType {
        // force_cloning = true;
        return typeof o === "number" ? (BoothTypesGenericRepo.get_by_id(o) as ComplexBoothType)
                                     : new ComplexBoothType(
                o.id,
                o.show_id,
                o.letter_index,
                o.title,
                o.desc,
                o.rw_height,
                o.rw_width,
                o.days,
                o.price,
                o.fill,
                o.is_favourite,
                o.parts?.map((p: any) => ComplexBoothTypePart.from(p)),
                o.imageURL,
            );
    }

    override copyTo(bt: ComplexBoothType) {
        super.copyTo(bt);
        bt.parts = this.parts;
    }
}