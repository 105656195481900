import {general_err_msg} from "@/classes/common/Const";

export default class ErrorForDisplay {
    private ex?: any;
    private readonly msg?: string;

    get is_single_error() {
        return true;
    }

    get single_err_msg(): string {
        return this.msg ?? this.ex.response?.data?.detail ?? this.ex.response?.data?.title ?? this.ex.response?.data ?? general_err_msg;
    }

    get errors_array() {
        if (this.ex?.response?.data?.errors instanceof Array) return this.ex.response?.data?.errors;
        if (this.ex?.response?.data instanceof Array) return this.ex.response?.data;
        return undefined;
    }

    constructor(ex: any) {
        if (typeof (ex) === 'string')
            this.msg = ex;
        else
            this.ex = ex;
    }

}