
import {Component} from 'vue-property-decorator';
import EntitySelector from "@/components/generic/EntitySelector.vue";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import Show from "@/classes/floor-map/Show";
import {search_field_description} from "@/components/generic/EntitySearchForm.vue";
import {ReHelper} from "@/classes/common/ReHelper";

@Component<ShowSelector>(
    {}
)
export default class ShowSelector extends EntitySelector {
  repo = ShowsGenericRepo;
  found_items: Show[] = [];

  protected entity_plural_name = 'shows';

  protected search_fields: (string | search_field_description)[] = ['name',
                                                                    {name: 'location',},
                                                                    {name: 'start_date', row_start: true, type: 'date'},
                                                                    {name: 'end_date', type: 'date'},
  ];

  protected search_results_fields = ['name', 'location', 'start_date'];

  protected entity_short_description(s: Show): string {
    return `${s.name} (${s.location} ${s.dates ?? ReHelper.dates(s.start_date, s.end_date)})`;
  }

}
