export const default_title = 'Repticon';
export const pix_per_foot = 10;
export const base_URL = process.env.VUE_APP_SERVER_URL;
export const image_tmp_web_path = '/images/uploads';
export const rw_grid_step_x_default = 8;
export const rw_grid_step_y_default = 8;
export const logo_url = "https://repticon.com/wp-content/uploads/2021/03/repticon-logo.png";
export const recaptcha_siteKey = "6LfuIvgcAAAAALu5s-59qNdNwcyzCMadBKOwR36M";
export const pwd_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
export const pwd_requirement = 'A password should contain at least one lower case letter, one upper case letter and one number';
export const general_err_msg = 'An error occurred while processing your request';

