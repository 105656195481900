import {PhysicalAddress} from "@/components/common/AddressEditor.vue";
import {Expose, instanceToPlain, Transform, Type} from "class-transformer";
import CompanyContact from "@/classes/companies/CompanyContact";
import User from "@/classes/users/User";
import {ReHelper} from "@/classes/common/ReHelper";

export type Social = { facebook?: string, instagram?: string, twitter?: string };

export default class Company {
    id: number;
    name: string;
    description: string;
    logo: string;
    email: string;
    phone: string;
    phone_alt: string;
    website: string;
    admin_notes: string;
    created_on: string;
    is_registered: boolean;
    store_credit: number;
    total_overpayments: number;

    @Type(() => User)
    users: User[] = [];

    @Expose({name: 'address_json'})
    @Transform(({value}) => JSON.parse(value), {toClassOnly: true})
    @Transform(({value}) => JSON.stringify(value), {toPlainOnly: true})
    address: PhysicalAddress;


    @Expose({name: 'social_json'})
    @Transform(({value}) => JSON.parse(value), {toClassOnly: true})
    @Transform(({value}) => JSON.stringify(value), {toPlainOnly: true})
    social: Social;

    @Type(() => CompanyContact)
    contacts?: CompanyContact[];

    get short_description() {
        return this.name;
    }

    constructor(id: number, name: string, description: string, logo: string, email: string, phone: string, phone_alt: string, website: string,
                admin_notes: string, created_on: string, is_registered: boolean, store_credit: number, total_overpayments: number, address: PhysicalAddress = {}, social: Social = {}, users: User[] = []) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.logo = logo;
        this.email = email;
        this.phone = phone;
        this.phone_alt = phone_alt;
        this.website = website;
        this.admin_notes = admin_notes;
        this.created_on = created_on;
        this.is_registered = is_registered;
        this.store_credit = store_credit;
        this.total_overpayments = total_overpayments;
        this.address = address;
        this.social = social;
        this.users = users;
    }

    static from(o: any) {
        return new Company(
            o.id,
            o.name,
            o.description,
            o.logo,
            o.email,
            o.phone,
            o.phone_alt,
            o.website,
            o.admin_notes,
            o.created_on,
            o.is_registered,
            o.store_credit,
            o.total_overpayments,
            o.address,
            o.social,
            o.users?.map((u: any) => User.from(u)),
        )
    }

    static default(): Company {
        return new Company(0, "", "", "", "", "", "", "", "", "", false, 0, 0);
    }

    clone() {
        let clone = Company.from(this);
        clone.is_registered = false;
        return clone;
    }

    prepare_for_save() {
        this.created_on ||= ReHelper.now;
        const o = instanceToPlain(this);
        o.vendor_mappings = this.users?.map(u => ({user_id: u.id})) ?? [];
        return o;
    }

    init_contacts() {
        if (!this.contacts?.length)
            this.contacts = [
                CompanyContact.from({type: 'Owner', address_json: '{}', social_json: '{}'}),
            ];

        if (!this.contacts?.find(c => c.type === 'Alt Contact'))
            this.contacts.push(
                CompanyContact.from({type: 'Alt Contact', address_json: '{}', social_json: '{}'}),
            )
    }
}