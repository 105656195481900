import {LikeableEntityGenericRepo} from "@/classes/repos/LikeableEntityGenericRepo";
import Vue from "vue";
import Company from "@/classes/companies/Company";
import axios from "axios";
import {JwtRepo} from "@/classes/repos/JwtRepo";
import ErrorForDisplay from "@/classes/common/ErrorForDisplay";
import DataProviderContext from "@/classes/common/DataProviderContext";

class _CompaniesRepo extends LikeableEntityGenericRepo<{ created_on?: string, has_bookings?: boolean, limit_by_ids?: number[], exclude_ids?: number[] }> {
    constructor() {
        super(Company, 'Company');
    }

    current_company: Company | null = null;

    async is_registered_company(id: number): Promise<{ success: boolean; is_registered: boolean; errors?: ErrorForDisplay }> {
        try {
            const {data}: { data: { is_registered: boolean } } = await axios.get(`/${this.entity}/is_registered_company`, {params: {id}});
            return {success: true, is_registered: data.is_registered};
        } catch (ex) {
            return {success: false, is_registered: false, errors: new ErrorForDisplay(ex)};

        }
    }

    async load_vendor_company(vendor_id?: number) {
        vendor_id ??= JwtRepo.vendor_id;
        this.current_company = null;
        if (!vendor_id) return;

        this.current_company = (await this.details(vendor_id)).entity as Company;
    }

    async get_company_users(): Promise<{ first_name: string; last_name: string; email: string }[]> {
        const {data}: { data: { users: { first_name: string, last_name: string, email: string, } [] } } = await axios.get(`/${this.entity}/get_company_users`);
        return data.users;
    }

    async remove_user(email: string): Promise<{ success: boolean; errors?: ErrorForDisplay }> {
        try {
            await axios.post(`/${this.entity}/remove_user`, {}, {params: {email}});
            return {success: true};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async vendors_report(ctx: DataProviderContext, show_id: number, csv: boolean = false): Promise<any> {
        const {data} = await axios.get('/reports/vendors', {params: {show_id, csv}});
        return data;
    }

    async orders_report(ctx: DataProviderContext, show_id: number, csv: boolean = false): Promise<any> {
        const {data} = await axios.get('/reports/orders', {params: {show_id, csv}});
        return data;
    }

    async sellable_services_report(ctx: DataProviderContext, show_id: number, csv: boolean = false): Promise<any> {
        const {data} = await axios.get('/reports/sellable_services', {params: {show_id, csv}});
        return data;
    }

    async get_names(ids: number[]): Promise<Company[]> {
        const {data} = await axios.get('/company/get_names', {params: {ids}});

        return data;
    }
}

export const CompaniesRepo = Vue.observable(new _CompaniesRepo);