
import {Component, Vue} from 'vue-property-decorator';
import ReFilter from "@/components/common/ReFilter.vue";
import ShowsTable from "@/components/generic/ShowsTable.vue";
import OverdueBookingsWarning from "@/components/floor-map/OverdueBookingsWarning.vue";

@Component(
    {
        components: {OverdueBookingsWarning, ShowsTable, ReFilter}
    }
)
export default class ShowsComp extends Vue {
    // private ShowsGenericRepo = ShowsGenericRepo;

    private filter: string | null = null;

    /*  private fields = [
        {key: 'id', label: 'Select', sortable: false,},
        {key: 'name', label: 'Show', sortable: true,},
        {key: 'location', label: 'Location', sortable: true,},
        {key: 'dates', label: 'Date', sortable: true,},
        {key: 'contact_number', label: 'Contact Number', sortable: true,},
        {key: 'edit', label: 'Edit', sortable: false,},
        {key: 'clone', label: 'Clone', sortable: false,},
        {key: 'delete', label: 'Delete', sortable: false,},
      ];

      get current_show_id(): number | undefined {
        return ShowsGenericRepo.current_show?.id;
      }

      set current_show_id(v) {
        //emitting event instead
      }*/

}
