
import {Component, Vue} from 'vue-property-decorator';
import {AuthRepo} from "@/classes/repos/AuthRepo";
import {logo_url} from "@/classes/common/Const";
import Logo from "@/components/common/Logo.vue";
import {AppRepo} from "@/classes/repos/AppRepo";
import {JwtRepo} from "@/classes/repos/JwtRepo";

@Component<LoginForm>(
    {
      components: {Logo}
    }
)
export default class LoginForm extends Vue {
  $refs!: {}

  private email = '';
  private pwd = '';
  private errorMsg = '';

  private logo_url = logo_url;

  private async submit(ev: Event) {

    await this.$recaptchaLoaded();
    const recaptcha_token = await this.$recaptcha('login');

    const {success, reason} = await AuthRepo.login(this.email, this.pwd, recaptcha_token);
    if (success) {
      this.$emit('success');
      if (JwtRepo.is_admin)
        AppRepo.ui_level = "admin";
    } else {
      this.errorMsg = reason ?? 'Invalid login attempt';
    }
  }
}
