import MapObjectCompBase from "@/components/floor-map/MapObjectCompBase";
import Booth, {MapObject} from "@/classes/floor-map/Booth";
import {Component, Prop} from "vue-property-decorator";
import ReFabricGroup from "@/components/re-fabric/re-fabric-group.vue";
import ReFabricText from "@/components/re-fabric/re-fabric-text.vue";
import ReFabricObject from "@/components/re-fabric/re-fabric-object";
import ICanvasParent from "@/components/interfaces/ICanvasParent";
import Loc from "@/classes/common/Loc";
import {BoothTypesGenericRepo} from "@/classes/repos/BoothTypesGenericRepo";
import {pix_per_foot} from "@/classes/common/Const";
import {MapRepo} from "@/classes/repos/MapRepo";

@Component
export default class BoothCompBase extends MapObjectCompBase {
    $refs!: {
        // popup: BPopover,
        group: ReFabricGroup,
        text: ReFabricText,
    }
    type = 'BoothCompBase';

    @Prop()
    booth!: Booth;

    @Prop()
    index_within_type?: number;

    @Prop()
    is_occupied!: boolean;

    @Prop()
    is_highlighted!: boolean;

    do_update_from_fjs = true;

    get map_object(): MapObject {
        return this.booth;
    }

    get fjs_wrap_object(): ReFabricObject {
        return this.$refs.group;
    }

    private get text() {
        return this.is_mounted && this.config.debug ? this.$refs.group?.debug_name : `${this.booth.booth_type?.letter_index}${this.booth.numeric_index}`;
    }

    get debug_name() {
        return this.$refs.group?.debug_name;
    }

    get booth_override_fill() {
        if (this.booth.is_on_hold) return MapRepo.booth_on_hold_fill_color;
        return undefined;
    }

    addChild(ch: ReFabricObject): void {
        (this.$parent as unknown as ICanvasParent).addChild(ch);
    }

    removeChild(ch: ReFabricObject): void {
        throw new Error('Method not implemented.');
    }

    child_modified(booth: Booth, loc: Loc, angle?: number, size_changed?: boolean) {
        if (!this.do_update_from_fjs) return;

        // console.log(`${this.constructor.name}.child_modified`, this.debug_name, arguments);

        const curr_size: { rw_width: number, rw_height: number } = {...booth.booth_type};

        booth.update_loc(loc, angle);

        const new_size = {rw_width: loc.width / pix_per_foot, rw_height: loc.height / pix_per_foot};

        if (size_changed)
            BoothTypesGenericRepo.change_booth_type_if_needed(booth, curr_size, new_size);

    }

    toggle_indices(visible: boolean) {
        // console.log(`${this.constructor.name}.toggle_indices`, this.$refs.text?.fjs_object)
        this.$refs.text?.fjs_object?.set({visible: visible});
    }

}