
import Vue from "vue";
import {Component, Ref, Watch} from "vue-property-decorator";
import router from "@/router";
import BootstrapVue, {BootstrapVueIcons} from "bootstrap-vue";
import LoginForm from "@/components/common/LoginForm.vue";
import {VueReCaptcha} from "vue-recaptcha-v3";
import LoginPopup from "@/components/common/LoginPopup.vue";
import {recaptcha_siteKey} from "@/classes/common/Const";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import {BoothTypesGenericRepo} from "@/classes/repos/BoothTypesGenericRepo";
import {AppInstance, AppRepo, init_app_instance} from "@/classes/repos/AppRepo";
import {MapRepo} from "@/classes/repos/MapRepo";
import {ReHelper} from "@/classes/common/ReHelper";

import '@/scss/repticon-app.scss';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueReCaptcha, {siteKey: recaptcha_siteKey});
// Vue.use(Vuelidate);

@Component<App>({
    components: {
        LoginPopup,
        LoginForm,
    },
    router: router,
})
export class App extends Vue {
    static instance: App;

    @Ref('login_popup')
    login_popup!: LoginPopup;

    common_modal_content = '';
    common_modal_variant = 'info';

    ShowsGenericRepo = ShowsGenericRepo;

    MapRepo = MapRepo;

    async created() {
        App.instance = this;
        init_app_instance(this);
        // console.log(`${this.constructor.name}.created : load_shows started`);
    }

    mounted() {
        window.addEventListener('beforeunload', this.beforeunload)
    }

    beforeunload(e: Event) {
        if (this.$route.meta?.group_name == 'map pages' && ShowsGenericRepo.current_show_has_changes) {
            e.preventDefault();
            e.returnValue = true;
        }
    }

    notify(msg: string, variant = 'success') {
        this.$bvToast.hide('re-toast');

        this.$bvToast.toast(msg, {
            title: AppRepo.ui_level === "admin" ? 'Repticon Editor' : 'Repticon',
            autoHideDelay: 4000,
            appendToast: true,
            variant: variant,
            noAutoHide: variant === 'danger',
            id: 're-toast'
        })
    }

    static notify(msg: string, variant = 'success') {
        AppInstance.notify(msg, variant);
    }

    static clear_notifications() {
        AppInstance?.$bvToast?.hide('re-toast');
    }

    modal(msg: string, variant = 'info') {
        this.common_modal_content = msg.replaceAll('\n', '<br/>');
        this.common_modal_variant = variant;
        this.$bvModal.show('common-modal');
    }

    @Watch('ShowsGenericRepo.current_show.id')
    private show_switched() {
        BoothTypesGenericRepo.show_switched();
    }

    @Watch('ShowsGenericRepo.current_show.id')
    @Watch('MapRepo.current_map.id')
    private map_switched() {
        MapRepo.undo_buffer.splice(0);
        MapRepo.undo_buffer_pos = 0;
        (window as any).current_map = MapRepo.current_map;
    }

    // changesets = require('json-diff-ts');

    @Watch('MapRepo.current_map_objects_serialized')
    private changes_made_in_current_map() {
        if (MapRepo.listening_undoable_changes) {
            if (MapRepo.undo_buffer_pos) {
                MapRepo.undo_buffer.splice(MapRepo.undo_buffer.length - MapRepo.undo_buffer_pos);
            }
            // console.log('changes_made_in_current_map')
            MapRepo.undo_buffer.push({json: MapRepo.current_map_objects_serialized, ts: ReHelper.now});
            MapRepo.undo_buffer_pos = 0;

            // const len = MapRepo.undo_buffer.length;
            // console.log(flattenChangeset(this.changesets.diff(JSON.parse(MapRepo.undo_buffer[len - 2] ?? '{}'), JSON.parse(MapRepo.undo_buffer[len - 1]))));
        }
    }

}


export {App as default}

