
import {Component, Prop, Vue} from 'vue-property-decorator';
import User from "@/classes/users/User";
import Booking from "@/classes/bookings/Booking";

@Component<CompanyLink>(
    {}
)
export default class CompanyLink extends Vue {
  @Prop()
  data!: { item: User | Booking };

  /*@Prop({default: null})
  component_settings!: { id_field: string, text_field: string } | null;*/
}
