import {plainToInstance} from "class-transformer";
import {BookingsRepo} from "@/classes/repos/BookingsRepo";

export default class MultiTableDiscount {
    id: number;
    show_id: number;
    booth_type: string;
    booths_count: number;
    discount: number;
    created?: string;

    get is_valid() {
        return this.booths_count >= BookingsRepo.multi_table_discount_min_booth_count && this.booth_type;
    }

    get validation_error() {
        if (this.booths_count < BookingsRepo.multi_table_discount_min_booth_count)
            return `There should be at least ${BookingsRepo.multi_table_discount_min_booth_count} booths in group`;
        else if (!this.booth_type)
            return 'Booth type is not set';
        else return null;
    }

    constructor(id: number, show_id: number, booth_type: string, booths_count: number, discount: number, created?: string) {
        this.id = id;
        this.show_id = show_id;
        this.booth_type = booth_type;
        this.booths_count = booths_count;
        this.discount = discount;
        this.created = created;
    }

    static from(o: object): MultiTableDiscount {
        return plainToInstance(MultiTableDiscount, o);
    }

}
