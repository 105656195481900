import FloorPlan from "@/classes/floor-map/FloorPlan";
import Booth, {MapObject} from "@/classes/floor-map/Booth";
import {MapRepo} from "@/classes/repos/MapRepo";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";

export default class FloorMap {

    mapObjects: MapObject[];

    floor_plan?: FloorPlan;

    id: number;

    grid_on = true;

    constructor(mapObjects: MapObject[], floor_plan?: FloorPlan, id?: number, grid_on: boolean = true) {
        this.mapObjects = mapObjects ?? [];
        this.floor_plan = floor_plan;
        this.grid_on = grid_on;
        this.id = id ?? ShowsGenericRepo.current_show?.get_new_floorplan_id() ?? 0;
    }

    static from(o: any): FloorMap {
        // o.mapObjects?.filter((x: any) => x.booth_type_id === 782).forEach((x: any) => x.type = 'ComplexBooth');

        const map = new FloorMap(
            o.mapObjects?.map((x: any) => MapObject.from(x)),
            FloorPlan.from(o.floor_plan),
            o.id,
            o.grid_on,
        );
        return map;
    }

    clone() {
        return FloorMap.from(this);
    }

    make_booth_types_internal(): FloorMap {
        this.all_booths().forEach(b => b.make_booth_type_internal());
        return this;
    }

    all_booths(types = ['Booth', 'ComplexBooth']): Booth[] {
        return this.mapObjects.filter((o): o is Booth => types.includes(o.type));
    }

    all_booths_map(): { [p: string]: Booth } {
        return Object.fromEntries(this.all_booths().map(b => [b.booth_number, b]));
    }

    /*
        all_booths_map(): Map<string, Booth> {
            return new Map<string, Booth>(this.all_booths().map(b => [b.booth_number, b]));
        }
    */
    index_within_type(booth: Booth): number {
        const res = this.mapObjects.filter(o => o instanceof Booth && o.booth_type?.id === booth.booth_type?.id).indexOf(booth) + 1;
        return res;
    }

    remove(o: MapObject): void {
        const n = this.mapObjects.indexOf(o);
        this.mapObjects.splice(n, 1);
        MapRepo.selected_map_objects = [];

        if (o.booth_or_complex_booth?.booth_note_id)
            ShowsGenericRepo.remove_booth_note(o.booth_or_complex_booth!.booth_note_id);
    }

    static default() {
        return new FloorMap([], FloorPlan.default());
    }

    get has_fp_image() {
        return !!this.floor_plan?.fp_image?.image_url;
    }

}