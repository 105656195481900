
import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import {LikeableEntity, LikeableEntityGenericRepo, TLikeableEntity} from "@/classes/repos/LikeableEntityGenericRepo";
import EntityEditor from "@/components/generic/EntityEditor.vue";
import DataProviderContext from "@/classes/common/DataProviderContext";
import {BTable} from "bootstrap-vue";
import App from "@/App.vue";
import {ReHelper} from "@/classes/common/ReHelper";
import {RawLocation} from "vue-router";
import {startCase} from "lodash-es";
import ReSpinner from "@/components/common/re-spinner.vue";
import ErrorForDisplay from "@/classes/common/ErrorForDisplay";

export type field_definition =
    { label?: string; sortable?: boolean; key?: string; formatter?: string | Function; component?: string; component_settings?: any }
    | string;

@Component<EntityTable>(
    {
      components: {ReSpinner, EntityEditor}
    }
)
export default class EntityTable extends Vue {
  $refs!: {
    table: BTable;
    editor: EntityEditor;
  }

  // protected default_fields: (field_definition | undefined)[] = [];
  protected get default_fields(): (field_definition | undefined)[] {
    return [];
  }

  @Prop()
  fields?: field_definition[];

  @Prop()
  items?: LikeableEntity[];

  @Prop({default: 'single'})
  select_mode!: 'single' | 'multi' | 'range' | 'none';

  @Prop({default: false})
  do_confirm_delete!: boolean;

  @Prop({default: false})
  do_delete_in_db!: boolean;

  @Prop({default: null})
  private filter!: string | null;

  @Prop()
  table_props: any;

  @Prop({default: true})
  full_width!: boolean;

  protected actions: { key: string; text: string; to: (x: LikeableEntity) => RawLocation; target?: string }[] = [];

  protected sort_by = 'id';
  protected sort_desc = false;

  protected loaded_items: LikeableEntity[] = [];
  protected items_on_page: LikeableEntity[] = [];

  protected need_reload_from_server = true;

  format_date = ReHelper.format_date
  format_datetime = ReHelper.format_datetime
  format_money = ReHelper.format_money

  protected get effective_fields() {
    return (this.fields ?? this.default_fields).filter(f => !!f);
  }

  protected readonly TEntity!: TLikeableEntity;

  protected readonly editor_comp: string = '';
  protected readonly row_details_comp: string = '';

  protected currentPage = 1;
  protected totalRows = 0;
  protected perPage = 15;

  protected edit_link_text = 'Edit';

  protected show_id_as_radio = false;

  @Ref()
  private table_horiz_scroll_wrapper!: HTMLDivElement;

  @Ref()
  private top_scroll!: HTMLDivElement;

  protected comp_event_handlers = {};

  get n_first_rec() {
    return this.perPage * (this.currentPage - 1) + 1;
  }

  get n_last_rec() {
    return Math.min(this.perPage * this.currentPage, this.totalRows);
  }

  protected busy = false;
  private error = '';

  protected repo!: LikeableEntityGenericRepo<any>;

  protected get editing_enabled() {
    return !!this.repo;
  }

  protected get show_add_btn() {
    return true;
  }

  protected get entity_single_name(): string {
    return this.TEntity.name.toLowerCase().replace(/\_/g, ' ');
  }

  protected get search_request(): any {
    return {};
  }

  get selected_id(): number | undefined {
    return undefined;
  }

  set selected_id(value: number | undefined) {
    //emitting event instead
  }

  protected selected_id_changed(e: LikeableEntity, index: number) {
  }

  protected row_selected(items: LikeableEntity[]) {
    this.$emit('on_items_selected', items);
  }

  protected async data_provider(ctx: DataProviderContext): Promise<LikeableEntity[]> {
    this.busy = true;
    const res: { success: boolean; items: LikeableEntity[]; total: number; errors?: ErrorForDisplay } = await this.repo.list(ctx, this.search_request);
    this.totalRows = res.total;
    this.busy = false;

    if (res.success) {

      res.items.forEach((i: any) => i._showDetails = false);
      return this.loaded_items = this.items_on_page = res.items;

    } else {

      App.notify(res.errors!.single_err_msg, 'danger');
      return this.loaded_items = this.items_on_page = [];

    }
  }

  protected setup_new_item(): LikeableEntity {
    return this.TEntity.default();
  }

  async add() {
    await this.$refs.editor.open(this.setup_new_item());
    this.need_reload_from_server = true;
    this.$emit('item_added')
    this.$refs.table.refresh();
  }

  protected show_edit_cmd(e: LikeableEntity) {
    return true;
  }

  async edit(e: LikeableEntity) {
    await this.$refs.editor.open(e);
    this.need_reload_from_server = true;
    this.$refs.table.refresh();
  }

  @Watch('filter')
  @Watch('search_request', {deep: true})
  refresh() {
    this.currentPage = 1;
    this.need_reload_from_server = true;
    this.$refs.table.refresh();
  }

  protected table_refreshed() {
    this.need_reload_from_server = false;
  }

  private async clone(id: number) {
    await this.repo.clone(id);
    this.$refs.table.refresh();
  }

  private async remove(item: LikeableEntity) {
    if (this.do_confirm_delete) {
      const confirm = await this.$bvModal.msgBoxConfirm(`Remove ${this.entity_single_name} "${item.name}"?`);
      if (!confirm) return;
    }

    let removed_successfully = true;

    if (this.do_delete_in_db) {
      const {success, errors} = await this.repo.remove(item.id);
      if (success) {
        this.need_reload_from_server = true;
        this.$refs.table.refresh();
      } else {
        removed_successfully = false;
        App.notify(errors!.single_err_msg, 'danger');
      }
    }

    if (removed_successfully) {
      this.$emit('entity_remove', item);
      App.notify(`${startCase(this.entity_single_name)} ${item.name ? `"${item.name}"` : ''} removed`);
    }
  }

  protected async toggle_details() {
    this.loaded_items.forEach((i: any) => i._showDetails = !i._showDetails);
  }

  private handle_horizontal_scroll(e: Event) {
    // console.log(this.top_scroll.scrollLeft, this.table_horiz_scroll_wrapper.scrollLeft)
    this.table_horiz_scroll_wrapper.scrollLeft = this.top_scroll.scrollLeft;
  }

  protected tbodyTrClass(item: TLikeableEntity | null, type: string) {
    return '';
  }


}
