
import {Component, Prop, Vue} from 'vue-property-decorator';
import {BookingQuiz_v2} from "@/classes/bookings/quiz/BookingQuiz";

@Component<BookingQuizComp_v2>(
    {}
)
export default class BookingQuizComp_v2 extends Vue {
    @Prop()
    quiz!: BookingQuiz_v2;

    @Prop({default: false})
    readonly!: boolean;
}
