
import {Component, Vue} from 'vue-property-decorator';
import Payment from "@/classes/bookings/Payment";
import {BookingsRepo} from "@/classes/repos/BookingsRepo";
import App from "@/App.vue";

@Component<RefundEditor>(
    {}
)
export default class RefundEditor extends Vue {
  private editor_visible = false;

  is_full_refund = true;

  refund_amount = 0;

  max_refundable_amount = 0;

  private payment: Payment | null = null;

  open(payment: Payment) {
    this.payment = payment;
    this.editor_visible = true;
    this.is_full_refund = true;
    this.max_refundable_amount = payment.amount;
    this.refund_amount = this.max_refundable_amount;
  }

  get form_valid() {
    return this.is_full_refund || (this.refund_amount > 0 && this.refund_amount <= this.max_refundable_amount);
  }

  async process_refund() {
    const {
      errors,
      success
    } = await BookingsRepo.refund_payment(this.payment!.id, this.refund_amount);

    if (success) {
      App.notify(this.refund_amount === this.max_refundable_amount ? 'Payment refunded' : 'Payment partially refunded');
      this.$emit('booking_changed');
    } else
      App.notify(errors!.single_err_msg, 'danger');
  }

  private refund_type_changed() {
    if (this.is_full_refund) this.refund_amount = this.max_refundable_amount;
  }
}
