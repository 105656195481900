
import {Component, Vue} from 'vue-property-decorator';
import User from "@/classes/users/User";
import {AuthRepo} from "@/classes/repos/AuthRepo";
import {BForm, BTab} from "bootstrap-vue";
import ValidationSummary from "@/components/common/ValidationSummary.vue";
import PasswordEditor from "@/components/common/PasswordEditor.vue";
import App from "@/App.vue";
import {UsersRepo} from "@/classes/repos/UsersRepo";
import {JwtRepo} from "@/classes/repos/JwtRepo";

@Component<UserFrontendEditor>(
    {
      components: {PasswordEditor, ValidationSummary}
    }
)
export default class UserFrontendEditor extends Vue {
  $refs!: {
    tab_info: BTab;
    form_info: BForm;
    pwd_editor: PasswordEditor;
  }

  private user: (User | null) = null;

  private pwd = '';

  private pwd_set_pressed = false;

  private info_validation_errors: any = null;
  private pwd_validation_errors: any = null;

  async created() {
    this.user = <User>await AuthRepo.load_frontend_user_data();
  }

  async save_info(event: Event) {
    if (!this.$refs.form_info.checkValidity()) {
      event.preventDefault();
      this.$refs.form_info.reportValidity();
      return;
    }

    const {success, errors} = await AuthRepo.save_frontend_user_data(this.user!);

    if (success) {
      App.notify('Changes saved successfully');
      const {user_data} = await UsersRepo.get_user_data();
      JwtRepo.first_name = user_data?.first_name ?? null;
      JwtRepo.last_name = user_data?.last_name ?? null;
    } else {
      this.info_validation_errors = errors;
    }

  }

  async save_pwd(event: Event) {
    this.pwd_set_pressed = true;

    await this.$nextTick();

    if (!this.$refs.pwd_editor.form_valid()) {
      event.preventDefault();
      return;
    }

    const {success, errors} = await AuthRepo.change_pwd(this.pwd);

    if (success) {
      App.notify('Password successfully changed');
      this.$refs.pwd_editor.clear();
      this.$refs.tab_info.activate();
      this.pwd_set_pressed = false;
    } else
      this.pwd_validation_errors = errors;
  }
}
