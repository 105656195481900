
import {Component, Prop, Vue} from 'vue-property-decorator';
import ReFabricImage from "@/components/re-fabric/re-fabric-image.vue";
import ReFabricGroup from "@/components/re-fabric/re-fabric-group.vue";
import ReFabricText from "@/components/re-fabric/re-fabric-text.vue";
import ReFabricRect from "@/components/re-fabric/re-fabric-rect.vue";
import Loc from "@/classes/common/Loc";
import ICanvasParent from "@/components/interfaces/ICanvasParent";
import BoothPopupEditor from "@/components/editors/BoothPopupEditor.vue";
import {mixins} from "vue-class-component";
import BoothCompBase from "@/components/floor-map/BoothCompBase";

@Component(
    {
      components: {
        BoothPopupEditor,
        ReFabricImage, ReFabricGroup, ReFabricText, ReFabricRect,
      },
    }
)
export default class BoothComp extends mixins(Vue, BoothCompBase) implements ICanvasParent {
  //Defined in BoothCompBase!
  $refs!: {
    // popup: BPopover,
    group: ReFabricGroup,
    text: ReFabricText,
  }

  @Prop()
  parent_loc?: Loc;

  @Prop({default: ''})
  parent_fill!: string;

  type = 'BoothComp';

  private get loc() {
    return this.parent_loc ? this.booth.loc.add(this.parent_loc.center_point) : this.booth.loc;
  }

  @Prop({default: false})
  is_group!: boolean;

  @Prop({default: true})
  show_indices!: boolean;

}
