
import {Component, Ref, Vue, Watch} from 'vue-property-decorator';
import DataProviderContext from "@/classes/common/DataProviderContext";
import {BTable} from "bootstrap-vue";
import {CompaniesRepo} from "@/classes/repos/CompaniesRepo";
import Show from "@/classes/floor-map/Show";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import ShowSelector from "@/components/generic/ShowSelector.vue";
import {library} from '@fortawesome/fontawesome-svg-core';
import {faFileCsv} from '@fortawesome/free-solid-svg-icons'
import Config from "@/classes/common/Config";
import Pluralize from "typescript-pluralize";
import {BookingsRepo} from "@/classes/repos/BookingsRepo";
import CompanySelector from "@/components/generic/CompanySelector.vue";
import XDate from "xdate";

library.add(faFileCsv);

@Component<CollectionsReport>(
    {
      components: {CompanySelector, ShowSelector}
    }
)
export default class CollectionsReport extends Vue {

  @Ref() table!: BTable;

  private show: Show | null = null;

  private busy = false;

  private total_rows = 0;

  private Pluralize = Pluralize;

  start_date: string | null = null;
  end_date: string | null = null;

  private predefined_date_ranges: {
    text: string,
    value: { start_date: string, end_date: string } | null,
    disabled?: boolean
  }[] = [];

  private selected_date_range: { start_date: string, end_date: string } | null = null;

  private created() {
    const today = XDate.today();
    const first_day_of_month = XDate.today().addDays(1 - today.getDate());
    const current_year = today.getFullYear();
    const first_day_of_year = new XDate(current_year, 0, 1);

    const predefined_date_ranges: { title: string, start_date: string, end_date: string }[] = [];

    predefined_date_ranges.push({
      title: 'Any Date',
      start_date: '2020-01-01',
      end_date: today.toString('yyyy-MM-dd')
    });

    predefined_date_ranges.push({
      title: 'Month to Date',
      start_date: first_day_of_month.toString('yyyy-MM-dd'),
      end_date: today.toString('yyyy-MM-dd')
    });

    [...Array(12).keys()].map(n => ({
      title: new XDate(first_day_of_month).addMonths(-n - 1).toString('MMMM yyyy'),
      start_date: new XDate(first_day_of_month).addMonths(-n - 1).toString('yyyy-MM-dd'),
      end_date: new XDate(first_day_of_month).addMonths(-n).addDays(-1).toString('yyyy-MM-dd'),
    })).forEach(x => predefined_date_ranges.push(x));

    predefined_date_ranges.push({
      title: 'Year to Date',
      start_date: first_day_of_year.toString('yyyy-MM-dd'),
      end_date: today.toString('yyyy-MM-dd')
    });

    predefined_date_ranges.push({
      title: (current_year - 1).toString(),
      start_date: new XDate(first_day_of_year).addYears(-1).toString('yyyy-MM-dd'),
      end_date: new XDate(first_day_of_year).addDays(-1).toString('yyyy-MM-dd')
    });

    this.predefined_date_ranges = predefined_date_ranges.map(x => ({
      text: x.title, value: {
        start_date: x.start_date,
        end_date: x.end_date
      }
    }));

    this.predefined_date_ranges.splice(0, 0, {text: 'Please select dates', value: null, disabled: true});
    this.selected_date_range = this.predefined_date_ranges[1].value;
    this.start_date = this.selected_date_range!.start_date;
    this.end_date = this.selected_date_range!.end_date;

    if (ShowsGenericRepo.current_show) this.show = ShowsGenericRepo.current_show;
  }

  @Watch('selected_date_range')
  private async selected_date_range_changed() {
    if (this.selected_date_range) {
      this.start_date = this.selected_date_range!.start_date;
      this.end_date = this.selected_date_range!.end_date;
    }

    await this.$nextTick();
    this.table.refresh();
  }

  @Watch('start_date')
  @Watch('end_date')
  private async start_date_changed() {
    const new_data_range = this.predefined_date_ranges.find(x => x.value && x.value.start_date === this.start_date && x.value.end_date === this.end_date);
    this.selected_date_range = new_data_range?.value ?? null;

    await this.$nextTick();
    this.table.refresh();
  }

  protected async data_provider(ctx: DataProviderContext) {
    if (!this.show || !this.start_date || !this.end_date) return [];

    this.busy = true;
    const items: any[] = (await BookingsRepo.collections_report(DataProviderContext.from({
      sortBy: 'payment_date',
      sortDesc: true
    }), this.show!.id, this.start_date, this.end_date, false)).items;
    this.total_rows = items.length;
    this.busy = false;

    return items;
  }

  @Watch('show')
  async refresh() {
    await this.$nextTick();
    this.table.refresh();
  }

  async get_csv() {
    if (!this.start_date || !this.end_date) return;

    const key: string = (await BookingsRepo.collections_report(DataProviderContext.from({
      sortBy: 'payment_date',
      sortDesc: true
    }), this.show!.id, this.start_date, this.end_date, true)).key;
    location.assign(`${Config.base_URL}/reports/get_csv?key=${key}`);
  }
}
