
import {Component, Ref, Vue, Watch} from 'vue-property-decorator';
import DataProviderContext from "@/classes/common/DataProviderContext";
import {BTable} from "bootstrap-vue";
import {CompaniesRepo} from "@/classes/repos/CompaniesRepo";
import Show from "@/classes/floor-map/Show";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import ShowSelector from "@/components/generic/ShowSelector.vue";
import {library} from '@fortawesome/fontawesome-svg-core';
import {faFileCsv} from '@fortawesome/free-solid-svg-icons'
import Config from "@/classes/common/Config";
import Pluralize from "typescript-pluralize";

library.add(faFileCsv);

@Component<OrdersReport>(
    {
      components: {ShowSelector}
    }
)
export default class OrdersReport extends Vue {

  @Ref() table!: BTable;

  private busy = false;

  private total_rows = 0;

  private Pluralize = Pluralize;

  private show: Show | null = null;

  protected async data_provider(ctx: DataProviderContext) {
    if (!this.show) return [];

    this.busy = true;
    const items: any[] = (await CompaniesRepo.orders_report(ctx, this.show.id)).items;
    this.total_rows = items.length;
    this.busy = false;

    return items;
  }

  created() {
    if (ShowsGenericRepo.current_show) this.show = ShowsGenericRepo.current_show;
  }

  @Watch('show')
  async refresh() {
    if (!this.show) return;

    await this.$nextTick();
    this.table.refresh();
  }

  async get_csv() {
    const key: string = (await CompaniesRepo.orders_report(DataProviderContext.from({sortBy: 'company_name'}), this.show!.id, true)).key;
    location.assign(`${Config.base_URL}/reports/get_csv?key=${key}`);
  }
}
