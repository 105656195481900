
import {Component, Vue} from 'vue-property-decorator';
import {AuthRepo} from "@/classes/repos/AuthRepo";
import Logo from "@/components/common/Logo.vue";
import ValidationSummary from "@/components/common/ValidationSummary.vue";

@Component<ForgotPassword>(
    {
      components: {ValidationSummary, Logo}
    }
)
export default class ForgotPassword extends Vue {
  private email = '';
  private errorMsg = '';
  private validation_errors: any = null;

  private async submit(ev: Event) {

    await this.$recaptchaLoaded();
    const recaptcha_token = await this.$recaptcha('reset_password');

    const {success, errors} = await AuthRepo.request_password_reset(this.email, recaptcha_token);
    if (success)
      await this.$router.push({
                                name  : 'confirmation',
                                params: {message: 'If this is a registered Email Address, a Password Reset link has been sent to it.'}
                              });
    else
      this.validation_errors = errors;
  }
}
