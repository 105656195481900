
import {Component, Ref, Vue, Watch} from 'vue-property-decorator';
import ReFabricCanvas from "@/components/re-fabric/re-fabric-canvas.vue";
import Palette from "@/components/floor-map/Palette.vue";
import {BoothType, ComplexBoothType} from "@/classes/floor-map/BoothType";
import {pix_per_foot} from "@/classes/common/Const";
import Booth, {ComplexBooth, MapObject, MapObjectTypes, PrintOnlyObject} from "@/classes/floor-map/Booth";
import {MapRepo} from "@/classes/repos/MapRepo";
import Loc from "@/classes/common/Loc";
import DebugComp from "@/components/common/DebugComp.vue";
import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faCopy,
  faHandRock,
  faObjectGroup,
  faObjectUngroup,
  faPaste,
  faSave,
  faTrashAlt
} from '@fortawesome/free-regular-svg-icons'
import {faBorderAll, faFileInvoiceDollar, faMousePointer} from '@fortawesome/free-solid-svg-icons'
import {BButton, BModal} from "bootstrap-vue";
import ReImageUploader from "@/components/common/re-image-uploader.vue";
import BoothCompBase from "@/components/floor-map/BoothCompBase";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import {BoothTypesGenericRepo} from "@/classes/repos/BoothTypesGenericRepo";
import {EventBus} from "@/classes/common/EventBus";
import {ReHelper} from "@/classes/common/ReHelper";
import ComplexBoothComp from "@/components/floor-map/ComplexBoothComp.vue";
import App from "@/App.vue";
import {default_user_settings, UsersRepo} from "@/classes/repos/UsersRepo";

library.add(faObjectUngroup, faObjectGroup, faSave, faHandRock, faMousePointer, faBorderAll, faTrashAlt, faFileInvoiceDollar, faCopy, faPaste)

@Component({
             components: {
               ReImageUploader,
               DebugComp,
               Palette,
               ReFabricCanvas,
             },
             filters   : {
               format_num: function (x: any) {
                 if (x === undefined) return '';
                 return parseFloat(x).toFixed(1).replace(/[.,]0$/, "");
               }
             }
           })
export default class MapComp extends Vue {
  $refs!: {
    canvas: ReFabricCanvas,
    obj_upload_dialog: BModal,
  }
  private canvas_key = 1;

  get selected_map_objects() {
    return MapRepo.selected_map_objects;
  }

  private get show() {
    return ShowsGenericRepo.current_show!;
  }

  private get map() {
    return MapRepo.current_map!;
  }

  private canvas_visible = false;

  private MapRepo = MapRepo;
  UsersRepo = UsersRepo;

  default_user_settings = default_user_settings;

  private new_booth_rw_width = this.map?.floor_plan?.rw_grid_step_x;
  private new_booth_rw_height = this.map?.floor_plan?.rw_grid_step_y;

  private drawing_booths = false;
  private placing_object = false;
  private object_image: { url: string, w: number, h: number } | null = null;

  private booth_number_options: { text: string; value: number }[] = [];

  mounted() {
    this.canvas_visible = true;

    EventBus.$on('remount-canvas', () => {
      this.remountCanvas();
    });
  }

  private get pix_per_foot(): number {
    return pix_per_foot;
  }

  get some_object_selected() {
    return !!this.selected_map_objects.length;
  }

  get some_inclined_object_selected() {
    return this.selected_map_objects.some(o => o.angle % 90 !== 0);
  }

  get some_locked_object_selected() {
    return this.selected_map_objects.some(o => o.locked);
  }

  get some_unlocked_object_selected() {
    return this.selected_map_objects.some(o => !o.locked);
  }

  get buttons(): any {
    return UsersRepo.user_settings!.editor_buttons;
  }

  remountCanvas() {
    // console.log('remountCanvas');
    MapRepo.listening_undoable_changes = false;
    this.canvas_key++;
    MapRepo.selected_map_objects.splice(0);
    MapRepo.selected_wrap_objects.splice(0);
    MapRepo.listening_undoable_changes = true;
  }

  private async group() {
    //make a booth image
    const selection_bounds = this.$refs.canvas.fjs_selection_rect;
    const rw_width = selection_bounds!.width / pix_per_foot;
    const rw_height = selection_bounds!.height / pix_per_foot;

    MapRepo.selected_booth_comps_including_complex.forEach(w => (w as BoothCompBase).toggle_indices(false));
    const png_data_url = this.$refs.canvas.get_png(selection_bounds!, true);
    MapRepo.selected_booth_comps_including_complex.forEach(w => (w as BoothCompBase).toggle_indices(true));

    //combining of ungrouping and regrouping not works so far
    /*let simple_booths = MapRepo.selected_map_objects.filter(o => o.type === MapObjectTypes.Booth);
    const complex_booth_wraps = MapRepo.selected_wrap_objects.filter(o => o.type === 'ComplexBoothComp') as ComplexBoothComp[];

    //Ungroup complex booths, if any
    for (const complex_booth_wrap of complex_booth_wraps) {
      await ReHelper.delay(1500);
      const _parts = await this.ungroup_object(complex_booth_wrap);
      // await this.$nextTick()
      simple_booths.push(..._parts);
    }

    // return
    await this.$nextTick()
    await ReHelper.delay(1500);*/

    //create Complex Booth Type
    let simple_booths = MapRepo.selected_map_objects.filter(o => o.type === MapObjectTypes.Booth);
    const fill = ReHelper.random_light_color_hex();
    const parts = simple_booths
        .filter((o: any): o is Booth => !!o)
        .map(o => o.toComplexBoothTypePart(selection_bounds!.center_point, fill));

    const letter_index = BoothTypesGenericRepo.get_free_letter_index();

    let complex_booth_type: ComplexBoothType = new ComplexBoothType(0, this.show.id, letter_index, '', '', rw_height, rw_width, 0, 0, ReHelper.random_light_color_hex(), true, parts, undefined, png_data_url);
    await BoothTypesGenericRepo.save(complex_booth_type);

    await BoothTypesGenericRepo.load_booth_types();

    complex_booth_type = BoothTypesGenericRepo.booth_types_by_letter[letter_index] as ComplexBoothType;

    let complex_booth = new ComplexBooth('', selection_bounds!, complex_booth_type!, 0);
    this.show.assign_numeric_index(complex_booth);

    simple_booths.forEach(o => this.map.remove(o));
    MapRepo.selected_map_objects = [];

    this.map.mapObjects.push(complex_booth);
    this.remountCanvas();
  }

  private async ungroup_selected() {
    const complex_booth_wrap = MapRepo.selected_wrap_objects[0] as ComplexBoothComp;
    await this.ungroup_object(complex_booth_wrap);

    MapRepo.selected_map_objects = [];
    MapRepo.selected_wrap_objects = [];

    this.remountCanvas();
  }

  private async ungroup_object(complex_booth_wrap: ComplexBoothComp): Promise<Booth[]> {
    complex_booth_wrap.do_update_from_fjs = false;
    complex_booth_wrap.booth.internal_booth_type = complex_booth_wrap.booth.booth_type.clone() as ComplexBoothType;
    complex_booth_wrap.booth.booth_type_id = undefined;
    await this.$nextTick();

    complex_booth_wrap.ungroup();
    const complex_booth = complex_booth_wrap.booth;
    const ungrouped_booths = complex_booth.booth_type.parts;
    this.map.remove(complex_booth);

    await this.$nextTick();

    ungrouped_booths.forEach(b => {
      // b.loc = b.loc.add(complex_booth.loc.center_point);
      BoothTypesGenericRepo.assign_letter_index(b.booth_type);
      this.show.assign_numeric_index(b);
      this.map.mapObjects.push(b);
    });

    return ungrouped_booths;

    /*return new Promise<Booth[]>((resolve) => {
      setTimeout(() => {
        resolve(ungrouped_booths)
      }, 1000)
    });*/
  }

  private delete_booths() {
    MapRepo.selected_map_objects.filter(o => !o.locked).forEach(o => this.map.remove(o));
    this.remountCanvas();
  }

  private align_tops() {
    const top = MapRepo.selected_map_objects.reduce((x, o) => Math.min(x, o.loc.top!), 1000000);
    MapRepo.selected_map_objects.forEach(o => o.loc.top = top);
    this.remountCanvas();
  }

  private align_bottoms() {
    const bottom = MapRepo.selected_map_objects.reduce((x, o) => Math.max(x, o.loc.top! + o.loc.height), 0);
    MapRepo.selected_map_objects.forEach(o => o.loc.top = bottom - o.loc.height);
    this.remountCanvas();
  }

  private align_lefts() {
    const left = MapRepo.selected_map_objects.reduce((x, o) => Math.min(x, o.loc.left!), 1000000);
    MapRepo.selected_map_objects.forEach(o => o.loc.left = left);
    this.remountCanvas();
  }

  private align_rights() {
    const right = MapRepo.selected_map_objects.reduce((x, o) => Math.max(x, o.loc.left! + o.loc.width), 0);
    MapRepo.selected_map_objects.forEach(o => o.loc.left = right - o.loc.width);
    this.remountCanvas();
  }

  private stack_horizontally() {
    let sorted = this.selected_map_objects.sort((a, b) => a.loc.left! < b.loc.left! ? -1 : a.loc.left! > b.loc.left! ? 1 : 0);
    for (let n = 1; n < sorted.length; n++) {
      sorted[n].loc.left = sorted[n - 1].loc.left! + sorted[n - 1].loc.width;
    }
    this.remountCanvas();
  }

  private stack_vertically() {
    let sorted = this.selected_map_objects.sort((a, b) => a.loc.top! < b.loc.top! ? -1 : a.loc.top! > b.loc.top! ? 1 : 0);
    for (let n = 1; n < sorted.length; n++) {
      sorted[n].loc.top = sorted[n - 1].loc.top! + sorted[n - 1].loc.height;
    }
    this.remountCanvas();
  }

  private toggle_booth_drawing() {
    this.drawing_booths = !this.drawing_booths;
  }

  private add_map_object(loc: Loc) {
    let map_object: MapObject | undefined = undefined;

    if (this.drawing_booths) {
      let boothType = BoothTypesGenericRepo.all_booth_types.find(t => t.sizes_equal({
                                                                                      rw_width : this.new_booth_rw_width!,
                                                                                      rw_height: this.new_booth_rw_height!
                                                                                    }));
      boothType ??= new BoothType(0, this.show.id, '', '', '', this.new_booth_rw_height!, this.new_booth_rw_width!, 1, 500, 1, ReHelper.random_light_color_hex(), true);
      BoothTypesGenericRepo.assign_letter_index(boothType);
      const booth = new Booth('', loc, undefined, boothType);
      this.show.assign_numeric_index(booth);
      map_object = booth;
    } else if (this.placing_object && this.object_image) {
      map_object = new PrintOnlyObject(this.object_image.url, loc.set_size(this.object_image.w, this.object_image.h));
      this.placing_object = false;
    }

    if (map_object) {
      this.map.mapObjects.push(map_object);
      this.$refs.canvas.render_all();
    }
  }

  private lock_objects() {
    const do_lock = this.some_unlocked_object_selected;

    this.selected_map_objects.forEach(o => o.locked = do_lock);
    this.$refs.canvas.render_all();
  }

  private copy() {
    //clone selected objects
    let clones = [];
    for (const o of MapRepo.selected_map_objects) {
      const map_object = o.clone();
      clones.push(map_object);
      if (map_object instanceof Booth) {
        map_object.make_booth_type_internal();
      }
    }

    localStorage.setItem('clipboard_booths', JSON.stringify(clones));

    if (clones.length) App.notify('Booth(s) copied')
  }

  private async paste() {
    const json = localStorage.getItem('clipboard_booths');
    if (!json) return;

    try {
      const clones = JSON.parse(json).map((o: object) => MapObject.from(o));

      MapRepo.current_map?.mapObjects.push(...clones);

      for (const o of clones) {
        if (o instanceof Booth) {
          BoothTypesGenericRepo.assign_letter_index(o.booth_type);
          this.show.assign_numeric_index(o);
        }
      }

      this.remountCanvas();

      await this.$nextTick();

      setTimeout(() => this.$refs.canvas.select_map_objects(clones), 100);


    } catch (e) {
    }

  }

  async toggle_fp_image_editing() {
    MapRepo.allow_fp_image_editing = !MapRepo.allow_fp_image_editing;
    await this.$nextTick();
    if (MapRepo.allow_fp_image_editing) {
      //disabled selection since one anyway needs to manually select the image to fire 'modified' event
      // this.$refs.canvas.select_fp_image();
    } else this.$refs.canvas.deselect_all();
  }

  async undo() {
    if (!MapRepo.undo_available) return;
    MapRepo.processing_undo = true;

    const map_objects_json = MapRepo.undo_buffer[MapRepo.undo_buffer.length - 2 - MapRepo.undo_buffer_pos].json;
    const map_objects = JSON.parse(map_objects_json).map((x: any) => MapObject.from(x));
    MapRepo.listening_undoable_changes = false;
    MapRepo.current_map!.mapObjects = map_objects;
    await this.$nextTick();
    MapRepo.listening_undoable_changes = true;
    MapRepo.undo_buffer_pos = Math.min(MapRepo.undo_buffer_pos + 1, MapRepo.undo_buffer.length - 1);


    await this.$nextTick()
    MapRepo.processing_undo = false;

    await this.$nextTick();
    this.remountCanvas();
  }

  async redo() {
    if (!MapRepo.redo_available) return;

    const map_objects_json = MapRepo.undo_buffer[MapRepo.undo_buffer.length - MapRepo.undo_buffer_pos].json;
    const map_objects = JSON.parse(map_objects_json).map((x: any) => MapObject.from(x));
    MapRepo.listening_undoable_changes = false;
    MapRepo.current_map!.mapObjects = map_objects;
    await this.$nextTick();
    MapRepo.listening_undoable_changes = true;
    MapRepo.undo_buffer_pos = Math.max(MapRepo.undo_buffer_pos - 1, 0);

    await this.$nextTick();
    this.remountCanvas();
  }

  async toggle_button(btn_name: string) {
    await UsersRepo.change_user_setting('editor_buttons', btn_name, String(this.buttons[btn_name]));
  }

  @Watch('MapRepo.selected_map_objects')
  private selected_map_objects_changed() {
    const booth = this.selected_map_objects[0]?.booth_or_complex_booth;
    if (!booth) {
      this.booth_number_options = [];
      return;
    }

    const free_indices = this.show.available_indices(booth.booth_type.letter_index);

    this.booth_number_options = [booth.numeric_index, ...free_indices]
        .sort((a, b) => a - b)
        .map(o => ({value: o, text: `${booth.booth_type.letter_index}${o}`}));
  }

  @Ref() btn_get_png!: BButton;

  private get_png() {
    const grid_on = this.map.grid_on;
    const canvas = this.$refs.canvas;
    if (grid_on)
      canvas.toggle_grid(false);
    const png_data_url = canvas.get_png(new Loc(0, 0, this.map.floor_plan?.loc.width!, this.map.floor_plan?.loc.height!), false, 1);
    this.btn_get_png.href = png_data_url;
    // document.getElementById('btn_export_png')!.setAttribute('href', png_data_url);
    if (grid_on)
      canvas.toggle_grid(true);
  }

}
