
import {Component, Prop, Vue} from 'vue-property-decorator';
import Booth from "@/classes/floor-map/Booth";
import {BookingsRepo} from "@/classes/repos/BookingsRepo";
import App from "@/App.vue";
import {JwtRepo} from "@/classes/repos/JwtRepo";

@Component<BoothInfo>(
    {}
)
export default class BoothInfo extends Vue {
    @Prop()
    booth!: Booth;

    JwtRepo = JwtRepo;
    BookingsRepo = BookingsRepo;

    private get vendors_occupying() {
        return BookingsRepo.occupancy[this.booth.booth_number];
    }

    private get is_occupied(): boolean {
        return !!this.vendors_occupying && !!this.vendors_occupying.length;
    }

    private get occupied_by_another_vendor(): boolean {
        return this.is_occupied && this.vendors_occupying[0].id !== JwtRepo.vendor_id;
    }

    private get is_in_cart(): boolean {
        return BookingsRepo.current_booking?.items.some(i => i.booth_number == this.booth.booth_number) ?? false;
    }

    private async add_to_cart() {
        const {errors, booking_disabled} = await BookingsRepo.add_to_cart(this.booth.booth_number);

        if (errors) App.notify(errors.single_err_msg, 'danger');

        if (booking_disabled)
            await BookingsRepo.get_overdue_booking_ids();
    }
}
