import {LikeableEntityGenericRepo} from "@/classes/repos/LikeableEntityGenericRepo";
import Show from "@/classes/floor-map/Show";
import Vue from "vue";
import {MapRepo} from "@/classes/repos/MapRepo";
import axios from "axios";
import ErrorForDisplay from "@/classes/common/ErrorForDisplay";
import FloorMap from "@/classes/floor-map/FloorMap";
import {EventBus} from "@/classes/common/EventBus";
import {IBoothInfo} from "@/classes/floor-map/IBoothInfo";
import MultiTableDiscount from "@/classes/floor-map/MultiTableDiscount";
import {BoothTypesGenericRepo} from "@/classes/repos/BoothTypesGenericRepo";
import router from "@/router";
import {processing_refresh_auth_logic} from "@/classes/repos/AuthRepo";
import {JwtRepo} from "@/classes/repos/JwtRepo";
import {AppInstance} from "@/classes/repos/AppRepo";

class _ShowsGenericRepo extends LikeableEntityGenericRepo<{
    location?: string,
    start_date?: string,
    end_date?: string,
    limit_by_ids?: number[],
    exclude_ids?: number[]
}> {
    protected entities_cache: Show[] = [];
    current_show: Show | null = null;
    orig_show_serialized: string | null = null;

    get shows(): Show[] {
        return this.entities_cache;
    }

    get current_show_id_with_route_fallback(): number {
        return this.current_show?.id ?? Number(router.currentRoute.params.show_id || 0);
    }

    constructor() {
        super(Show, 'Show');
    }

    async load_by_slug(id?: number | string, slug?: string, fp_slug?: string, highlighted_booths?: string, force_reload?: boolean): Promise<{
        success: boolean;
        show?: Show;
        errors?: ErrorForDisplay
    }> {
        if (id) id = Number(id);
        let show = this.current_show;
        slug ??= show?.slug;
        const need_reload = force_reload || !show || (slug && slug !== show.slug) || (id && id !== show.id) || !show.maps_loaded() || highlighted_booths;

        /*if (!force_reload && slug === this.current_show?.slug
            // && (!fp_slug || fp_slug === MapRepo.current_map?.floor_plan?.slug)
            && this.current_show?.maps_loaded() && !highlighted_booths) {
            return {success: true, show: this.current_show!};
        }*/

        try {
            if (need_reload) {
                const {data} = await (processing_refresh_auth_logic ? axios.create({headers: {Authorization: JwtRepo.auth_header.Authorization}}) : axios).post(`/${this.entity}/load_by_slug`, {}, {
                    params: {
                        slug,
                        id
                    }
                });
                show = Show.from(data);
                await this.load_details_and_select(show, data.json);
                this.orig_show_serialized = JSON.stringify(show);
            }

            fp_slug ??= MapRepo.current_map?.floor_plan?.slug;
            if (fp_slug) {
                MapRepo.current_map = show!.maps?.find(m => (m.floor_plan?.slug === fp_slug) || (m.id.toString() === fp_slug)) ?? null;
                if (highlighted_booths)
                    MapRepo.highlighted_booths = Object.fromEntries(highlighted_booths.split(',').map(x => ([x, x])));
            } else {
                MapRepo.current_map = show!.maps?.[0];
            }
            return {success: true, show: show!};
        } catch (ex) {
            // console.error(ex)
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async get_slug_by_id(id: number): Promise<string> {
        const {data: {slug}} = await axios.post(`/${this.entity}/get_slug_by_id`, {}, {params: {id}});
        return slug;
    }

    async load_details_and_select(show: Show, json?: string) {
        ShowsGenericRepo.select_show(show);
        await BoothTypesGenericRepo.load_booth_types();
        if (json) {
            show.maps = JSON.parse(json).maps?.map((m: any) => FloorMap.from(m)) ?? [];
        }
        MapRepo.current_map = show.maps?.[0];
    }

    select_show(sh: Show | null) {
        // if (this.current_show?.id === sh.id) return;
        this.current_show = sh;
        // await sh.load_details_and_select();
        MapRepo.current_map = sh?.maps?.length ? sh.maps[0] : null;
        EventBus.$emit('current_show_changed');
    }

    add_map(map: FloorMap, set_as_current = false) {
        if (!this.current_show) return;
        const existing_ids = this.current_show.maps?.map(m => m.id) ?? [0];
        const new_id = Math.max(0, ...existing_ids) + 1;
        map.id = new_id;
        this.current_show?.maps?.push(map);
        if (set_as_current)
            MapRepo.current_map = map;
    }

    async save(e: Show): Promise<{ success: boolean; errors?: ErrorForDisplay }> {
        const res = await super.save(e);
        if (res.success)
            this.orig_show_serialized = JSON.stringify(e);
        return res;
    }

    async save_editing_history(show_id: number, editing_history: { json: string, ts: string }[]) {
        try {
            if ((editing_history?.length ?? 0) <= 1) return;
            await axios.post(`/show/save_editing_history`, {show_id, editing_history});
            return {success: true};
        } catch (ex) {
            console.log(ex)
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    get current_show_serialized() {
        return JSON.stringify(this.current_show);
    }

    get current_show_has_changes(): boolean {
        return this.orig_show_serialized !== this.current_show_serialized;
    }

    async list_floor_plans(show_id?: number): Promise<{
        success: boolean;
        floor_plans?: { id: number, name: string } [];
        errors?: ErrorForDisplay
    }> {
        show_id ??= this.current_show?.id;
        if (!show_id) return {success: true, floor_plans: []};

        try {
            const {data} = await axios.get(`/${this.entity}/list_floor_plans`, {params: {show_id}});

            return {success: true, floor_plans: data.floor_plans};

        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async list_booths_with_booking_info(show_id: number, fp_id: number, sortBy: string, sortDesc: boolean): Promise<{
        success: boolean;
        booths?: IBoothInfo[];
        errors?: ErrorForDisplay
    }> {
        if (!show_id) return {success: true, booths: []};

        try {
            const {data} = await axios.get(`/${this.entity}/list_booths_with_booking_info`, {
                params: {
                    show_id,
                    fp_id,
                    sortBy,
                    sortDesc
                }
            });

            return {success: true, booths: data.list};

        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    remove_map(n_deleting_map: number) {
        const current_show = ShowsGenericRepo.current_show;
        if (!current_show) return;

        current_show.maps?.splice(n_deleting_map, 1);

        //set new active map
        if (MapRepo.current_map === current_show.maps![n_deleting_map])
            MapRepo.current_map = current_show.maps?.length ? current_show.maps[0] : null;

        AppInstance.notify('Floor Plan removed');
    }

    async save_multi_table_discounts(show_id: number, discounts: MultiTableDiscount[]): Promise<{
        success: boolean;
        errors?: ErrorForDisplay
    }> {
        try {
            const {data} = await axios.post(`/${this.entity}/save_multi_table_discounts`, discounts, {params: {show_id}});

            return {success: true};

        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async reload_current_show_if_needed() {
        if (this.current_show && !this.current_show.loaded_by_admin) {
            const {errors} = await this.load_by_slug(this.current_show.id, undefined, router.currentRoute?.params?.fp_slug, undefined, true);

            if (errors)
                AppInstance.notify(errors.single_err_msg, 'danger');
        }
    }

    remove_booth_note(booth_note_id: number) {
        const n = this.current_show?.show_notes?.findIndex(n => n.note_id == booth_note_id);
        if (n !== undefined && n > -1)
            this.current_show!.show_notes.splice(n, 1);
    }
}

export const ShowsGenericRepo = Vue.observable(new _ShowsGenericRepo());