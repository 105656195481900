
import {Component, Prop, Vue} from 'vue-property-decorator';
import {tree_level, tree_node, tree_nodes} from "@/components/Reservations/VendorSidebar.vue";

@Component<TreeAccordion>(
    {
      components: {TreeAccordion}
    }
)
export default class TreeAccordion extends Vue {
  @Prop({default: 'accordion'})
  accordion_name!: string;

  @Prop()
  tree_levels!: tree_level[];

  @Prop({default: 0})
  current_level!: number;

  @Prop()
  items!: tree_nodes;

  @Prop({default: ''})
  filter!: string;

  private get_children(node: tree_node): tree_nodes | undefined {
    return (this.tree_levels[this.current_level].get_children?.(node) ?? node.children)//.sort((a: tree_node, b: tree_node) => a.name.localeCompare(b.name));
  }

  private sort(o_nodes: tree_nodes) {
    const sort_children: (a: tree_node, b: tree_node) => number = this.tree_levels[this.current_level].sort_children ??
        ((a: tree_node, b: tree_node) => a.name.localeCompare(b.name));

    let nodes = Object.values(o_nodes);

    if (this.filter) {
      const search_string = this.filter.toLowerCase();
      nodes = nodes.filter(x =>
          x.name.toLowerCase().includes(search_string)
          || Object.values(x.children ?? {})?.some(x => x.name.toLowerCase().includes(search_string))
          || Object.values(x.grand_children ?? {})?.some(x => x.name.toLowerCase().includes(search_string))
      );
    }

    return nodes.sort(sort_children);
  }
}
