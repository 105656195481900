
import {Component} from 'vue-property-decorator';
import EntityTable, {field_definition} from "@/components/generic/EntityTable.vue";
import {SellableServicesRepo} from "@/classes/repos/SellableServicesRepo";
import Sellable_Service from "@/classes/floor-map/Sellable_Service";
import SellableServiceEditor from "@/components/editors/SellableServiceEditor.vue";
import ShowsList from "@/components/floor-map/ShowsList.vue";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";

@Component<Sellable_Services_Table>(
    {
      components: {SellableServiceEditor, ShowsList}
    }
)
export default class Sellable_Services_Table extends EntityTable {
  /*  @Prop({default: {}})
    filter_request: any;*/

  protected get default_fields(): (field_definition | undefined)[] {
    return [
      {key: 'id', label: 'Id', sortable: true,},
      {key: 'name', label: 'Service', sortable: true,},
      {key: 'details', label: 'Details', sortable: false,},
      {key: 'per_booth_limit', label: 'Per-Booth Limit', sortable: true,},
      // {key: 'total_inventory', label: 'Total Inventory', sortable: true,},
      {key: 'price', label: 'Price', sortable: true, formatter: 'format_money'},
      {key: 'is_universal', sortable: true, formatter: (v: boolean) => v ? 'yes' : 'no'},
      {key: 'service_mappings', label: 'Shows', sortable: false, component: 'ShowsList'},
      {key: 'edit', label: 'Edit', sortable: false,},
      {key: 'clone', label: 'Clone', sortable: false,},
      {key: 'delete', label: 'Delete', sortable: false,},
    ];
  }

  repo = SellableServicesRepo;
  TEntity = Sellable_Service;

  protected readonly editor_comp = 'SellableServiceEditor';

  get search_request(): any {
    return {show_id: ShowsGenericRepo.current_show?.id};
  }

}
