
import {Component, Prop} from 'vue-property-decorator';
import EntityTable, {field_definition} from "@/components/generic/EntityTable.vue";
import {UsersRepo} from "@/classes/repos/UsersRepo";
import User from "@/classes/users/User";
import UserGenericEditor from "@/components/editors/UserGenericEditor.vue";
import VendorMappings from "@/components/vendor/VendorMappings.vue";

@Component<UsersTable>(
    {
      components: {
        UserGenericEditor: UserGenericEditor, VendorMappings: VendorMappings
      }
    }
)
export default class UsersTable extends EntityTable {
  repo = UsersRepo;
  TEntity = User;

  protected get default_fields(): (field_definition | undefined)[] {
    return [
      {key: 'email', sortable: true},
      {key: 'first_name', sortable: true},
      {key: 'last_name', sortable: true},
      // {key: 'roles', sortable: false, formatter: (r: any[]) => r.map(x => x.role_id).join(', ')},
      {key: 'roles_list', label: 'Roles', sortable: true},
      {key: 'vendor_name', label: 'Company', sortable: true, component: 'VendorMappings'},
      {key: 'phone', sortable: true},
      {key: 'created_at', label: 'Created', sortable: true, formatter: 'format_date'},
      {key: 'last_logged_in', sortable: true, formatter: 'format_datetime'},

      {key: 'edit', label: 'Edit', sortable: false,},
      {key: 'delete', label: 'Delete', sortable: false,},
    ];
  }

  editor_comp = 'UserGenericEditor';

  @Prop({default: {}})
  filter_request: any;

  get search_request(): any {
    return this.filter_request;
  }

  /*  actions: { key: string; text: string; to: (b: LikeableEntity) => RawLocation }[] = [
      {
        key : 'edit_company',
        text: 'Edit',
        to  : c => ({
          name  : 'admin_vendor_profile',
          params: {vendor_id: c.id.toString()}
        })
      }
    ]*/
}
