
import {Component, Prop, Ref, Vue} from 'vue-property-decorator';
import VendorProfileComp from "@/components/vendor/VendorProfileComp.vue";
import {CompaniesRepo} from "@/classes/repos/CompaniesRepo";
import Company from "@/classes/companies/Company";

@Component<AdminVendorProfile>(
    {
      components: {VendorProfileComp}
    }
)
export default class AdminVendorProfile extends Vue {
  @Ref() profile_editor!: VendorProfileComp;

  @Prop()
  vendor_id!: string;

  private company: Company | null = null;

  async beforeMount() {
    let vendor_id = 0;

    if (this.vendor_id === 'new') {
      const {new_id, entity} = await CompaniesRepo.create_empty();
      vendor_id = new_id!;
    } else vendor_id = Number(this.vendor_id);

    this.company = (await CompaniesRepo.details(vendor_id)).entity as Company;
    this.company.init_contacts();
  }
}
