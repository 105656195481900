
import {Component, Prop, Vue} from 'vue-property-decorator';
import WaitlistItem from "@/classes/floor-map/WaitlistItem";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";

@Component<WaitListItemSortCell>(
    {}
)
export default class WaitListItemSortCell extends Vue {
  @Prop()
  data!: { item: WaitlistItem, index: number };

  @Prop()
  perPage: number;

  @Prop()
  currentPage: number;

  @Prop()
  totalRows: number;

  @Prop()
  sort_by: string;

  private get buttons_disabled() {
    return !ShowsGenericRepo.current_show || this.sort_by !== 'sort_order';
  }

  get index() {
    return (this.currentPage - 1) * this.perPage + this.data.index;
  }
}
