
import {Component, Prop, Vue} from 'vue-property-decorator';
import ShoppingCart from "@/components/Reservations/ShoppingCart.vue";
import {ReHelper} from "@/classes/common/ReHelper";
import {BookingsRepo} from "@/classes/repos/BookingsRepo";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import ReSpinner from "@/components/common/re-spinner.vue";
import {AppRepo} from "@/classes/repos/AppRepo";
import ShoppingCartFooter from "@/components/Reservations/ShoppingCartFooter.vue";
import {BookingQuiz_v2} from "@/classes/bookings/quiz/BookingQuiz";
import BookingQuizComp_v2 from "@/components/Reservations/BookingQuizComp_v2.vue";

@Component<SidebarCheckout>(
    {
        components: {BookingQuizComp_v2, ShoppingCartFooter, ReSpinner, ShoppingCart}
    }
)
export default class SidebarCheckout extends Vue {

    @Prop({default: false})
    readonly!: boolean;

    @Prop({default: false})
    quiz_only!: boolean;

    private accordion: string | null = ReHelper.narrow_screen ? 'acc-shopping-cart' : null;

    private shopping_cart_loaded = false;

    private tos = '';

    private get quiz(): BookingQuiz_v2 {
        return BookingsRepo.current_booking!.quiz! as any;
    }

    get show_id() {
        return ShowsGenericRepo.current_show?.id ?? Number(this.$route.params.show_id)
    }

    private async created() {
        this.tos = await AppRepo.get_setting('tos');
    }

    private async mounted() {
        await BookingsRepo.get_shopping_cart(this.show_id);
        this.shopping_cart_loaded = true;
    }
}
