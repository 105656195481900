
import {Component, Prop, Ref, Vue} from 'vue-property-decorator';
import ShoppingCart from "@/components/Reservations/ShoppingCart.vue";
import PayPalButtons from "@/components/Reservations/PayPalButtons.vue";
import ShoppingCartFooter from "@/components/Reservations/ShoppingCartFooter.vue";
import {BookingsRepo} from "@/classes/repos/BookingsRepo";
import CollapsibleCard from "@/components/common/CollapsibleCard.vue";
import {BForm} from "bootstrap-vue";
import {AuthRepo} from "@/classes/repos/AuthRepo";
import {AppRepo} from "@/classes/repos/AppRepo";
import SidebarCheckout from "@/components/Reservations/SidebarCheckout.vue";

@Component<CheckoutPayment>(
    {
        components: {SidebarCheckout, CollapsibleCard, ShoppingCartFooter, PayPalButtons, ShoppingCart}
    }
)
export default class CheckoutPayment extends Vue {
    private booking_id = '';

    @Prop()
    show_id: number | null;

    private user_account: { first_name?: string, last_name?: string, email?: string } = {};
    private user_account_created = false;
    private create_account_errors = '';

    private tos = '';
    private tos_accepted = true;

    @Ref()
    create_account_form!: BForm;

    async created() {
        this.tos = await AppRepo.get_setting('tos');
    }

    mounted() {
        if (!this.show_id) return;

        if (!BookingsRepo.current_booking)
            BookingsRepo.get_shopping_cart(this.show_id);

        this.booking_id = BookingsRepo.current_booking?.id.toString() ?? '';
    }

    private async create_account() {
        this.create_account_errors = '';
        if (!this.create_account_form.checkValidity()) {
            this.create_account_form.reportValidity();
            return;
        }

        await this.$recaptchaLoaded();
        const recaptcha_token = await this.$recaptcha('register_user');

        const {
            success,
            errors
        } = await AuthRepo.register_user(this.user_account.first_name!, this.user_account.last_name!, this.user_account.email!, recaptcha_token);

        this.user_account_created = success;
        this.create_account_errors = errors?.single_err_msg ?? '';
    }
}
