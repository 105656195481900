
import {Component, Prop} from 'vue-property-decorator';
import EntityTable, {field_definition} from "@/components/generic/EntityTable.vue";
import {BookingHistoryRepo} from "@/classes/repos/BookingHistoryRepo";
import BookingHistory from "@/classes/history/BookingHistory";
import BookingHistoryViewer from "@/components/editors/BookingHistoryViewer.vue";

@Component<BookingHistoryTable>(
    {
      components: {BookingHistoryViewer}
    }
)
export default class BookingHistoryTable extends EntityTable {
  /*  @Prop({default: {}})
    filter_request: any;*/

  @Prop()
  booking_id!: number;

  protected get default_fields(): (field_definition | undefined)[] {
    return [
      {key: 'short_description', label: 'Action', sortable: true,},
      {key: 'changed_by', label: 'User', sortable: true,},
      {key: 'role', label: 'User Role', sortable: true,},
      {key: 'time', sortable: true, formatter: 'format_datetime'},
      {key: 'new_value', label: 'Value', sortable: false,},
      {key: 'edit', label: '', sortable: false,},
    ];
  }

  repo = BookingHistoryRepo;
  TEntity = BookingHistory;
  editor_comp = 'BookingHistoryViewer';

  sort_desc = true;
  edit_link_text = 'Details';

  get search_request(): any {
    return {booking_id: this.booking_id};
  }

  protected show_edit_cmd(e: BookingHistory) {
    return e.has_data;
  }

}
