
import {Component, Ref, Vue} from 'vue-property-decorator';
import {LikeableEntity, LikeableEntityGenericRepo, TLikeableEntity} from "@/classes/repos/LikeableEntityGenericRepo";
import {BForm, BModal} from "bootstrap-vue";
import App from "@/App.vue";
import {startCase} from "lodash-es";

@Component<EntityEditor>(
    {}
)
export default class EntityEditor extends Vue {
  protected entity: (LikeableEntity | null) = null;
  protected orig_entity!: LikeableEntity;

  protected readonly TEntity!: TLikeableEntity;
  protected repo?: LikeableEntityGenericRepo<any>;

  protected validity_check_requested = false;

  @Ref() protected readonly modal!: BModal;
  @Ref() protected readonly form!: BForm;

  private resolvePromise?: (value: (PromiseLike<boolean> | boolean)) => void;

  protected get entity_single_name(): string {
    return this.TEntity.name.toLowerCase().replace(/\_/g, ' ');
  }

  async open(entity: LikeableEntity): Promise<boolean> {
    this.orig_entity = entity;
    this.entity = entity.clone();
    await this.load_additional();
    await this.$nextTick();

    return new Promise((resolve, reject) => {
      this.modal.show();

      this.resolvePromise = resolve;
    });
  }

  protected async load_additional() {
  }

  protected close() {
    this.resolvePromise?.(false)
  }

  protected success() {
    this.modal.hide();
    this.resolvePromise?.(true);
  }

  protected check_validity_html(): boolean {
    if (!!this.form && !this.form.checkValidity()) {
      this.form.reportValidity();
      return false;
    } else return true;
  }

  protected check_validity_additional(): boolean {
    return true;
  }

  protected setup_entity() {
    throw 'setup_entity method should be overridden!'
  }

  protected async after_save() {
  }

  protected async get_additional_save_params(): Promise<any> {
    return {};
  }

  protected async save(bvModalEvt: Event) {
    if (!this.check_validity_html() || !this.check_validity_additional()) {
      bvModalEvt.preventDefault();
      return;
    }

    this.setup_entity();
    const {success, errors} = await this.repo!.save(this.orig_entity, await this.get_additional_save_params());
    this.resolvePromise?.(true);

    if (!errors) {
      await this.after_save();
      App.notify(startCase(`${this.entity_single_name} saved`));
    } else {
      App.notify(errors.single_err_msg, 'danger');
    }
  }
}
