
import {Component, Vue} from 'vue-property-decorator';
import {BookingsRepo} from "@/classes/repos/BookingsRepo";
import LayoutPage from "@/components/pages/Map/LayoutPage.vue";

@Component
export default class OverdueBookingsWarning extends Vue {
    BookingsRepo = BookingsRepo;
    show_alert_overdue_bookings = false;
}
