
import {Component, Prop} from 'vue-property-decorator';
import EntitySelector from "@/components/generic/EntitySelector.vue";
import {search_field_description} from "@/components/generic/EntitySearchForm.vue";
import {SellableServicesRepo} from "@/classes/repos/SellableServicesRepo";
import {field_definition} from "@/components/generic/EntityTable.vue";
import Show from "@/classes/floor-map/Show";

@Component<SellableServiceSelector>(
    {components: {}}
)
export default class SellableServiceSelector extends EntitySelector {
  @Prop()
  show!: Show;

  repo = SellableServicesRepo;

  protected entity_plural_name = 'services';

  protected search_fields: (string | search_field_description)[] = ['filter'];

  search_results_fields: field_definition[] = ['name', {key: 'details', }];

  protected get search_request_additional_params(): any {
    return {show_id: this.show.id}
  }
}
