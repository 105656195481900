import {LikeableEntityGenericRepo} from "@/classes/repos/LikeableEntityGenericRepo";
import Vue from "vue";
import Sellable_Service from "@/classes/floor-map/Sellable_Service";

class _SellableServicesRepo extends LikeableEntityGenericRepo<{ limit_by_ids?: number[], exclude_ids?: number[] }> {
    constructor() {
        super(Sellable_Service, 'Sellable_Service');
    }
}

export const SellableServicesRepo = Vue.observable(new _SellableServicesRepo);