var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"px-3 text-left",attrs:{"id":"vendors-report"}},[_c('h3',[_vm._v("Booked Vendor Report")]),_c('show-selector',{staticClass:"mt-4",staticStyle:{"max-width":"400px"},attrs:{"single_selection":true,"use_dropdown_form":true,"auto_close_dropdown":true,"dropdown_title":"Choose show"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}}),(_vm.show)?[_c('b-button',{staticClass:"float-right mb-4",attrs:{"variant":"primary"},on:{"click":_vm.get_csv}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'fa-file-csv']}}),_vm._v(" Export CSV ")],1),_c('div',{staticClass:"clearfix"}),_c('em',{staticClass:"d-block mb-2"},[_vm._v(" "+_vm._s(_vm.total_rows)+" "+_vm._s(_vm.Pluralize.plural('item'))+" ")]),_c('b-table',{ref:"table",attrs:{"items":_vm.data_provider,"fields":[
            'company_name',
            'contact_name',
            'contact_phone',
            'contact_address',
            'contact_email',
            'bookings_count',
            'booths',
            {key:'discount', formatter:a=>_vm.$repo.ReHelper.format_money(a)},
            {key:'total', formatter:a=>_vm.$repo.ReHelper.format_money(a)},
          ],"busy":_vm.busy,"show-empty":""}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }