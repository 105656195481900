
import {Component, Vue} from 'vue-property-decorator';

@Component<VendorProfileMenu>(
    {}
)
export default class VendorProfileMenu extends Vue {
  goto(hash: string) {
    location.hash = hash;
  }
}
