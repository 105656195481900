import XDate from "xdate";
import Refund from "@/classes/bookings/Refund";

export default class Payment {
    id: number;
    date: string;
    amount: number;
    gateway: string;
    paypal_account?: string;
    tran_number: string;
    refunded_amount: number;
    refund_available: boolean;
    notes: string;
    refunds: Refund[] = [];

    constructor(id: number, date: string, amount: number, gateway: string, tran_number: string, refunded_amount: number, notes: string, refund_available: boolean, paypal_account?: string, refunds?: Refund[]) {
        this.id = id;
        this.date = date;
        this.amount = amount;
        this.gateway = gateway;
        this.tran_number = tran_number;
        this.refunded_amount = refunded_amount;
        this.refund_available = refund_available;
        this.notes = notes;
        this.paypal_account = paypal_account;
        this.refunds = refunds ?? [];
    }

    static from(o: any) {
        return new Payment(
            o.id,
            o.date,
            o.amount,
            o.gateway,
            o.tran_number,
            o.refunded_amount,
            o.notes,
            o.refund_available,
            o.paypal_account,
            o.refunds?.map((r: any) => Refund.from(r)),
        )
    }

    get date_formatted() {
        return new XDate(this.date).toString('M/d/yyyy')
    }
}