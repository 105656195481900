export default class Refund {
    amount: number;
    date: string;
    made_by: string;
    tran_number: string;


    constructor(amount: number, date: string, made_by: string, tran_number: string) {
        this.amount = amount;
        this.date = date;
        this.made_by = made_by;
        this.tran_number = tran_number;
    }

    static from(o: any) {
        return new Refund(
            o.amount,
            o.date,
            o.made_by,
            o.tran_number,
        )
    }
}
