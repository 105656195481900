
import {Component, Prop, Vue} from 'vue-property-decorator';
import WaitlistItem from "@/classes/floor-map/WaitlistItem";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";

@Component<WaitListItemBookingsCell>(
    {}
)
export default class WaitListItemBookingsCell extends Vue {
  @Prop()
  data!: { item: WaitlistItem, index: number };

}
