var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!!_vm.vendor_id)?_c('b-modal',{attrs:{"title":`Move payment to booking ${(_vm.selected_booking? `#${_vm.selected_booking.id}` : '...')}`,"size":"xl","ok-disabled":!_vm.selected_booking},on:{"ok":_vm.move_payment},model:{value:(_vm.editor_visible),callback:function ($$v) {_vm.editor_visible=$$v},expression:"editor_visible"}},[_c('bookings-table',{attrs:{"admin_mode":true,"fields":[
    {key: 'id', label: 'Order Id', sortable: true,},
    {key: 'created_on', label: 'Booking Date', sortable: true, formatter: 'format_date'},
    {key: 'show', sortable: false, formatter: (value, key, item) => item.show ? item.show.name : '' },
    {key: 'status', label: 'Booking Status', sortable: true, formatter: 'format_booking_status'},
    {key: 'total', label: 'Booking Total', sortable: true, formatter: 'format_money'},
    {key: 'payments_total', label: 'Payments Total', sortable: true, formatter: 'format_money'},
    {key: 'balance_due', label: 'Balance Due', sortable: true, formatter: 'format_money'},

  ],"filter_request":_vm.filter_request},on:{"on_items_selected":_vm.booking_selected}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }