import {startCase} from "lodash-es";
import XDate from "xdate";
import {BookingsRepo} from "@/classes/repos/BookingsRepo";

class _ReHelper {
    readonly touch = matchMedia('(hover: none)').matches;
    readonly narrow_screen = matchMedia('(max-device-width: 550px)').matches;

    format_num(x: any) {
        if (x === undefined) return '';
        return parseFloat(x).toFixed(1).replace(/[.,]0$/, "");
    }

    set_id(o: any, id: any): any {
        o.id = id;
        return o;
    }

    /*    get_error_messages(ex: any): ErrorForDisplay {
            // if (ex?.response?.data?.errors instanceof Array) return ex.response?.data?.errors;
            // if (ex?.response?.data instanceof Array) return ex.response?.data;
            if (ex?.response?.data?.status == 500) return new ErrorForDisplay({'': ex.response.data.title});
            return new ErrorForDisplay({'': general_err_msg});
        }*/

    id_to_title(id: string): string {
        return startCase(id.replace(/[\-_]+/g, ' '));
    }

    title_to_id(title: string): string {
        return title?.toLowerCase().replace(/\W+/g, '_');
    }

    format_date(date: string) {
        return date ? new XDate(date).toString('M/d/yyyy') : '';
    }

    format_datetime(dt: string) {
        return dt ? new XDate(dt).toString('MMM d, yyyy h:mmtt') : '';
    }

    format_money(amt: number) {
        return amt?.toLocaleString('en-US', {style: 'currency', currency: 'USD',});
    }

    format_booking_status(status: number): string {
        const booking_status = BookingsRepo.booking_statuses[status];
        if (booking_status == 'OverPaid') return 'OverPaid';
        return ReHelper.camelcase_to_sentence(booking_status);
    }

    dates(start: string | XDate, end: string | XDate): string {
        const start_date = typeof start === 'string' ? new XDate(start) : start;
        const end_date = typeof end === 'string' ? new XDate(end) : end;

        if (start_date.diffDays(end_date) == 0) return start_date.toString('MMMM d, yyyy');
        else if (start_date.getMonth() == end_date.getMonth() && start_date.diffDays(end_date) == 1) {
            return `${start_date.toString('MMMM d')} & ${end_date.toString('d, yyyy')}`;
        } else if (start_date.getFullYear() == end_date.getFullYear()) {
            if (start_date.getMonth() == end_date.getMonth())
                return `${start_date.toString('MMMM d')} - ${end_date.toString('d, yyyy')}`;
            else return `${start_date.toString('MMMM d')} - ${end_date.toString('MMMM d, yyyy')}`;
        }
        return `${start_date.toString('MMMM d, yyyy')} - ${end_date.toString('MMMM d, yyyy')}`;
    }

    get today() {
        return new Date().toISOString().slice(0, 10);
    }

    get now() {
        return new XDate().toString('i');
    }

    empty(o: any, exclude_fields?: string[]): boolean {
        return !Object.entries(o).some(e => !exclude_fields?.includes(e[0]) && e[1] && Object.keys(e[1] as object).length);
    }

    scroll_to_top() {
        window.scroll({top: 0, left: 0, behavior: 'smooth'});
    }

    random_color(): string {
        // return `hsla(${Math.random() * 360}, 100%, 75%, 50%)`;
        return this.HSLToHex(Math.random() * 360, 100, 75);
    }

    random_light_color_hex(): string {
        return this.HSLToHex(Math.random() * 360, 100, 75);
        /*        let color = "#";
                for (let i = 0; i < 3; i++)
                    color += ("0" + Math.floor(((1 + Math.random()) * Math.pow(16, 2)) / 2).toString(16)).slice(-2);
                return color;*/
    }

    HSLToHex(h: number, s: number, l: number): string {
        s /= 100;
        l /= 100;

        let c = (1 - Math.abs(2 * l - 1)) * s,
            x = c * (1 - Math.abs((h / 60) % 2 - 1)),
            m = l - c / 2,
            r = 0,
            g = 0,
            b = 0;

        if (0 <= h && h < 60) {
            r = c;
            g = x;
            b = 0;
        } else if (60 <= h && h < 120) {
            r = x;
            g = c;
            b = 0;
        } else if (120 <= h && h < 180) {
            r = 0;
            g = c;
            b = x;
        } else if (180 <= h && h < 240) {
            r = 0;
            g = x;
            b = c;
        } else if (240 <= h && h < 300) {
            r = x;
            g = 0;
            b = c;
        } else if (300 <= h && h < 360) {
            r = c;
            g = 0;
            b = x;
        }
        // Having obtained RGB, convert channels to hex
        let r1 = Math.round((r + m) * 255).toString(16);
        let g1 = Math.round((g + m) * 255).toString(16);
        let b1 = Math.round((b + m) * 255).toString(16);

        // Prepend 0s, if necessary
        if (r1.length == 1)
            r1 = "0" + r;
        if (g1.length == 1)
            g1 = "0" + g;
        if (b1.length == 1)
            b1 = "0" + b;

        return "#" + r1 + g1 + b1;
    }

    camelcase_to_sentence(s: string) {
        return s?.replace(/([A-Z])/g, " $1");
    }

    async delay(t: number): Promise<void> {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                resolve()
            }, t)
        });
    }

    range(from: number, to: number) {
        return [...Array(to - from)].map((_, i) => i + from);
    }

    get_initials(full_name: string) {
        if (!full_name) return '';

        const all_names = full_name.trim().split(' ');
        const initials = all_names.reduce((acc, curr, index) => {
            if (index === 0 || index === all_names.length - 1) {
                acc = `${acc}${curr.charAt(0).toUpperCase()}`;
            }
            return acc;
        }, '');
        return initials;
    }

    sort_booths(bn1: string, bn2: string): number {
        const n1 = bn1.slice(1);
        const n2 = bn2.slice(1);

        return n1.localeCompare(n2, 'en', {numeric: true});
    }

}

export const ReHelper = new _ReHelper;