import {LikeableEntityGenericRepo} from "@/classes/repos/LikeableEntityGenericRepo";
import Vue from "vue";
import BookingHistory from "@/classes/history/BookingHistory";

class _BookingHistoryRepo extends LikeableEntityGenericRepo<{ booking_id: number, limit_by_ids?: number[], exclude_ids?: number[] }> {
    constructor() {
        super(BookingHistory, 'booking_history');
    }

}

export const BookingHistoryRepo = Vue.observable(new _BookingHistoryRepo());