import Vue from 'vue'
import ReFabricCanvas from "@/components/re-fabric/re-fabric-canvas.vue";
import Booth, {MapObject} from "@/classes/floor-map/Booth";
import {pix_per_foot} from "@/classes/common/Const";
import FloorMap from "@/classes/floor-map/FloorMap";
import MapObjectCompBase from "@/components/floor-map/MapObjectCompBase";
import {EventBus} from "@/classes/common/EventBus";
import BoothComp from "@/components/floor-map/BoothComp.vue";
import BoothCompBase from "@/components/floor-map/BoothCompBase";
import {BoothTypesGenericRepo} from "@/classes/common/internal";
import {JwtRepo} from "@/classes/repos/JwtRepo";

/*const initialState = {
    zoom: 1
}*/

class _MapRepo {
    current_map?: FloorMap | null = null;

    active_canvas: ReFabricCanvas | null = null;

    //region Zoom
    internal_zoom = 1;
    public_zoom = 1;
    readonly max_public_zoom = 5;

    get zoom() {
        return this.internal_zoom * this.public_zoom;
    }

    adjust_public_zoom(zoom: number) {
        this.public_zoom = zoom / this.internal_zoom;
    }

    is_zoom_acceptable(zoom: number) {
        const pz = zoom / this.internal_zoom;
        return (0.5 <= pz) && (pz <= this.max_public_zoom);
    }

    //endregion

    editor_mode = 'layout';

    get do_lock_objects_movements() {
        return this.editor_mode !== 'layout' || !JwtRepo.is_admin // || AppRepo.ui_level !== 'admin';
    }

    // selected_map_object?: MapObject | null = null;

    selected_map_objects: MapObject[] = [];

    //region selected object shorthand accessors
    get nothing_selected() {
        return !this.selected_map_objects.length;
    }

    get is_single_object_selected() {
        return this.selected_map_objects.length === 1;
    }

    get multiple_selected() {
        return this.selected_map_objects.length >= 2;
    }

    get selected_object() {
        return this.selected_map_objects[0];
    }

    get selected_booth() {
        return this.selected_map_objects[0] as Booth;
    }

    get selected_booths(): Booth[] {
        return this.selected_map_objects.filter((o: any): o is Booth => o.booth_or_complex_booth);
    }

    get is_single_booth_selected() {
        return this.is_single_object_selected && this.selected_object instanceof Booth;
    }

    get selected_booth_rw_w() {
        return this.selected_booth.booth_type.rw_width;
    }

    set selected_booth_rw_w(w: number) {
        const booth_type = this.selected_booth.booth_type;
        const curr_size: { rw_width: number, rw_height: number } = {...booth_type};
        // const bak_rw = this.selected_booth.booth_type.rw_width;
        // this.selected_booth.booth_type.rw_width = w;
        this.selected_booth.loc.width = w * pix_per_foot;
        const new_size = {rw_width: w, rw_height: booth_type.rw_height};
        BoothTypesGenericRepo.change_booth_type_if_needed(this.selected_booth, curr_size, new_size);
    }

    get selected_booth_rw_h() {
        return this.selected_booth.booth_type.rw_height;
    }

    set selected_booth_rw_h(h: number) {
        const booth_type = {...this.selected_booth.booth_type};
        const curr_size: { rw_width: number, rw_height: number } = booth_type;
        // const bak_rh = this.selected_booth.booth_type.rw_width;
        // this.selected_booth.booth_type.rw_height = h;
        this.selected_booth.loc.height = h * pix_per_foot;
        const new_size = {rw_width: booth_type.rw_width, rw_height: h};
        BoothTypesGenericRepo.change_booth_type_if_needed(this.selected_booth, curr_size, new_size);
    }

    //endregion

    selected_wrap_objects: MapObjectCompBase[] = [];

    get selected_booth_comps_including_complex(): BoothCompBase[] {
        return this.selected_wrap_objects.filter(x => x.type === 'BoothComp' || x.type === 'ComplexBoothComp') as BoothCompBase[];
    }

    //region Map Settings
    booth_fill_color = 'rgba(220, 220, 220, 0.5)';
    // booth_occupied_fill_color = 'rgba(227,197,100,0.75)';
    booth_occupied_fill_color = 'rgba(0,128,0,0.75)';
    booth_highlighted_fill_color = 'rgba(212,127,92,0.75)';
    booth_on_hold_fill_color = 'rgba(180,180,180,0.75)';
    booth_edited_stroke_width = 1;
    booth_edited_stroke_color = 'green';

    show_booth_indices = true;

    magnetic_side: 't' | 'l' | 'r' | 'b' | 'tr' | 'tl' | 'br' | 'bl' = 'tl';

    allow_fp_image_editing = false;

    //endregion


    //region Canvas
    panning_mode = false;
    pan_x = 0;
    pan_y = 0;
    //endregion

    highlighted_booths: { [k: string]: string } = {};

    //region Undo

    // undo_buffer: string[] = [];
    undo_buffer: { json: string, ts: string }[] = [];
    undo_buffer_pos = 0;
    listening_undoable_changes = true;
    processing_undo = false;

    get current_map_objects_serialized() {
        return JSON.stringify(this.current_map?.mapObjects);
    }

    get undo_available() {
        return MapRepo.undo_buffer.length - 2 - MapRepo.undo_buffer_pos >= 0;
    }

    get redo_available() {
        return MapRepo.undo_buffer_pos > 0;
    }

    //endregion
}

export const MapRepo = Vue.observable(new _MapRepo);

EventBus.$on('reset_map', () => {
    MapRepo.current_map?.mapObjects?.splice(0);
});