import {instanceToPlain} from "class-transformer";

export default class Sellable_Service {
    id: number;
    name: string;
    details: string;
    per_booth_limit: number;
    total_inventory: number;
    price: number;
    is_universal: boolean;
    service_mappings: { id: number, name: string }[];

    get short_description() {
        return this.name;
    }

    get tooltip() {
        return this.details;
    }

    constructor(id: number, name: string, details: string, per_booth_limit: number, total_inventory: number, price: number, is_universal: boolean, service_mappings: { id: number, name: string }[]) {
        this.id = id;
        this.name = name;
        this.details = details;
        this.per_booth_limit = per_booth_limit;
        this.total_inventory = total_inventory;
        this.price = price;
        this.is_universal = is_universal;
        this.service_mappings = service_mappings;
    }

    static from(o: any) {
        return new Sellable_Service(
            o.id,
            o.name,
            o.details,
            o.per_booth_limit,
            o.total_inventory,
            o.price,
            o.is_universal,
            o.service_mappings,
        )
    }

    copyTo(s: Sellable_Service) {
        s.name = this.name;
        s.details = this.details;
        s.per_booth_limit = this.per_booth_limit;
        s.total_inventory = this.total_inventory;
        s.price = this.price;
        s.is_universal = this.is_universal;
        s.service_mappings = this.service_mappings;
    }

    static default(): Sellable_Service {
        return new Sellable_Service(0, "", "", 0, 0, 0, false, []);
    }

    clone() {
        return Sellable_Service.from(this);
    }

    prepare_for_save() {
        let o = instanceToPlain(this);
        if (this.is_universal)
            o.service_mappings = [];
        else
            o.service_mappings = this.service_mappings?.map(m => ({show_id: m.id}));
        return o;
    }
}