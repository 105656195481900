
import {Component, Ref, Vue, Watch} from 'vue-property-decorator';
import BookingsTable from "@/components/generic/BookingsTable.vue";
import {BookingsRepo} from "@/classes/repos/BookingsRepo";
import {AppRepo} from "@/classes/repos/AppRepo";
import CompanySelector from "@/components/generic/CompanySelector.vue";
import {BTable} from "bootstrap-vue";
import {JwtRepo} from "@/classes/repos/JwtRepo";
import ShowSelector from "@/components/generic/ShowSelector.vue";
import ShowSelectorForVendor from "@/components/generic/ShowSelectorForVendor.vue";
import Show from "@/classes/floor-map/Show";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import App from "@/App.vue";

@Component<Bookings>(
    {
      components: {ShowSelectorForVendor, ShowSelector, CompanySelector, BookingsTable}
    }
)
export default class Bookings extends Vue {
  private filter_request: { show_ids?: number[], status?: number, start_date?: string, end_date?: string, vendor_ids?: number[] } = {
    status: -1,
    vendor_ids: []
  };

  @Ref('e_table')
  private e_table!: BTable;

  private shows: Show[] = [];

  private get admin_mode() {
    return AppRepo.ui_level === "admin";
  }

  private get booking_statuses() {
    return [{text: 'All', value: -1},
      ...BookingsRepo.booking_statuses.map((s: string, n: number) => ({text: s, value: n}))
          .filter(x => x.text != 'OnHold')];
  }

  private get company_name() {
    return JwtRepo.current_company?.name ?? '';
  }

  private goto_order_number: number | null = null;

  JwtRepo = JwtRepo;

  async created() {
    await AppRepo.load_settings();
    if (ShowsGenericRepo.current_show) {
      this.shows = [ShowsGenericRepo.current_show];
      this.filter_request.show_ids = [ShowsGenericRepo.current_show.id];
    }
  }

  mounted() {
    this.e_table?.refresh();
  }

  @Watch('admin_mode')
  private admin_mode_changed() {
    this.filter_request.vendor_ids = this.admin_mode ? undefined : [JwtRepo.vendor_id ?? -1];
  }

  private goto_order() {
    if (this.goto_order_number! > 0)
      this.$router.push({name: 'order', params: {booking_id: String(this.goto_order_number)}});
  }

  /*  private async new_order() {
      const {errors, new_id, success, entity} = await BookingsRepo.create_empty();
      await this.$router.push({name: 'order', params: {booking_id: String(new_id)}});
    }*/

  private async bulk_send_invoices() {
    if (!(await this.$bvModal.msgBoxConfirm(`Please confirm Bulk Send Invoices action`))) return;

    const {errors, success} = await BookingsRepo.bulk_send_invoices(ShowsGenericRepo.current_show!.id);

    if (errors) App.notify(errors.single_err_msg, 'danger');
    else App.notify('Emails have been sent to the exhibitors');
  }
}
