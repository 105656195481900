
import {Component, Vue} from 'vue-property-decorator';
import {JwtRepo} from "@/classes/repos/JwtRepo";
import {AppInstance} from "@/classes/repos/AppRepo";

@Component<LoginReminder>(
    {
    }
)
export default class LoginReminder extends Vue {
    private AppInstance = AppInstance;
    private JwtRepo = JwtRepo;

    private async login() {
        await this.AppInstance.login_popup.open();
    }
}
