
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class ReFilter extends Vue {
  @Prop()
  value?: string;

  @Prop({default: 'Search'})
  search_placeholder!: string;

  @Prop({default: true})
  bordered!: boolean;
}
