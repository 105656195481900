
import {Component, Prop, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import MapObjectCompBase from "@/components/floor-map/MapObjectCompBase";
import ReFabricImage from "@/components/re-fabric/re-fabric-image.vue";
import {MapObject, PrintOnlyObject} from "@/classes/floor-map/Booth";
import {MapRepo} from "@/classes/repos/MapRepo";
import ReFabricObject from "@/components/re-fabric/re-fabric-object";

@Component<PrintOnlyObjectComp>(
    {
      components: {ReFabricImage}
    }
)
export default class PrintOnlyObjectComp extends mixins(Vue, MapObjectCompBase) {
  $refs!: {
    image_wrap: ReFabricImage;
  }

  type = 'PrintOnlyObjectComp';

  @Prop()
  print_only_object!: PrintOnlyObject;

  @Prop({default: false})
  lowest_layer!: boolean;

  @Prop({default: true})
  editable!: boolean;

  get map_object(): MapObject {
    return this.print_only_object;
  }

  get fjs_wrap_object(): ReFabricObject {
    return this.$refs.image_wrap;
  }

  get debug_name() {
    const a = this.print_only_object.image_url.split('/');
    return a[a.length - 1];
  }

  private MapRepo = MapRepo;
}
