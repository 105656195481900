import {MapObject} from "@/classes/floor-map/Booth";
import {MapRepo} from "@/classes/repos/MapRepo";
import {Component, Vue} from "vue-property-decorator";
import Config from "@/classes/common/Config";
import Loc from "@/classes/common/Loc";
import ReFabricObject from "@/components/re-fabric/re-fabric-object";

@Component
export default class MapObjectCompBase extends Vue {
    protected is_mounted = false;
    protected config = Config;

    readonly type: string = 'MapObjectCompBase';

    get map_object(): MapObject {
        throw 'map_object not implemented';
    }

    get fjs_wrap_object(): ReFabricObject {
        throw 'fjs_wrap_object not implemented';
    }

    get debug_name() {
        return '(No name)';
    }

    mounted() {
        this.is_mounted = true;
        // console.log(`${this.constructor.name} mounted ${this.debug_name}`)
    }

    protected get editor_mode() {
        return MapRepo.editor_mode;
    }

    /*    protected get zoom(): number {
            return MapRepo.zoom;
        }*/

    child_modified(t: MapObject, loc: Loc, angle?: number, size_changed?: boolean) {
        // console.log(`${this.constructor.name}.child_modified`, arguments);
        t.update_loc(loc, angle);
    }

    selected_from_fjs(map_object: MapObject) {
        // console.log(`${this.constructor.name} selected_from_fjs: ${map_object.UID}`)
        MapRepo.selected_map_objects.push(map_object);
        MapRepo.selected_wrap_objects.push(this);
    }

    deselected_from_fjs(map_object: MapObject) {
        // console.log(`${this.constructor.name} deselected_from_fjs: ${map_object.UID}`)
        MapRepo.selected_map_objects.splice(MapRepo.selected_map_objects.indexOf(map_object), 1);
        MapRepo.selected_wrap_objects.splice(MapRepo.selected_wrap_objects.indexOf(this), 1);
    }


}