
import {Component, Prop, Vue} from 'vue-property-decorator';
import BoothTypeFullEditor from "@/components/editors/BoothTypeFullEditor.vue";
import {BoothType} from "@/classes/floor-map/BoothType";

@Component({
             components: {BoothTypeFullEditor}
           })
export default class BoothQuickPropsEditor extends Vue {
  @Prop()
  booth_type!: BoothType;

  @Prop()
  booth_letter!: string;

  private days_options = Array.from(Array(10).keys()).map(n => ({
    value: n + 1,
    text : `${n + 1} ${n ? 'Days' : 'Day'}`
  }));

  private changed() {
    if (['BoothType', 'ComplexBoothType'].includes(this.booth_type.type))
      (this.booth_type as BoothType).is_dirty = true;

    this.booth_type.price ||= 0;
  }

}
