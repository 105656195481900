
import {Component} from 'vue-property-decorator';
import EntityEditor from "@/components/generic/EntityEditor.vue";
import BookingHistory from "@/classes/history/BookingHistory";
import {BookingHistoryRepo} from "@/classes/repos/BookingHistoryRepo";

@Component<BookingHistoryViewer>(
    {
      components: {}
    }
)
export default class BookingHistoryViewer extends EntityEditor {
  repo = BookingHistoryRepo;
  TEntity = BookingHistory;

  protected entity: (BookingHistory | null) = null;

  private items: { [k: string]: string } = {};

  protected async load_additional(): Promise<void> {
    this.items = JSON.parse(((await this.repo.details(this.entity!.id)).entity as BookingHistory).data);
  }

  protected setup_entity() {
    this.entity!.copyTo(<BookingHistory>this.orig_entity);
  }

}
