
import {Component, Vue} from 'vue-property-decorator';
import MapEditor from "@/components/floor-map/MapEditor.vue";
import {MapRepo} from "@/classes/repos/MapRepo";
import {RawLocation, Route} from "vue-router";

@Component(
    {
      components: {MapEditor},
      async beforeRouteEnter(to: Route, from: Route, next: (to?: (RawLocation | false | ((vm: PricingPage) => void))) => void) {
        next(async vm => {
          MapRepo.editor_mode = 'pricing';
        });
      },

    }
)
export default class PricingPage extends Vue {

}
