
import {Component, Prop, Vue} from 'vue-property-decorator';
import {ReHelper} from "@/classes/common/ReHelper";
import {BFormInput} from "bootstrap-vue";

export type search_field_description = { name: string, css_class?: string, row_start?: boolean, type?: 'text' | 'date' };

@Component<EntitySearchForm>(
    {}
)
export default class EntitySearchForm extends Vue {
  $refs!: {
    input_0_0: BFormInput[];
  };

  @Prop({default: []})
  fields!: (string | search_field_description)[];

  @Prop({default: {}})
  private search_request: any;

  @Prop({default: false})
  use_dropdown_form!: boolean;

  protected ReHelper = ReHelper;

  async mounted() {
    this.$root.$on('bv::dropdown::shown', async bvEvent => {
      await this.$nextTick();
      this.$refs.input_0_0[0]?.focus();
    })
  }

  protected get effective_fields(): search_field_description[] {
    return this.fields.map(f => typeof f === 'string' ? {name: f} : f);
  }

  protected get fields_grouped(): Array<Array<search_field_description>> {
    return this.effective_fields.reduce((a: Array<Array<search_field_description>>, x: search_field_description, n: number) => {
      if (n == 0 || x.row_start) a.push([x]); else a[a.length - 1].push(x);
      return a;
    }, []);
  }

  private get form_comp() {
    return this.use_dropdown_form ? 'b-dropdown-form' : 'b-form';
  }
}
