
import {Component, Prop, Vue} from 'vue-property-decorator';
import Booth from "@/classes/floor-map/Booth";

@Component<BoothControlPanel>(
    {}
)
export default class BoothControlPanel extends Vue {
  @Prop()
  booth!: Booth;

  remove() {
    // this.map.remove(this.booth);
    this.$emit('remove_booth', this.booth);
    this.$emit('remount');
  }

  async rotate(angle: number) {
    // MapRepo.listening_undoable_changes = false;
    this.booth.angle = (this.booth.angle + angle) % 360;
    await this.$nextTick()
    // MapRepo.listening_undoable_changes = true;
  }

  duplicate() {
    this.$emit('duplicate', this.booth);
  }
}
