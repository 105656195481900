
import {Component, Prop} from 'vue-property-decorator';
import EntityTable, {field_definition} from "@/components/generic/EntityTable.vue";
import WaitListItemSortCell from "@/components/Reservations/WaitListItemSortCell.vue";
import {LikeableEntity} from "@/classes/repos/LikeableEntityGenericRepo";
import {RawLocation} from "vue-router";
import {WaitlistRepo} from "@/classes/repos/WaitlistRepo";
import WaitlistItem from "@/classes/floor-map/WaitlistItem";
import CompanyLink from "@/components/vendor/CompanyLink.vue";
import WaitlistItemEditor from "@/components/editors/WaitlistItemEditor.vue";
import {JwtRepo} from "@/classes/repos/JwtRepo";
import Show from "@/classes/floor-map/Show";
import {ReHelper} from "@/classes/common/ReHelper";
import DataProviderContext from "@/classes/common/DataProviderContext";
import {orderBy} from "lodash-es";
import WaitListItemBookingsCell from "@/components/Reservations/WaitListItemBookingsCell.vue";

@Component<WaitlistItemsTable>(
    {components: {CompanyLink, WaitlistItemEditor, WaitListItemSortCell, WaitListItemBookingsCell}}
)
export default class WaitlistItemsTable extends EntityTable {
  @Prop()
  show: Show | null = null;

  repo = WaitlistRepo;
  TEntity = WaitlistItem;

  protected sort_by = 'sort_order';
  protected sort_desc = false;
  editor_comp = 'WaitlistItemEditor';


  protected get default_fields(): (field_definition | undefined)[] {
    return [
      JwtRepo.is_admin ? {key: 'vendor_id', label: 'Exhibitor', sortable: false, component: 'CompanyLink'} : undefined,
      {key: 'show_name', label: 'Show', sortable: true,},
      {key: 'location', label: 'Location', sortable: true,},
      {
        key: 'start_date',
        label: 'Date',
        sortable: true,
        formatter: (value: string, key: string, item: WaitlistItem) => ReHelper.dates(item.start_date, item.end_date)
      },
      {key: 'request', sortable: false,},
      {key: 'created', label: 'Request Added', sortable: true, formatter: 'format_datetime'},

      JwtRepo.is_admin ? {key: 'edit', label: '', sortable: false,} : undefined,
      JwtRepo.is_logged_in ? {key: 'delete', label: '', sortable: false,} : undefined,
      JwtRepo.is_logged_in ? {key: 'sort_order', label: 'Priority', sortable: true, component: 'WaitListItemSortCell'} : undefined,
      JwtRepo.is_logged_in ? {key: 'bookings', label: 'Bookings', sortable: false, component: 'WaitListItemBookingsCell'} : undefined,
    ];
  }

  protected get entity_single_name(): string {
    return 'Waitlist Request';
  }

  protected async data_provider(ctx: DataProviderContext): Promise<LikeableEntity[]> {
    if (this.need_reload_from_server) {
      this.busy = true;
      await this.update_sort_order_if_needed();
      const ctx_all_items = {...ctx, ...{currentPage: 1, perPage: 1e6}};
      const res: { success: boolean; items: LikeableEntity[]; total: number; errors?: any } = await this.repo.list(ctx_all_items, this.search_request);
      this.totalRows = res.total;
      this.busy = false;

      res.items.forEach((i: any) => i._showDetails = false);

      this.loaded_items = res.items;
    }

    const ordered_items = orderBy(this.loaded_items, [this.sort_by], [this.sort_desc ? 'desc' : 'asc']);

    return this.items_on_page = ordered_items.slice((ctx.currentPage - 1) * ctx.perPage, ctx.currentPage * ctx.perPage);
  }

  protected setup_new_item(): LikeableEntity {
    let item = WaitlistItem.default();
    // item.show_id = ShowsGenericRepo.current_show!.id;
    if (this.$route.name === 'account_waitlist')
      item.vendor_id = JwtRepo.vendor_id || null;
    return item;
  }

  protected get show_add_btn() {
    return false;
  }

  @Prop({default: {}})
  filter_request: any;

  get search_request(): any {
    return this.filter_request;
  }

  actions: { key: string; text: string; to: (b: LikeableEntity) => RawLocation }[] = [
    {
      key: 'edit_company',
      text: 'Edit',
      to: c => ({
        name: 'admin_vendor_profile',
        params: {vendor_id: c.id.toString()}
      })
    }
  ]

  protected comp_event_handlers = {move_element: this.move_item};


  private async move_item(from: number, to: number) {
    const item = this.loaded_items.splice(from, 1)[0];
    this.loaded_items.splice(to, 0, item);
    this.loaded_items.forEach((v: WaitlistItem, n: number) => v.sort_order = n + 1);

    this.defer_update_sort_order();

    const visible_row_index = to - (this.currentPage - 1) * this.perPage;

    this.$refs.table.$once('refreshed', async () => {
      await this.$nextTick()
      this.$refs.table.selectRow(visible_row_index)
    });
    await this.$refs.table.refresh();
  }

  private update_sort_order_timeout_msec = 500;
  private update_sort_order_timeout_handler = 0;

  private defer_update_sort_order() {
    if (!this.update_sort_order_timeout_handler)
      this.update_sort_order_timeout_handler = setTimeout(this.run_update_sort_order, this.update_sort_order_timeout_msec);
  }

  private async run_update_sort_order() {
    clearTimeout(this.update_sort_order_timeout_handler);
    this.update_sort_order_timeout_handler = 0;

    const ordered_ids = this.loaded_items.map(i => i.id);

    await this.repo.update_sort_order(this.show!.id, ordered_ids.join(','));
  }

  private async update_sort_order_if_needed() {
    if (this.update_sort_order_timeout_handler)
      await this.run_update_sort_order();
  }

  protected tbodyTrClass(item: WaitlistItem | null, type: string): string {
    return item?.bookings.length? 'table-success' : (item?.canceled_bookings.length? 'table-danger' : '');
  }

}
