export default class BookingHistory {
    id: number;
    booking_id: number;
    short_description: string;
    time: string;
    changed_by: string;
    role: string;
    property: string;
    new_value: string;
    has_data: boolean;
    data: string;


    constructor(id: number, booking_id: number, short_description: string, time: string, changed_by: string, role: string, property: string, new_value: string, has_data: boolean, data: string) {
        this.id = id;
        this.booking_id = booking_id;
        this.short_description = short_description;
        this.time = time;
        this.changed_by = changed_by;
        this.role = role;
        this.property = property;
        this.new_value = new_value;
        this.has_data = has_data;
        this.data = data;
    }

    get name() {
        return this.short_description;
    }

    static from(o: any) {
        return new BookingHistory(
            o.id,
            o.booking_id,
            o.short_description,
            o.time,
            o.changed_by,
            o.role,
            o.property,
            o.new_value,
            o.has_data,
            o.data,
        )
    }

    static default(): BookingHistory {
        return new BookingHistory(0, 0, "", "", "", "", "", "", false, "{}");
    }

    prepare_for_save() {
        throw "BookingHistory isn't intended for save";
    }

    clone() {
        return BookingHistory.from(this);
    }

    copyTo(h: BookingHistory) {
        h.booking_id = this.booking_id;
        h.short_description = this.short_description;
        h.time = this.time;
        h.changed_by = this.changed_by;
        h.role = this.role;
        h.property = this.property;
        h.new_value = this.new_value;
        h.has_data = this.has_data;
        h.data = this.data;
    }
}