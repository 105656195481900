import {Exclude, instanceToPlain} from "class-transformer";

export default class User {
    id: number;
    email: string;
    pwd: string;
    is_active: boolean;
    roles: { role_id: string, shows_access_is_limited: boolean, limit_by_shows?: number[] }[];
    roles_list?: string;
    first_name: string;
    last_name: string;
    phone: string;
    created_at: string;
    last_logged_in?: string;
    vendor_id: number | null = null;
    vendor_name: string | null = null;
    @Exclude()
    vendor_mappings: { id: number, name: string }[] = [];

    // company: Company | null = null;

    get name() {
        return `${this.first_name} ${this.last_name}`;
    }

    get short_description() {
        return `${this.first_name} ${this.last_name}`;
    }

    constructor(id: number,
                email: string,
                pwd: string,
                is_active: boolean,
                roles: { role_id: string, shows_access_is_limited: boolean, limit_by_shows: number[] | string }[],
                first_name: string,
                last_name: string,
                phone: string,
                created_at: string,
                last_logged_in?: string,
                roles_list?: string,
                vendor_mappings: { id: number, name: string }[] = [],
    ) {
        this.id = id;
        this.email = email;
        this.pwd = pwd;
        this.is_active = is_active;
        this.roles = roles?.map(r => ({
            role_id                : r.role_id,
            shows_access_is_limited: r.shows_access_is_limited,
            limit_by_shows         : typeof r.limit_by_shows === 'string' ? JSON.parse(r.limit_by_shows) : r.limit_by_shows
        }));
        this.first_name = first_name;
        this.last_name = last_name;
        this.phone = phone;
        this.created_at = created_at;
        this.last_logged_in = last_logged_in;
        this.roles_list = roles_list;
        this.vendor_mappings = vendor_mappings;
    }

    static from(o: any): User {
        return new User(
            o.id,
            o.email,
            o.pwd,
            o.is_active,
            o.roles,
            o.first_name,
            o.last_name,
            o.phone,
            o.created_at,
            o.last_logged_in,
            o.roles_list,
            o.vendor_mappings,
        )
    }

    clone(): User {
        return User.from(this);
    }

    copyTo(p: User) {
        p.id = this.id;
        p.email = this.email;
        p.pwd = this.pwd;
        p.is_active = this.is_active;
        p.roles = [...this.roles ?? []];
        p.first_name = this.first_name;
        p.last_name = this.last_name;
        p.phone = this.phone;
        p.created_at = this.created_at;
        p.last_logged_in = this.last_logged_in;
        p.roles_list = this.roles_list;
        p.vendor_mappings = this.vendor_mappings;
    }

    static default() {
        return new User(0, '', '', true, [], '', '', '', new Date().toISOString(), undefined, '', []);
    }

    prepare_for_save() {
        let o = instanceToPlain(this);
        /*if (this.vendor_id && this.roles.some(r => r.role_id === 'vendor'))
            o.vendor_mapping = {company_id: this.vendor_id};*/
        if (this.vendor_mappings?.length)
            o.vendor_mappings = this.vendor_mappings.map(m => ({company_id: m.id}));
        return o;
    }
}