export default class BookingMultiTableDiscount {
    id: number;
    booking_id: number;
    discount_id: number;
    booth_type: string;
    discount_amount: number;
    discount_qty: number;
    affected_booths_count: number;
    created: string;

    show_name?: string;

    get discount_total() {
        return this.discount_amount * this.discount_qty;
    }


    constructor(id: number, booking_id: number, discount_id: number, booth_type: string, discount_amount: number, discount_qty: number, affected_booths_count: number, created: string, show_name?: string) {
        this.id = id;
        this.booking_id = booking_id;
        this.discount_id = discount_id;
        this.booth_type = booth_type;
        this.discount_amount = discount_amount;
        this.discount_qty = discount_qty;
        this.affected_booths_count = affected_booths_count;
        this.created = created;

        this.show_name = show_name;
    }

    static from(o: any): BookingMultiTableDiscount {
        return new BookingMultiTableDiscount(
            o.id,
            o.booking_id,
            o.discount_id,
            o.booth_type,
            o.discount_amount,
            o.discount_qty,
            o.affected_booths_count,
            o.created,
            o.show_name,
        )
    }
}
