
import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import {BookingsRepo} from "@/classes/repos/BookingsRepo";
import Booking from "@/classes/bookings/Booking";
import App from "@/App.vue";
import ButtonNewPayment from "@/components/Reservations/ButtonNewPayment.vue";
import Payment from "@/classes/bookings/Payment";
import {ReHelper} from "@/classes/common/ReHelper";
import CompanySelector from "@/components/generic/CompanySelector.vue";
import Company from "@/classes/companies/Company";
import BoothSelector from "@/components/floor-map/BoothSelector.vue";
import {IBoothInfo} from "@/classes/floor-map/IBoothInfo";
import BookingItem from "@/classes/bookings/BookingItem";
import SellableServiceBookingItemBuilder from "@/components/floor-map/SellableServiceBookingItemBuilder.vue";
import Sellable_Service from "@/classes/floor-map/Sellable_Service";
import Show from "@/classes/floor-map/Show";
import {CompaniesRepo} from "@/classes/repos/CompaniesRepo";
import ManualPaymentEditor from "@/components/editors/ManualPaymentEditor.vue";
import BookingHistoryTable from "@/components/generic/BookingHistoryTable.vue";
import SharedVendorSelector from "@/components/Reservations/SharedVendorSelector.vue";
import CompanyLink from "@/components/vendor/CompanyLink.vue";
import ShowSelector from "@/components/generic/ShowSelector.vue";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import ExportPdfReceipt from "@/components/Reservations/ExportPdfReceipt.vue";
import MovePaymentPopup from "@/components/editors/MovePaymentPopup.vue";
import RefundEditor from "@/components/editors/RefundEditor.vue";
import BookingQuizComp_v2 from "@/components/Reservations/BookingQuizComp_v2.vue";
import CollapsibleCard from "@/components/common/CollapsibleCard.vue";

@Component<AdminOrder>(
    {
        components: {
            CollapsibleCard,
            BookingQuizComp_v2,
            RefundEditor,
            MovePaymentPopup,
            ExportPdfReceipt,
            ShowSelector,
            SharedVendorSelector,
            BookingHistoryTable,
            ManualPaymentEditor,
            SellableServiceBookingItemBuilder,
            BoothSelector, CompanySelector, ButtonNewPayment, CompanyLink
        }
    }
)
export default class AdminOrder extends Vue {
    @Prop()
    booking_id!: number;

    private booking: Booking | null = null;

    @Ref()
    history_table!: BookingHistoryTable;

    @Ref()
    shared_vendor_selector!: SharedVendorSelector;

    @Ref()
    refund_editor!: RefundEditor;

    private get discount_valid() {
        return this.booking!.custom_discount >= 0 && this.booking!.custom_discount <= this.booking!.items_total;
    }

    private get is_valid_for_saving(): boolean {
        return !!this.booking?.vendor_id && !!this.booking?.show?.id && this.discount_valid;// && !!this.booking.total;
    }

    private get vendor_overdue_booking_ids() {
        return this.booking?.vendor_info?.overdue_booking_ids ?? [];
    }

    @Watch('booking_id')
    private async load_booking() {
        if (this.booking_id) {
            const {booking, errors, success} = await BookingsRepo.get_booking(this.booking_id);
            if (errors) App.notify(errors.single_err_msg, 'danger');
            else this.booking = booking!;

            this.load_related_data();

        } else {
            this.booking = Booking.default();
            this.booking.show = ShowsGenericRepo.current_show;
        }
    }

    private async created() {
        await this.load_booking();
    }

    private load_related_data() {
        this.history_table?.refresh();
    }

    private async remove() {
        const confirmed: boolean = await this.$bvModal.msgBoxConfirm(`Delete this order?`);
        if (!confirmed) return;

        await BookingsRepo.remove(this.booking!.id);
        App.notify(`Order ${this.booking?.id} deleted`);
        await this.$router.push({name: 'orders'});
    }

    private async save(): Promise<boolean> {
        const {
            errors,
            booking
        } = await BookingsRepo.save(this.booking!);
        if (!errors) {
            this.booking!.id = booking!.id;
            App.notify('Order saved');
            this.load_related_data();
            if (!this.booking_id) //first saving
                await this.$router.replace({name: 'order', params: {booking_id: String(booking!.id)}});
            else {
                this.booking = booking!;
                this.$forceUpdate()
            }

            return true;
        } else {
            App.notify(errors.single_err_msg, 'danger');
            return false;
        }
    }

    private async remove_payment(p: Payment) {
        if (!await this.$bvModal.msgBoxConfirm(`Remove the payment for ${ReHelper.format_money(p.amount)}?`)) return;

        const {
            errors,
            success
        } = await BookingsRepo.remove_payment(this.booking!.id, p.id);

        if (success)
            App.notify(`Payment removed`);
        else
            App.notify(errors!.single_err_msg, 'danger');

        await this.load_booking();
    }

    private async refund(p: Payment) {
        await this.refund_editor.open(p);

        /*

        await this.load_booking();*/
    }

    private async vendor_changed(c: Company) {
        this.booking!.vendor_id = c.id;
        this.booking!.vendor_name = c.name;
        this.load_related_data();
    }

    private async add_booth(item: IBoothInfo, show_id: number, fp_id: number) {
        const {
            errors,
            booking
        } = await BookingsRepo.add_to_booking(this.booking!.id, item.booth_number, show_id, fp_id);

        if (booking) {
            this.booking = booking;
            this.load_related_data();
            this.$forceUpdate()
        } else App.notify(errors!.single_err_msg, 'danger');
    }

    private async add_service(service: Sellable_Service, show: Show, fp_id: number) {
        const {
            errors,
            booking,
        } = await BookingsRepo.add_or_update_service_in_booking(service.id, 1, show.id, fp_id, this.booking!.id);

        if (booking) {
            this.booking = booking;
            this.load_related_data();
            this.$forceUpdate()
        } else App.notify(errors!.single_err_msg, 'danger');
    }

    private async set_order_status(status: string) {

        const saved_ok = await this.save();

        if (!saved_ok) return;

        const {
            errors,
            booking
        } = await BookingsRepo.set_order_status(this.booking!.id, status);

        if (booking) {
            this.booking = booking;
            this.load_related_data();
            this.$forceUpdate()
        } else App.notify(errors!.single_err_msg, 'danger');
    }


    private async remove_item(item: BookingItem) {
        if (!(await this.$bvModal.msgBoxConfirm(`Remove this item?`))) return;

        await BookingsRepo.remove_item(this.booking!.id, item.id);
        this.booking = BookingsRepo.current_booking;
        this.load_related_data();
        // this.booking!.items.splice(this.booking!.items.indexOf(item), 1);
    }

    private async send_invoice() {
        await BookingsRepo.send_invoice(this.booking!.id);
        App.notify('Email has been sent to the exhibitor');
    }

    /*private async edit_booking() {
      await BookingsRepo.edit_booking(this.booking!.id, this.booking!.vendor_id, this.booking!.notes_admin);
      App.notify('Order saved');
    }*/

    private async add_or_update_service_in_booking(item: BookingItem) {
        await BookingsRepo.add_or_update_service_in_booking(item.service_id!, item.qty!, item.show_id, item.floorplan_id, this.booking!.id);
        await this.update_totals();
        this.load_related_data();
    }

    private async update_totals() {
        const {booking, errors, success} = await BookingsRepo.get_booking(this.booking!.id);
        this.booking!.total = booking!.total;
        this.booking!.services_total = booking!.services_total;
        this.booking!.booths_total = booking!.booths_total;
        this.load_related_data();
    }

    private async add_vendor() {
        const {new_id} = await CompaniesRepo.create_empty();
        await this.$router.push({name: 'admin_vendor_profile', params: {vendor_id: String(new_id)}});
    }

    private after_manual_payment(b: Booking) {
        this.booking = b;
        this.load_related_data();
    }

    private async unshare(item: BookingItem) {
        if (!(await this.$bvModal.msgBoxConfirm(`Unshare booth ${item.booth_number}?`))) return;

        await BookingsRepo.set_shared_vendor(item.id, null);

        item.sharing_with_vendor_id = null;
        item.sharing_with_vendor_name = null;

    }

}
