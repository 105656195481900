import IRW_Size from "@/classes/floor-map/IRW_Size";
import {pix_per_foot} from "@/classes/common/Const";

export default class Loc {
    top?: number;
    left?: number;
    width!: number;
    height!: number;
    scaleX!: number;
    scaleY!: number;

    constructor(top: number | undefined, left: number | undefined, width: number, height: number, scaleX: number = 1, scaleY: number = 1) {
        this.top = top;
        this.left = left;
        this.width = width;
        this.height = height;
        this.scaleX = scaleX;
        this.scaleY = scaleY;
    }

    static from(o: any): Loc {
        return new Loc(o.top, o.left, o.width, o.height, o.scaleX, o.scaleY);
    }

    static from_point_and_rw_size(top: number, left: number, rw_size: IRW_Size): Loc {
        return new Loc(top, left, rw_size.rw_width * pix_per_foot, rw_size.rw_height * pix_per_foot);
    }

    clone(): Loc {
        return Loc.from({...this});
    }

    get center_x(): number {
        return this.left! + this.width / 2;
    }

    get center_y(): number {
        return this.top! + this.height / 2;
    }

    get center_point() {
        // return {top: this.center_y, left: this.center_x, width: this.width, height: this.height};
        return new Loc(this.center_y, this.center_x, this.width, this.height, this.scaleX, this.scaleY);
    }

    get zero_originated(): Loc {
        return new Loc(0, 0, this.width, this.height, this.scaleX, this.scaleY);
    }

    get strip_pos(): Loc {
        return new Loc(undefined, undefined, this.width, this.height, this.scaleX, this.scaleY);
    }

    get object(): object {
        const {top, left, width, height, scaleX, scaleY} = this;
        return {top, left, width, height, scaleX, scaleY};
    }

    get pos_object(): object {
        const {top, left} = this;
        return {top, left};
    }

    static get zero(): Loc {
        return new Loc(0, 0, 100, 100, 1, 1);
    }

    add(point: Loc): Loc {
        return new Loc(this.top! + point.top!, this.left! + point.left!, this.width, this.height, this.scaleX, this.scaleY);
    }

    subtract(point: Loc): Loc {
        return new Loc(this.top! - point.top!, this.left! - point.left!, this.width, this.height, this.scaleX, this.scaleY);
    }

    rotate(angle: number): Loc {
        switch (angle) {
            case 0:
                return this;
            case 90:
            case -270:
                return new Loc(this.top, this.left! - this.height, this.height, this.width, this.scaleX, this.scaleY);
            case 180:
            case -180:
                return new Loc(this.top! - this.height, this.left! - this.width, this.width, this.height, this.scaleX, this.scaleY);
            case 270:
            case -90:
                return new Loc(this.top! - this.width, this.left, this.height, this.width, this.scaleX, this.scaleY);
        }
        return this;
    }

    set_size(w: number, h: number) {
        this.width = w;
        this.height = h;
        return this;
    }

    /*toString() {
        return JSON.stringify({
                                  top: this.top?.toFixed(0),
                                  left: this.left?.toFixed(0),
                                  width: this.width?.toFixed(0),
                                  height: this.height?.toFixed(0),
        });
    }*/

    toString() {
        return `${this.top?.toFixed(0)}:${this.left?.toFixed(0)} ${this.width?.toFixed(0)}:${this.height?.toFixed(0)}`;
    }
}