import {LikeableEntityGenericRepo} from "@/classes/repos/LikeableEntityGenericRepo";
import FloorMapLib from "@/classes/floor-map/FloorMapLib";
import axios from "axios";
import ErrorForDisplay from "@/classes/common/ErrorForDisplay";
import Vue from "vue";

class _MapLibrariesGenericRepo extends LikeableEntityGenericRepo<{ show_name?: string, show_location?: string, show_start_date?: string, show_end_date?: string, created_on?: string, limit_by_ids?: number[], exclude_ids?: number[] }> {
    protected entities_cache: FloorMapLib[] = [];

    floorMapLibs: FloorMapLib[] = [];

    constructor() {
        super(FloorMapLib, 'Map_Library');
    }

    async load_map_library() {
        const {errors, items, success, total} = await super.list();
        this.floorMapLibs = success ? items as FloorMapLib[] : [];
    }

    async save_map_image(map_library_id: number, data_url: string): Promise<{ success: boolean; errors?: ErrorForDisplay }> {
        const blob = await (await fetch(data_url)).blob();
        const file = new File([blob], `map_${map_library_id}.png`, {
            type        : "image/png",
            lastModified: new Date().getDate()
        });

        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", file.name);
        try {
            await axios.post(`/map_library/save_map_image`, formData);
            return {success: true};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

}

export const MapLibrariesGenericRepo = Vue.observable(new _MapLibrariesGenericRepo());