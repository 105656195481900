
import {Component, Prop, Vue} from 'vue-property-decorator';
import FloorMap from "@/classes/floor-map/FloorMap";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";

@Component(
    {}
)
export default class LayerSwitch extends Vue {
  @Prop()
  maps?: FloorMap[];

  @Prop()
  current_map?: FloorMap;

  // private new_fp = FloorPlan.default();

  async delete_map(map: FloorMap, n_map: number) {
    if (!await this.$bvModal.msgBoxConfirm(`Remove layer ${map?.floor_plan?.name}?`)) return;
    ShowsGenericRepo.remove_map(n_map);
  }

}
