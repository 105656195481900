import {base_URL} from "./Const";
import Vue from "vue";

class _Config {
    readonly base_URL = base_URL;
    readonly debug = false; //process.env.NODE_ENV === "development"; //
    readonly is_dev_mode = process.env.NODE_ENV === "development";
}

const Config = Vue.observable(new _Config());
export default Config;