
import {Component, Model, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import {LikeableEntity, LikeableEntityGenericRepo} from "@/classes/repos/LikeableEntityGenericRepo";
import DataProviderContext from "@/classes/common/DataProviderContext";
import EntitySearchForm, {search_field_description} from "@/components/generic/EntitySearchForm.vue";
import EntityTable, {field_definition} from "@/components/generic/EntityTable.vue";
import {BDropdown} from "bootstrap-vue";

@Component<EntitySelector>(
    {
      components: {EntityTable, EntitySearchForm}
    }
)
export default class EntitySelector extends Vue {
  @Prop({default: false})
  use_dropdown_form!: boolean;

  @Prop({default: false})
  auto_close_dropdown!: boolean;

  @Prop({default: false})
  single_selection!: boolean;

  @Prop({default: () => []})
  external_exclude_ids!: number[];

  @Prop({default: () => ''})
  dropdown_title!: string;

  @Prop({default: false})
  hide_selected_items_list!: boolean;

  protected entity_plural_name = 'items';

  protected repo?: LikeableEntityGenericRepo<any>;
  protected found_items: LikeableEntity[] = [];
  protected total: number = 0;
  protected curr_page: number = 1;
  protected per_page: number = 10;

  @Model('selected_items_changed', {default: null})
  model!: LikeableEntity | LikeableEntity[] | null;

  //needed when v-model isn't provided by a client component so selected items need to be stored somewhere
  private internal_model: LikeableEntity[] = [];

  get selected_items(): LikeableEntity[] {
    if (!this.model) return this.internal_model;
    return Array.isArray(this.model) ? this.model : (this.model ? [this.model] : []);
  }

  protected search_fields: (string | search_field_description)[] = [];
  protected search_results_fields: field_definition[] = ['name']; //Not used?

  private get form_wrapper() {
    return this.use_dropdown_form ? 'b-dropdown' : 'div';
  }

  private get exclude_ids() {
    return [...this.external_exclude_ids, ...this.selected_items.map(x => x.id)];
  }

  private search_request: any = {};

  protected get search_request_additional_params(): any {
    return {}
  }

  protected sort_by = 'name';

  @Ref()
  private dropdown!: BDropdown;

  async load_items() {
    let res: { success: boolean; items: LikeableEntity[]; total: number; errors?: any } =
        await this.repo!.list(new DataProviderContext(this.curr_page, this.per_page, this.search_request.filter, this.sort_by), {
          ...this.search_request,
          ...this.search_request_additional_params,
          exclude_ids: this.exclude_ids
        });
    this.found_items = res.items;
    this.total = res.total;
  }

  async mounted() {
    this.internal_model = this.selected_items;
    await this.load_items();
  }

  protected entity_short_description(e: LikeableEntity): string {
    return e.name;
  }

  on_items_selected(items: LikeableEntity[]) {
    if (this.single_selection) {
      this.$emit('selected_items_changed', items[0]);
    } else { //multiple selection mode
      let new_items = items.filter(e => !this.selected_items.some(x => x.id === e.id));
      /*if (this.single_selection) {
        new_items = [new_items[0]];
        this.selected_items.splice(0);
      }*/
      // (this.model as LikeableEntity[]).push(...new_items);
      // this.$emit('selected_items_changed', this.model, new_items);
      if (this.model)
        (this.model as LikeableEntity[]).push(...new_items);
      else
        this.internal_model.push(...new_items);
      this.$emit('selected_items_changed', this.selected_items, new_items);
    }

    if (this.use_dropdown_form && (this.auto_close_dropdown || this.single_selection)) this.dropdown?.hide(true);
  }

  @Watch('curr_page')
  @Watch('exclude_ids')
  @Watch('search_request', {deep: true})
  private async search_request_changed() {
    await this.load_items();
  }

  private item_remove(item: LikeableEntity) {
    if (this.single_selection) {
      this.$emit('selected_items_changed', null);
      this.internal_model = [];
    } else {
      this.selected_items.splice(this.selected_items.indexOf(item), 1);
      this.$emit('selected_items_changed', this.selected_items);
    }
  }
}
