
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import {BoothType} from "@/classes/floor-map/BoothType";
import {MapRepo} from "@/classes/repos/MapRepo";
import BoothTypeImage from "@/components/common/BoothTypeImage.vue";
import {BoothTypesGenericRepo} from "@/classes/repos/BoothTypesGenericRepo";
import BoothTypeQuickPropsEditor from "@/components/editors/BoothTypeQuickPropsEditor.vue";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import MultiTableDiscountComp from "@/components/floor-map/MultiTableDiscountComp.vue";
import MultiTableDiscount from "@/classes/floor-map/MultiTableDiscount";
import {BookingsRepo} from "@/classes/repos/BookingsRepo";

@Component({
  components: {MultiTableDiscountComp, BoothTypeQuickPropsEditor, BoothTypeImage}
})
export default class Palette extends Vue {

  private MapRepo = MapRepo;

  private get palette_booth_types() {
    return Object.fromEntries(
        BoothTypesGenericRepo.all_booth_types_sorted
            .filter(t => t.is_favourite || t.id <= 0)
            .map(t => [t.letter_index, t])
    );
  }

  get show_multi_table_discounts() {
    return ShowsGenericRepo.current_show!.multi_table_discounts;
  }

  add_multi_table_discount() {
    ShowsGenericRepo.current_show!.multi_table_discounts.push(new MultiTableDiscount(0, ShowsGenericRepo.current_show!.id, "", BookingsRepo.multi_table_discount_min_booth_count, 0));
  }

  private dragStart(booth_type: BoothType, e: DragEvent) {
    const item_offset = (e!.target as any).getBoundingClientRect();
    let internal_x = e!.pageX - item_offset.left;
    let internal_y = e!.pageY - item_offset.top;
    // console.log(`${this.constructor.name} dragStart`, arguments);
    e?.dataTransfer?.setData('booth_id', String(booth_type.id));
    e?.dataTransfer?.setData('booth_letter_index', booth_type.letter_index);
    e?.dataTransfer?.setData('internal_x', String(internal_x));
    e?.dataTransfer?.setData('internal_y', String(internal_y));
  }

}
