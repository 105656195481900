
import {Component, Vue} from 'vue-property-decorator';
import {BoothType} from "@/classes/floor-map/BoothType";
import ReFilter from "@/components/common/ReFilter.vue";
import BoothTypeImage from "@/components/common/BoothTypeImage.vue";
import {BoothTypesGenericRepo} from "@/classes/repos/BoothTypesGenericRepo";

@Component(
    {
      components: {BoothTypeImage, ReFilter,},
    }
)
export default class ImportBoothTypesComp extends Vue {
  $refs!: {}

  private filter: string | null = null;

  private booth_types_by_show: { bts: BoothType[]; show: string }[] = [];

  private selected_bt_ids_for_import: boolean[] = [];
  private selected_show_ids: boolean[] = [];
  private select_all = false;

  async beforeMount() {
    await this.load();
  }

  private async load() {
    this.booth_types_by_show = await BoothTypesGenericRepo.get_available_for_import();
    this.selected_bt_ids_for_import = [];
    this.selected_show_ids = Array(this.booth_types_by_show.length).fill(false);
  }

  private get booth_types_by_show_filtered(): { bts: BoothType[]; show: string }[] {
    return this.booth_types_by_show
               .map(x => ({
                 show: x.show,
                 bts : x.bts.filter(bt => bt.title.toLowerCase().includes(this.filter?.toLowerCase() ?? ''))
               }))
               .filter(x => x.bts.length);
  }

  private toggle_by_show(n_sh: number, v: boolean) {
    this.booth_types_by_show_filtered[n_sh].bts.forEach(x => Vue.set(this.selected_bt_ids_for_import, x.id, v));
    if (!this.selected_show_ids.some(x => x !== v)) this.select_all = v;
  }

  private toggle_select_all(v: boolean) {
    this.booth_types_by_show_filtered.forEach((x, n) => {
      Vue.set(this.selected_show_ids, n, v);
      this.toggle_by_show(n, v);
    });
  }

  private async import_booth_types() {
    const bt_ids = this.selected_bt_ids_for_import.flatMap((v, n) => v ? n : []);
    await BoothTypesGenericRepo.import_booth_types(bt_ids);
    await BoothTypesGenericRepo.load_booth_types();
    this.$emit('notify', `${bt_ids.length} booth(s) imported`);
    await this.load();
  }

}
