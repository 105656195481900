import Loc from "@/classes/common/Loc";
import {pix_per_foot, rw_grid_step_x_default, rw_grid_step_y_default} from "@/classes/common/Const";
import {PrintOnlyObject} from "@/classes/floor-map/Booth";

const default_fp_rw_size = 100;

export default class FloorPlan {

    private _rw_width: number;
    get rw_width(): number {
        return this._rw_width;
    }

    set rw_width(value: number) {
        this._rw_width = value;
        this.loc.width = value * pix_per_foot;
    }

    private _rw_height: number;
    get rw_height(): number {
        return this._rw_height;
    }

    set rw_height(value: number) {
        this._rw_height = value;
        this.loc.height = value * pix_per_foot;
    }

    name: string;

    slug: string;

    rw_grid_step_x!: number;
    rw_grid_step_y!: number;

    fp_image?: PrintOnlyObject;

    loc: Loc;

    imageURL: string;

    angle: number;


    constructor(name: string, slug: string, rw_width: number, rw_height: number, imageURL: string, angle: number = 0,
                rw_grid_step_x: number                                                                           = rw_grid_step_x_default, rw_grid_step_y: number = rw_grid_step_y_default, fp_image?: PrintOnlyObject) {
        this.name = name;
        this.slug = slug;
        this._rw_width = rw_width;
        this._rw_height = rw_height;
        this.imageURL = imageURL;
        this.angle = angle;
        this.rw_grid_step_x = rw_grid_step_x;
        this.rw_grid_step_y = rw_grid_step_y;
        this.loc = new Loc(0, 0, rw_width * pix_per_foot, rw_height * pix_per_foot);
        this.fp_image = fp_image;
    }

    static from(o: any): FloorPlan {
        return new FloorPlan(
            o.name,
            o.slug,
            o._rw_width,
            o._rw_height,
            o.imageURL,
            o.angle,
            o.rw_grid_step_x,
            o.rw_grid_step_y,
            o.fp_image ? PrintOnlyObject.from(o.fp_image) :
            new PrintOnlyObject(o.imageURL, new Loc(0, 0, o._rw_width * pix_per_foot, o._rw_height * pix_per_foot), 0, true),
        )
    }

    static default(): FloorPlan {
        return new FloorPlan("", "", default_fp_rw_size, default_fp_rw_size, "", undefined, undefined, undefined,
                             new PrintOnlyObject('', new Loc(0, 0, default_fp_rw_size * pix_per_foot, default_fp_rw_size * pix_per_foot), 0, true));
    }

    clone(): FloorPlan {
        return FloorPlan.from(this);
    }

    copyTo(fp: FloorPlan) {
        fp.name = this.name;
        fp.slug = this.slug;
        fp._rw_width = this._rw_width;
        fp._rw_height = this._rw_height;
        fp.imageURL = this.imageURL;
        fp.angle = this.angle;
        fp.rw_grid_step_x = this.rw_grid_step_x;
        fp.rw_grid_step_y = this.rw_grid_step_y;
        fp.loc = new Loc(0, 0, this._rw_width * pix_per_foot, this._rw_height * pix_per_foot);
        fp.fp_image = this.fp_image?.clone();
    }
}