
import {Component, Prop, Ref, Vue} from 'vue-property-decorator';
import VendorProfileComp from "@/components/vendor/VendorProfileComp.vue";
import {CompaniesRepo} from "@/classes/repos/CompaniesRepo";
import {JwtRepo} from "@/classes/repos/JwtRepo";
import LoginReminder from "@/components/common/LoginReminder.vue";
import {EventBus} from "@/classes/common/EventBus";

@Component<CheckoutVendorProfile>(
    {
        components: {LoginReminder, VendorProfileComp}
    }
)
export default class CheckoutVendorProfile extends Vue {
    @Ref() profile_editor!: VendorProfileComp;

    @Prop()
    show_id: number | null;

    JwtRepo = JwtRepo

    private async beforeMount() {
        EventBus.$on?.('logged_in', this.load_company);
        await this.load_company();
    }

    private async load_company() {
        await CompaniesRepo.load_vendor_company();
        CompaniesRepo.current_company?.init_contacts();
    }
}
