
import {Component} from 'vue-property-decorator';
import {search_field_description} from "@/components/generic/EntitySearchForm.vue";
import ShowSelector from "@/components/generic/ShowSelector.vue";

@Component<ShowSelectorForVendorWaitlist>(
    {}
)
export default class ShowSelectorForVendorWaitlist extends ShowSelector {

  protected search_fields: (string | search_field_description)[] = ['name',];

  get search_request_additional_params(): any {
    return {having_waitlist_only: true}
  }

}
