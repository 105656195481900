
import {Component, Prop, Vue} from 'vue-property-decorator';
import {BookingsRepo} from "@/classes/repos/BookingsRepo";
import SellableServiceSelector from "@/components/generic/SellableServiceSelector.vue";
import Sellable_Service from "@/classes/floor-map/Sellable_Service";
import BookingItem from "@/classes/bookings/BookingItem";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import ReSpinner from "@/components/common/re-spinner.vue";

@Component<ShoppingCart>(
    {
      components: {ReSpinner, SellableServiceSelector}
    }
)
export default class ShoppingCart extends Vue {

  @Prop({default: false})
  readonly!: boolean;

  @Prop({default: false})
  display_show_name!: boolean;


  private ShowsGenericRepo = ShowsGenericRepo;

  get current_booking_items_grouped() {
    return BookingsRepo.current_booking_items_grouped;
  }

  get current_booking() {
    return BookingsRepo.current_booking;
  }

  private async add_service(selected_items: Sellable_Service[], new_items: Sellable_Service[]) {
    if (!new_items?.length) return;

    //only one item can be selected at once since EntityTable.select_mode is single by default
    const service = new_items[0];
    await BookingsRepo.add_or_update_service_in_cart(service.id, 1);
  }

  private async qty_changed(service_item: BookingItem) {
    await BookingsRepo.add_or_update_service_in_cart(service_item.service_id!, service_item.qty!);
  }

  /*  @Watch('ShowsGenericRepo.current_show.id')
    private show_switched() {
      BookingsRepo.reset();
    }*/
}
