
import {Component, Vue} from 'vue-property-decorator';
import LoginForm from "@/components/common/LoginForm.vue";
import ReMenuSidebar from "@/components/common/re-menu-sidebar.vue";
import TitlePanel from "@/components/floor-map/TitlePanel.vue";
import {ReHelper} from "@/classes/common/ReHelper";
import Show from "@/classes/floor-map/Show";
import App from "@/App.vue";
import TopPanel from "@/components/common/TopPanel.vue";
import {RawLocation, Route} from "vue-router";
import LoginPopup from "@/components/common/LoginPopup.vue";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";

@Component<App_Authenticated>(
    {
      components: {
        LoginForm,
        LoginPopup,
        ReMenuSidebar,
        TopPanel,
        TitlePanel,
      },
      async beforeRouteEnter(to: Route, from: Route, next: (to?: (RawLocation | false | ((vm: App_Authenticated) => void))) => void) {
        next(async vm => {

        })
      }
    }
)
export default class App_Authenticated extends Vue {
  $refs!: {
    login_popup: LoginPopup;
  }

  private ReHelper = ReHelper;

  left_sidebar_opened: boolean = !ReHelper.narrow_screen;

  shows: Show[] = [];

  get current_show() {
    return ShowsGenericRepo.current_show;
  }

  async created() {
  }

  mounted() {
    let DragDropTouch_script = document.createElement('script');
    DragDropTouch_script.setAttribute('src', '/js/DragDropTouch.js');
    document.head.appendChild(DragDropTouch_script);
  }

  async show_remove(sh: Show) {
    await ShowsGenericRepo.remove(sh.id);
    this.shows.splice(this.shows.indexOf(sh), 1);
    ShowsGenericRepo.select_show(this.shows.length ? this.shows[0] : null);
    App.notify('Show removed');
  }

}
