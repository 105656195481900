
import {Component, Prop, Vue} from 'vue-property-decorator';
import Sellable_Service from "@/classes/floor-map/Sellable_Service";

@Component<ShowsList>(
    {}
)
export default class ShowsList extends Vue {
  @Prop()
  data!: { item: Sellable_Service };
}
