export default class ShowNote {
    show_id: number;
    note_id: number;
    note: string;
    created: string;
    author: string;


    constructor(show_id: number, note_id: number, note: string, created: string, author: string) {
        this.show_id = show_id;
        this.note_id = note_id;
        this.note = note;
        this.created = created;
        this.author = author;
    }

    static from(o: any) {
        return new ShowNote(
            o.show_id,
            o.note_id,
            o.note,
            o.created,
            o.author,
        )
    }
}