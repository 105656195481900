
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Booth from "@/classes/floor-map/Booth";
import {MapRepo} from "@/classes/repos/MapRepo";
import BoothControlPanel from "@/components/editors/BoothControlPanel.vue";
import {AppRepo} from "@/classes/repos/AppRepo";
import BoothInfo from "@/components/floor-map/BoothInfo.vue";
import BoothQuickPropsEditor from "@/components/editors/BoothQuickPropsEditor.vue";

@Component<BoothPopupEditor>(
    {components: {BoothQuickPropsEditor, BoothInfo, BoothControlPanel}}
)
export default class BoothPopupEditor extends Vue {
  @Prop()
  booth!: Booth;

  private get popup_type(): 'control' | 'pricing' | 'checkout' {
    return AppRepo.ui_level === 'admin' ? (MapRepo.editor_mode === 'layout' ? 'control' : 'pricing') : 'checkout';
  }

  private show_popup = false;

  private get hide_temporary() {
    return AppRepo.ui_level === 'admin' && (MapRepo.active_canvas?.dragging_now || MapRepo.active_canvas?.scaling_now);
  }

  private loc_debug_info(): string {
    return `${this.booth?.loc?.top?.toFixed(0)}:${this.booth?.loc?.left?.toFixed(0)} angle: ${this.booth.angle}`;
    // | ${this.$refs.group?.fjs_top()}:${this.$refs.group?.fjs_left()}
  }

  private get spot() {
    const loc = this.booth.loc.rotate(this.booth.angle);
    return {
      top   : `${loc.top! * this.zoom + MapRepo.pan_y}px`,
      left  : `${loc.left! * this.zoom + MapRepo.pan_x}px`,
      width : `${loc.width * this.zoom}px`,
      height: `${loc.height * this.zoom}px`
    };
  }

  private MapRepo = MapRepo;

  private get zoom(): number {
    return MapRepo.zoom;
  }

  @Watch('MapRepo.selected_map_objects')
  selected() {
    // console.log(`${this.constructor.name} selected_map_objects`)
    if (MapRepo.selected_map_objects.length == 1 && MapRepo.selected_map_objects.includes(this.booth)) {
      this.show_popup = true;
      // await this.$nextTick();
      setTimeout(() => this.$root.$emit('bv::show::popover', `booth-popover`), 50);
      // this.$root.$emit('bv::show::popover', `booth-popover`);
      // this.$refs.popup.visible = true;
    } else this.show_popup = false;
  }

}
