
import {Component, Vue} from 'vue-property-decorator';
import {CompaniesRepo} from "@/classes/repos/CompaniesRepo";
import VendorProfileMenu from "@/components/common/VendorProfileMenu.vue";
import {JwtRepo} from "@/classes/repos/JwtRepo";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";

@Component<CheckoutMenu>(
    {
        components: {VendorProfileMenu}
    }
)
export default class CheckoutMenu extends Vue {
    private get company_has_alt_contact() {
        return !!CompaniesRepo.current_company?.contacts?.some(x => x.type === 'Alt Contact');
    }

    private get show_id() {
        return ShowsGenericRepo.current_show?.id ?? this.$route.params.show_id;
    }

    private async show_slug() {
        if(ShowsGenericRepo.current_show) return ShowsGenericRepo.current_show.slug;

        const show_id: string = this.$route.params.show_id;
        return show_id ? await ShowsGenericRepo.get_slug_by_id(Number(show_id)) : null;
    }

    JwtRepo = JwtRepo;
}
