import {LikeableEntity, LikeableEntityGenericRepo} from "@/classes/repos/LikeableEntityGenericRepo";
import Vue from "vue";
import axios from "axios";
import WaitlistItem from "@/classes/floor-map/WaitlistItem";
import ErrorForDisplay from "@/classes/common/ErrorForDisplay";
import {JwtRepo} from "@/classes/repos/JwtRepo";

class _WaitlistRepo extends LikeableEntityGenericRepo<{
    show_id?: number,
    vendor_id?: number,
    limit_by_ids?: number[],
    exclude_ids?: number[]
}> {
    constructor() {
        super(WaitlistItem, 'Waitlist');
    }

    async toggle_waitlist_enabled(show_id: number, waitlist_enabled: boolean) {
        await axios.post('/show/toggle_waitlist_enabled', {}, {params: {show_id, waitlist_enabled}});
    }

    async save(e: LikeableEntity, additional_params: Record<string, any> = {}): Promise<{
        success: boolean;
        errors?: ErrorForDisplay
    }> {
        try {
            let post_body = e.prepare_for_save();
            const {data}: {
                data: { id: number, jwt: string }
            } = await axios.post(`/${this.entity}/save`, post_body, {params: additional_params});
            if (data.jwt) {
                JwtRepo.jwt = data.jwt;
                JwtRepo.companies = [{id: JwtRepo.vendor_id, name: ''}]
            }

            return {success: true};
        } catch (ex) {
            console.log(ex)
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async update_sort_order(show_id: number, ordered_ids: string) {
        try {
            await axios.post('/Waitlist/update_sort_order', `"${ordered_ids}"`, {params: {show_id}, headers: {"Content-Type": "application/json"}});
            return {success: true};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

}

export const WaitlistRepo = Vue.observable(new _WaitlistRepo());