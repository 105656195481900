
import {Component} from 'vue-property-decorator';
import {search_field_description} from "@/components/generic/EntitySearchForm.vue";
import ShowSelector from "@/components/generic/ShowSelector.vue";
import {JwtRepo} from "@/classes/repos/JwtRepo";

@Component<ShowSelectorForVendor>(
    {}
)
export default class ShowSelectorForVendor extends ShowSelector {

  protected search_fields: (string | search_field_description)[] = ['name',];

  protected search_results_fields = ['name', 'location', 'start_date'];

  get search_request_additional_params(): any {
    return {booked_by_vendor: JwtRepo.vendor_id}
  }

}
