import axios from "axios";
import {Exclude, Expose, instanceToPlain, plainToClass, serialize} from "class-transformer";

export interface IBookingQuiz {
    rule_version: number;
    tos_accepted: boolean | null;

    get valid(): boolean;
    get questions_answered(): boolean;
}

export class BookingQuiz {
    static from(o: any, booking_id: number): IBookingQuiz | null {
        if (!o.quiz_json) return null;
        const quiz = JSON.parse(o.quiz_json)
        switch (o.rule_version) {
            case 1:
                return new BookingQuiz_v1(booking_id, o.rule_version, quiz.tos_accepted);
            case 2:
                return new BookingQuiz_v2(booking_id, o.rule_version, quiz.tos_accepted, quiz.dry_goods, quiz.bring_racks);
            default:
                return null;
        }
    }
}

export class BookingQuiz_v1 implements IBookingQuiz {
    rule_version!: number;

    @Exclude()
    private booking_id: number;

    @Expose({name: 'tos_accepted'})
    private _tos_accepted: boolean | null = null;

    constructor(booking_id:number, rule_version: number, tos_accepted: boolean | null) {
        this.booking_id = booking_id;
        this.rule_version = rule_version;
        this._tos_accepted = tos_accepted ?? null;
    }

    get tos_accepted(): boolean | null {
        return this._tos_accepted;
    }

    set tos_accepted(value: boolean | null) {
        this._tos_accepted = value;
        this.persist();
    }

    get valid(): boolean {
        return this.tos_accepted === true;
    }

    get questions_answered(): boolean {
        return true;
    }

    protected persist() {
        axios.post(`/booking/update_quiz`, {quiz: JSON.stringify(instanceToPlain(this))}, {
            params: {booking_id: this.booking_id},
            headers: {"Content-Type": "multipart/form-data"}
        });
    }
}

export class BookingQuiz_v2 extends BookingQuiz_v1 {

    @Expose({name: 'dry_goods'})
    private _dry_goods: boolean | null = null;

    @Expose({name: 'bring_racks'})
    private _bring_racks: boolean | null = null;

    constructor(booking_id:number, rule_version: number, tos_accepted: boolean | null, dry_goods: boolean | null, bring_racks: boolean | null) {
        super(booking_id, rule_version, tos_accepted);
        this._dry_goods = dry_goods ?? null;
        this._bring_racks = bring_racks ?? null;
    }

    get dry_goods(): boolean | null {
        return this._dry_goods;
    }

    set dry_goods(value: boolean | null) {
        this._dry_goods = value;
        this.persist();
    }

    get bring_racks(): boolean | null {
        return this._bring_racks;
    }

    set bring_racks(value: boolean | null) {
        this._bring_racks = value;
        this.persist();
    }

    get questions_answered(): boolean {
        return (this._dry_goods === true && this._bring_racks !== null) || this._dry_goods === false;
    }

    get valid(): boolean {
        return this.tos_accepted === true && this.questions_answered;
    }
}