import FloorMap from "@/classes/floor-map/FloorMap";
import {instanceToPlain} from "class-transformer";

export default class FloorMapLib {
    id: number;
    show_name: string;
    show_location: string;
    show_start_date: string;
    show_end_date: string;
    created_on?: string;

    map?: FloorMap;


    constructor(id: number, show_name: string, show_location: string, show_start_date: string, show_end_date: string, map?: FloorMap, created_on?: string) {
        this.id = id;
        this.show_name = show_name;
        this.show_location = show_location;
        this.show_start_date = show_start_date;
        this.show_end_date = show_end_date;
        this.created_on = created_on;
        this.map = map;
    }

    get name() {
        return ``;
    }

    get image_url() {
        return `/images/uploads/map_library/map_${this.id}.png`;
    }

    static from(o: any): FloorMapLib {
        const map_lib = new FloorMapLib(
            o.id,
            o.show_name,
            o.show_location,
            o.show_start_date,
            o.show_end_date,
            FloorMap.from({...JSON.parse(o.map_json), ...{id: 0}}),
            o.created_on,
        );
        return map_lib;
    }

    prepare_for_save() {
        return {...this, ...{map_json: JSON.stringify(instanceToPlain(this.map)), map: undefined}};
    }

    static default(): FloorMapLib {
        throw 'Method not implemented';
    }

    clone() {
        return FloorMapLib.from(this);
    }
}