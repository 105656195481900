
import {Component, Ref, Vue, Watch} from 'vue-property-decorator';
import ReFilter from "@/components/common/ReFilter.vue";
import WaitlistItemsTable from "@/components/generic/WaitlistItemsTable.vue";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import {JwtRepo} from "@/classes/repos/JwtRepo";
import {WaitlistRepo} from '@/classes/repos/WaitlistRepo';
import ShowSelector from "@/components/generic/ShowSelector.vue";
import Show from "@/classes/floor-map/Show";

@Component<WaitlistComp>(
    {
      components: {ShowSelector, WaitlistItemsTable, ReFilter}
    }
)
export default class WaitlistComp extends Vue {
  private filter: string | null = null;

  private WaitlistRepo = WaitlistRepo;
  private JwtRepo = JwtRepo;

  @Ref() table!: WaitlistItemsTable;

  private selected_show: Show | null = null;

  mounted() {
    if (ShowsGenericRepo.current_show) this.selected_show = ShowsGenericRepo.current_show;
  }

  private get filter_request(): { show_id?: number, vendor_id?: number } {
    return {
      show_id: this.selected_show?.id,
      vendor_id: JwtRepo.is_admin ? undefined : JwtRepo.vendor_id
    };
  }

  private get is_admin() {
    return JwtRepo.is_admin;
  }

  @Watch('selected_show')
  private show_changed() {
    if (this.selected_show)
      ShowsGenericRepo.select_show(this.selected_show);
  }

  private item_added() {

  }

}
