import {MapRepo} from "@/classes/repos/MapRepo";
import Booth from "@/classes/floor-map/Booth";

export default class BookingItem {
    readonly type_booth = 1;
    readonly type_service = 2;

    id: number;
    booking_id: number;
    show_id: number;
    show_slug: string;
    floorplan_id: number;
    booth_number: string;
    discount: number;
    sharing_with_vendor_id: number | null;
    sharing_with_vendor_name: string | null;
    show_description: string;
    item_description: string;
    item_total?: number;
    item_type: number;

    vendor_id?: number;
    vendor_name?: string;
    vendor_categories?: string[];
    sharing_vendor_categories?: string[];
    show_name?: string;
    show_location?: string;
    show_start_date?: string;
    show_end_date?: string;
    floorplan_name?: string;

    qty?: number;
    service_id?: number;
    service_name?: string;

    get booth(): Booth | undefined {
        return MapRepo.current_map?.mapObjects.find(b => b.booth?.booth_number === this.booth_number || b.complex_booth?.booth_number === this.booth_number) as Booth;
    }

    constructor(id: number, booking_id: number, show_id: number, show_slug: string, floorplan_id: number, booth_number: string, discount: number, show_description: string, item_description: string,
                item_total: number, item_type: number, vendor_id?: number, vendor_name?: string, vendor_categories?: string[], show_name?: string, show_location?: string,
                show_start_date?: string, show_end_date?: string, floorplan_name?: string, qty?: number, service_id?: number, service_name?: string, sharing_with_vendor_id?: number,
                sharing_with_vendor_name?: string, sharing_vendor_categories?: string[]) {
        this.id = id;
        this.booking_id = booking_id;
        this.show_id = show_id;
        this.show_slug = show_slug;
        this.floorplan_id = floorplan_id;
        this.booth_number = booth_number;
        this.discount = discount;
        this.show_description = show_description;
        this.item_description = item_description;
        this.item_total = item_total;
        this.item_type = item_type;
        this.vendor_id = vendor_id;
        this.vendor_name = vendor_name;
        this.vendor_categories = vendor_categories;
        if (!this.vendor_categories?.length) this.vendor_categories = ['Uncategorized'];
        this.show_name = show_name;
        this.show_location = show_location;
        this.show_start_date = show_start_date;
        this.show_end_date = show_end_date;
        this.floorplan_name = floorplan_name;
        this.qty = qty;
        this.service_id = service_id;
        this.service_name = service_name;
        this.sharing_with_vendor_id = sharing_with_vendor_id ?? null;
        this.sharing_with_vendor_name = sharing_with_vendor_name ?? null;
        this.sharing_vendor_categories = sharing_vendor_categories;
        if (!this.sharing_vendor_categories?.length) this.sharing_vendor_categories = ['Uncategorized'];
    }

    static from(o: any) {
        return new BookingItem(o.id,
            o.booking_id,
            o.show_id,
            o.show_slug,
            o.floorplan_id,
            o.booth_number,
            o.discount,
            o.show_description,
            o.item_description,
            o.item_total,
            o.item_type,
            o.vendor_id,
            o.vendor_name,
            o.vendor_categories,
            o.show_name,
            o.show_location,
            o.show_start_date,
            o.show_end_date,
            o.floorplan_name,
            o.qty,
            o.service_id,
            o.service_name,
            o.sharing_with_vendor_id,
            o.sharing_with_vendor_name,
            o.sharing_vendor_categories,
        );
    }
}