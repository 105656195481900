var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"text-left px-1 px-md-2",attrs:{"id":"companies-comp"}},[_c('h3',{staticClass:"mb-3"},[_vm._v("Connected Companies")]),_c('b-table',{staticStyle:{"width":"auto"},attrs:{"items":_vm.JwtRepo.companies,"fields":[
           {key: 'id', label: 'Currently Active'},
          {key:'name', label: '', formatter: n => n || '(company not registered)'},
          {key:'edit', label: ''},
          {key:'disconnect', label: ''},
          {key:'delete', label: ''},
    ]},scopedSlots:_vm._u([{key:"cell(id)",fn:function(data){return [_c('b-form-radio',{attrs:{"name":"vendor_id","value":data.item.id,"disabled":!data.item.name},model:{value:(_vm.vendor_id),callback:function ($$v) {_vm.vendor_id=$$v},expression:"vendor_id"}})]}},{key:"cell(edit)",fn:function(data){return [_c('b-button',{attrs:{"size":"sm","variant":"primary","to":{name:'vendor_profile', params: {company_id:data.item.id}}}},[_vm._v("Edit ")])]}},{key:"cell(disconnect)",fn:function(data){return [(data.item.id!==_vm.vendor_id)?_c('b-button',{attrs:{"size":"sm","variant":"warning"},on:{"click":function($event){return _vm.disconnect(data.item.id)}}},[_vm._v(" Disconnect ")]):_vm._e()]}},{key:"cell(delete)",fn:function(data){return [(data.item.id!==_vm.vendor_id)?_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.remove(data.item)}}},[_vm._v("Delete ")]):_vm._e()]}}])}),_c('b-button',{attrs:{"to":{name:'new_vendor_profile'},"variant":"primary"}},[_vm._v("Register new Company")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }