import Vue from "vue";
import Config from "@/classes/common/Config";

class _JwtRepo {
    get companies(): { id: number; name: string }[] {
        return this._companies;
    }

    set companies(value: { id: number; name: string }[]) {
        this._companies = value ?? [];
    }

    private _companies: { id: number, name: string }[] = [];

    private _jwt: string | null = null;

    first_name: string | null = null;

    last_name: string | null = null;

    store_credit: number = 0;
    total_overpayments: number = 0;

    get full_store_credit() {
        return this.store_credit + this.total_overpayments;
    }

    get full_name() {
        return [this.first_name, this.last_name].filter(s => !!s).join(' ');
    }

    get current_company(): { id: number; name: string } {
        return this._companies.find(c => c.id == this.vendor_id)!;
    }

    get companies_loaded() {
        return !!this._companies?.length;
    }

    get jwt(): string | null {
        if (this._jwt == null) {
            this.jwt = this.storage.getItem('jwt'); //call setter
            return this._jwt
        } else {
            return this._jwt;
        }
    }

    set jwt(value: string | null) {
        this._jwt = value;
        if (this._jwt) {
            this.storage.setItem('jwt', this._jwt);
            this.jwt_decoded = this.parse_jwt(this._jwt);
            if (Config.is_dev_mode)
                this.storage.setItem('jwt_decoded', JSON.stringify(this.jwt_decoded));
        } else {
            this.storage.removeItem('jwt');
            this.jwt_decoded = null;
            this.storage.removeItem('jwt_decoded');
        }
    }

    private get storage(): Storage {
        return Config.is_dev_mode ? sessionStorage : localStorage;
    }

    read_stored_jwt() {
        this.jwt = this.storage.getItem('jwt'); //call setter
    }

    clear_jwt() {
        this.jwt = null;
        this._companies = [];
        this.first_name = this.last_name = null;
        this.store_credit = this.total_overpayments = 0;
    }

    private jwt_decoded: {
                             user_id?: string,
                             email?: string,
                             // full_name?: string,
                             // user_type: 'temp' | 'registered',
                             vendor_id?: string,
                             // roles: string[],
                             is_admin: 'true' | 'false',
                             is_vendor: 'true' | 'false',
                             is_staff: 'true' | 'false',
                             // is_company_registered: 'true' | 'false',
                             jti: string
                         } | null = null;


    get is_logged_in() {
        return !!this.jwt;
    }

    get is_registered_user(): boolean {
        return !!this.jwt_decoded?.email;
    }

    get is_company_registered(): boolean {
        return !!this.current_company?.name;
    }

    get is_admin() {
        return this.jwt_decoded?.is_admin === "true";
    }

    get is_vendor() {
        return this.jwt_decoded?.is_vendor === "true" || !!this.jwt_decoded?.vendor_id;
    }

    get user_id() {
        return parseInt(this.jwt_decoded?.user_id ?? '0');
    }

    get email() {
        return this.jwt_decoded?.email;
    }

    /*
        get full_name() {
            return this.jwt_decoded?.full_name;
        }
    */

    get vendor_id() {
        return parseInt(this.jwt_decoded?.vendor_id ?? '0');
    }

    get auth_header() {
        return {Authorization: this.jwt ? `Bearer ${this.jwt}` : ''};
    }

    private parse_jwt(token: string | null) {
        if (!token) return null;

        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`).join(''));

        return JSON.parse(jsonPayload);
    };

}

export const JwtRepo = Vue.observable(new _JwtRepo());
