
import {Component, Prop, Vue} from 'vue-property-decorator';
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import ShowSelector from "@/components/generic/ShowSelector.vue";
import Show from "@/classes/floor-map/Show";

@Component<FloorPlanSelector>(
    {
      components: {ShowSelector}
    }
)
export default class FloorPlanSelector extends Vue {

  @Prop({default: null})
  show_initial!: Show | null;

  private show!: Show | null;

  @Prop({default: null})
  fp_id!: number | null;

  private floor_plans: { id: number, name: string } [] = [];

  async created() {
    this.show = this.show_initial;

    await this.load_floor_plans();
    this.$emit('floor_plan_changed', this.show, this.floor_plans[0]?.id ?? null)
  }

  protected async load_floor_plans() {
    if (!this.show) return;
    this.floor_plans = this.show.maps?.map(m => ({
      id: m.id,
      name: m.floor_plan?.name ?? String(m.id)
    })) ?? (await ShowsGenericRepo.list_floor_plans(this.show.id)).floor_plans ?? [];

    this.$emit('floor_plan_changed', this.show, this.floor_plans[0]?.id ?? null)
  }

}
