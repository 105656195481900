import DataProviderContext from "@/classes/common/DataProviderContext";
import Person from "@/classes/people/Person";
import axios, {Axios} from "axios";
import Show from "@/classes/floor-map/Show";
import Booking from "@/classes/bookings/Booking";
import Company from "@/classes/companies/Company";
import Sellable_Service from "@/classes/floor-map/Sellable_Service";
import {plainToClassFromExist} from "class-transformer";
import ErrorForDisplay from "@/classes/common/ErrorForDisplay";
import User from "@/classes/users/User";
import {BoothType} from "@/classes/floor-map/BoothType";
import FloorMapLib from "@/classes/floor-map/FloorMapLib";
import WaitlistItem from "@/classes/floor-map/WaitlistItem";
import BookingHistory from "@/classes/history/BookingHistory";

export type TLikeableEntity = (typeof Show | typeof Person | typeof Booking | typeof Company | typeof Sellable_Service | typeof User | typeof BoothType | typeof FloorMapLib | typeof WaitlistItem | typeof BookingHistory);
export type LikeableEntity = (Show | Person | Booking | Company | Sellable_Service | User | BoothType | FloorMapLib | WaitlistItem | BookingHistory);

export class LikeableEntityGenericRepo<TSearchRequest> {
    protected readonly TEntity: TLikeableEntity;
    protected readonly entity: string;
    protected entities_cache: LikeableEntity[] = [];

    constructor(TEntity: TLikeableEntity, entity: string) {
        this.TEntity = TEntity;
        this.entity = entity;
    }

    protected get axios(): Axios {
        return axios;
    }

    async list(bv_table_ctx?: DataProviderContext, search_request?: TSearchRequest): Promise<{ success: boolean; items: LikeableEntity[]; total: number; errors?: any }> {
        try {
            const post_body = {bv_table_ctx, ...search_request};
            const {data}: { data: { result: object[]; total: number } } = await this.axios.post(`/${this.entity}/list`, post_body);
            const items = this.objects_to_entities(data.result);

            if (bv_table_ctx === undefined && search_request === undefined)
                this.entities_cache = items;

            return {success: true, items, total: data.total};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex), items: [], total: 0};
        }
    }

    protected objects_to_entities(data: object[]): LikeableEntity[] {
        return data.map(o => this.TEntity.from(o));
    }

    async details(id: number): Promise<{ success: boolean; entity?: LikeableEntity; errors?: ErrorForDisplay }> {
        try {
            const {data} = await axios.get(`/${this.entity}/details?id=${id}`);
            const entity = plainToClassFromExist(this.TEntity.default(), data);
            return {success: true, entity};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async create_empty(): Promise<{ new_id?: number; entity?: TLikeableEntity; success: boolean, errors?: any }> {
        try {
            const {data}: { data: { new_id: number, entity: TLikeableEntity } } = await axios.post(`/${this.entity}/create_empty`);
            return {success: true, new_id: data.new_id, entity: data.entity};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async save(e: LikeableEntity, additional_params: Record<string, any> = {}): Promise<{ success: boolean; id?: number; errors?: ErrorForDisplay }> {
        try {
            let post_body = e.prepare_for_save();
            const {data}: { data: { id: number } } = await axios.post(`/${this.entity}/save`, post_body, {params: additional_params});
            return {success: true, id: data.id};
        } catch (ex) {
            console.log(ex)
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async clone(id: number): Promise<{ success: boolean; new_id?: number; errors?: any }> {
        try {
            const {data}: { data: { new_id: number } } = await axios.post(`/${this.entity}/clone?id=${id}`);
            return {success: true, new_id: data.new_id};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async remove(id: number): Promise<{ success: boolean; errors?: ErrorForDisplay }> {
        try {
            await axios.post(`/${this.entity}/remove?id=${id}`);
            return {success: true};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async remove_multiple(ids: number[]): Promise<{ success: boolean; errors?: ErrorForDisplay }> {
        try {
            await axios.post(`/${this.entity}/remove_multiple`, ids);
            return {success: true};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }
}
